<template>
  <div style="border: 1px solid #ccc; z-index: 2">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editorRef"
      :defaultConfig="toolbarConfig"
    />

    <Editor
      style="height: 600px; overflow-y: hidden"
      v-model="modelValue"
      :defaultConfig="editorConfig"
      @customPaste="customPaste"
      @onCreated="handleCreated"
      @onChange="change"
    />
  </div>
</template>
<script setup>
import { BASE_HOST } from '../utils/axios'

import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import {
  onBeforeUnmount,
  shallowRef,
  defineProps,
  watchEffect,
  ref,
  defineEmits
} from 'vue'
import { replaceCopyImgPath } from '../utils/replace-img-path'
import '@wangeditor/editor/dist/css/style.css' // 引入 css
const props = defineProps({
  modelValue: String
})
const emit = defineEmits(['update:modelValue'])

const modelValue = ref('')

watchEffect(() => {
  modelValue.value = props.modelValue
})

// 编辑器实例，必须用 shallowRef
const editorRef = shallowRef()

const toolbarConfig = {}
const change = (e) => {
  emit('update:modelValue', e.getHtml())
}
const customPaste = (editor, event) => {
  // editorConfig.customPaste = (editor, event) => {                                       // JS 语法

  // event 是 ClipboardEvent 类型，可以拿到粘贴的数据
  // 可参考 https://developer.mozilla.org/zh-CN/docs/Web/API/ClipboardEvent

  const html = event.clipboardData.getData('text/html') // 获取粘贴的 html
  // const text = event.clipboardData.getData('text/plain') // 获取粘贴的纯文本
  // const rtf = event.clipboardData.getData('text/rtf') // 获取 rtf 数据（如从 word wsp 复制粘贴）
  // console.log(editor.getHtml(), text, 981)
  // console.log(replaceCopyImgPath(html), '替换之后')

  // 同步
  // editor.insertText('xxx')
  // editor.dangerouslyInsertHtml(html)
  editor.setHtml(replaceCopyImgPath(html))
  // editor.dangerouslyInsertHtml(replaceCopyImgPath(html))

  // 异步
  setTimeout(() => {
    // editor.insertText('yy')
  }, 1000)

  // 阻止默认的粘贴行为
  event.preventDefault()
  return false

  // 继续执行默认的粘贴行为
  // return true
}
const editorConfig = {
  placeholder: '请输入内容...',

  MENU_CONF: {
    base64LimitSize: 10 * 1024 * 1024, // 10M 以下插入 base64

    uploadVideo: {
      maxFileSize: 200 * 1024 * 1024, // 5M
      server: BASE_HOST + 'v1/cms/upload/',
      fieldName: 'file',
      meta: {
        module: 'video'
      },
      customInsert: (res, insertFn) => {
        // res 即服务端的返回结果
        console.log(res, 123)
        // 从 res 中找到 url alt href ，然后插图图片

        const url = BASE_HOST + 'v1/images/' + res.data.ImgPath
        const alt = '春秋版画博物馆 文章'
        const href = '#'
        insertFn(url, alt, href)
      }
    },
    uploadImage: {
      maxFileSize: 200 * 1024 * 1024, // 1M
      server: BASE_HOST + 'v1/cms/upload/',
      fieldName: 'file',
      meta: {
        module: 'news'
      },
      customInsert: (res, insertFn) => {
        // res 即服务端的返回结果
        console.log(res, 123)
        // 从 res 中找到 url alt href ，然后插图图片

        const url = BASE_HOST + 'v1/images/' + res.data.ImgPath
        const alt = '春秋版画博物馆 文章'
        const href = '#'
        insertFn(url, alt, href)
      }
    }
  }
}

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  const editor = editorRef.value
  if (editor == null) return
  editor.destroy()
})

const handleCreated = (editor) => {
  editorRef.value = editor // 记录 editor 实例，重要！
}
</script>
<style lang="scss"></style>
