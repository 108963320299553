<template>
  <div>
    <CommonLsitPage
      dataUrl="/blogroll/"
      :params="pageParams"
      :requestParams="{}"
      ref="tableRef"
    ></CommonLsitPage>

    <!-- 删除弹窗 -->
    <el-dialog v-model="dialogVisible" title="确认操作" width="30%">
      <span
        >此操作将删除数据
        <span style="color: red">{{ aboutDetail.ArticleTitle }}</span
        >，请谨慎操作。</span
      >
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="danger" @click="deleteArticlesFunction"
            >删除</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 创建 编辑弹窗 -->
    <el-dialog v-model="operationDialog" title="友情链接" width="30%">
      <div>
        <el-form
          ref="ruleFormRef"
          :model="aboutDetail"
          :rules="aboutDetailRules"
          class="demo-ruleForm"
          label-width="80px"
          status-icon
        >
          <el-form-item label="名称" prop="Name">
            <el-input v-model="aboutDetail.Name" />
          </el-form-item>
          <el-form-item label="链接地址" prop="Href">
            <el-input v-model="aboutDetail.Href" />
          </el-form-item>

          <!-- <el-form-item>
      <el-button type="primary" @click="submitForm(ruleFormRef)"
        >Create</el-button
      >
      <el-button @click="resetForm(ruleFormRef)">Reset</el-button>
    </el-form-item> -->
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm(ruleFormRef)">取消</el-button>
          <el-button @click="submitForm(ruleFormRef)" type="primary">{{
            isEdit ? '修改' : '创建'
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import CommonLsitPage from '../../../components/common-list-page.vue'
// import moment from '../../../utils/moment'
import { ref, reactive } from 'vue'
// import { useRouter } from 'vue-router'
// import { BASE_HOST } from '../../../utils/axios'
import { ElMessage } from 'element-plus'
import { deleteBlogroll, putBlogroll, postBlogroll } from '../../../apis'

import { Delete, Edit, Tickets } from '@element-plus/icons-vue'

const tableRef = ref(null)
const dialogVisible = ref(false)

// const router = useRouter()
// const route = useRoute()
const aboutDetail = ref({})
const operationDialog = ref(false)
const ruleFormRef = ref()
const isEdit = ref(false)
const aboutDetailRules = reactive({
  Href: [{ required: true, message: '请输入链接地址', trigger: 'blur' }],
  Name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
})
const pageParams = {
  title: '友情链接管理',
  btns: [
    {
      type: 'primary',
      name: '新增友情链接',
      onClick: () => {
        isEdit.value = false
        aboutDetail.value = {}
        operationDialog.value = true
      }
    }
  ],
  // <el-table-column prop="date" label="Date" width="180" />
  tableColumn: [
    {
      prop: 'Name',
      label: '名称'
    },
    {
      label: '链接地址',
      prop: 'Href'
      // width: 150
    },

    {
      label: '操作',
      width: 240,
      operations: [
        {
          name: '访问',
          // type: 'plain',
          icon: Tickets,
          onClick: (scope) => {
            window.open(scope.row.Href, '_blank')

            // aboutDetail.value = scope.row
            // showDrawer.value = true
          }
        },
        {
          name: '编辑',
          type: 'success',
          icon: Edit,
          onClick: (scope) => {
            // console.log(tableRef.value.refresh())
            console.log(scope.row, 1111)
            aboutDetail.value = scope.row
            isEdit.value = true

            operationDialog.value = true
          }
        },
        {
          name: '删除',
          type: 'danger',
          icon: Delete,

          onClick: (scope) => {
            aboutDetail.value = scope.row
            dialogVisible.value = true
            console.log(scope, 1111)
          }
        }
      ]
    }
  ]
}

const deleteArticlesFunction = () => {
  deleteBlogroll(aboutDetail.value.Id).then((res) => {
    tableRef.value.refresh()
    // console.log(res, '删除数据')
    dialogVisible.value = false
    ElMessage({
      message: '删除成功',
      type: 'success'
    })
  })
}
const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!')
      if (isEdit.value) {
        putBlogroll(aboutDetail.value)
          .then((res) => {
            tableRef.value.refresh()
            // console.log(res, '删除数据')
            operationDialog.value = false
            ElMessage({
              message: '编辑成功',
              type: 'success'
            })
          })
          .catch(() => {
            ElMessage({
              message: '请稍后再试！',
              type: 'success'
            })
          })
      } else {
        postBlogroll(aboutDetail.value)
          .then((res) => {
            tableRef.value.refresh()
            // console.log(res, '删除数据')
            operationDialog.value = false
            ElMessage({
              message: '添加成功',
              type: 'success'
            })
          })
          .catch(() => {
            ElMessage({
              message: '请稍后再试！',
              type: 'success'
            })
          })
      }
    } else {
      console.log('error submit!', fields)
    }
  })
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  operationDialog.value = false
}
</script>

<style scoped lang="scss">
.cell-item {
  width: 6em;
}
.dec-box {
  // background: red;
}
</style>
