<template>
  <el-page-header :content="isEdit ? '编辑文章' : '发布文章'" @back="goBack" />
  <div class="form-box">
    <el-form
      ref="formRef"
      :model="formData"
      :rules="rules"
      label-width="120px"
      class="demo-formData"
      :size="formSize"
    >
      <el-form-item label="文章标题" prop="ArticleTitle">
        <el-input class="input-w500" v-model="formData.ArticleTitle" />
      </el-form-item>
      <el-form-item label="文章来源" prop="PubFrom">
        <el-input
          class="input-w500"
          v-model="formData.PubFrom"
          @input="pubFromChange"
        />
      </el-form-item>
      <el-form-item label="作者" prop="Honor">
        <el-input class="input-w500" v-model="formData.Honor" />
      </el-form-item>
      <!-- <el-form-item label="文章种类" prop="ArticleSort">

        <el-select v-model="formData.ArticleSort" placeholder="文章种类">
          <el-option
            :label="item.label"
            :value="item.value"
            v-for="item in newsTypeList"
            :key="item.value"
          />
        </el-select>
      </el-form-item> -->
      <el-form-item label="文章封面图" prop="BigPic">
        <!-- <el-input v-model="formData.BigPic" /> -->

        <el-upload
          ref="uploadImage"
          :limit="1"
          :action="BASE_HOST + 'v1/cms/upload/'"
          :data="{ module: 'news' }"
          :headers="{
            // 'Content-Type': 'multipart/form-data'
          }"
          :file-list="fileList"
          :on-success="fileUploadSuccess"
          list-type="picture-card"
          :auto-upload="true"
          :on-exceed="handleExceed"
        >
          <!-- {{ fileList }} -->
          <el-icon><Plus /></el-icon>

          <template #file="{ file }">
            <div>
              <img
                class="el-upload-list__item-thumbnail"
                :src="file.url"
                alt=""
              />
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <el-icon><zoom-in /></el-icon>
                </span>
                <!-- <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleDownload(file)"
                >
                  <el-icon><Download /></el-icon>
                </span> -->
                <span
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <el-icon><Delete /></el-icon>
                </span>
              </span>
            </div>
          </template>
        </el-upload>

        <el-dialog v-model="previewImageDialog">
          <el-image fit="cover" :src="dialogImageUrl" alt="Preview Image" />
        </el-dialog>
      </el-form-item>
      <el-form-item label="文章内容" prop="ArticleContent" v-if="!reprint">

        <wangeditor v-model="formData.ArticleContent"></wangeditor>
      </el-form-item>
      <el-form-item label="文章内容" prop="ArticleContent" v-if="reprint">
        <div
          style="
            width: 100%;
            min-height: 100px;
            border: 1px solid #ccc;
            z-index: 2;
          "
        >
          <!-- https://mp.weixin.qq.com/s/faLf2R3lYoYUoacbU_73uw -->
          <iframe
            :src="BASE_HOST + 'v1/cms/frame/?target=' + formData.PubFrom"
            style="width: 100%; height: 100vh"
          ></iframe>
          <div style="text-align: center">
            文章内容转载自：<a href="#"> {{ formData.PubFrom }}</a>
          </div>
        </div>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" v-if="!isEdit" @click="submitForm(formRef)"
          >保存</el-button
        >
        <el-button type="primary" v-else @click="submitForm(formRef)"
          >更新</el-button
        >
        <el-button @click="goBack">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { BASE_HOST } from '../../../utils/axios'
import wangeditor from '../../../components/wang-editor-component.vue'
import { ref, reactive } from 'vue'
import { Delete, Plus, ZoomIn } from '@element-plus/icons-vue'
import { useRoute, useRouter } from 'vue-router'
import { getArticlesDetail, putReferral, postReferral } from '../../../apis'
import { ElMessage, genFileId } from 'element-plus'
const formRef = ref()
const isEdit = ref(false)
const formSize = ref('default')
const route = useRoute()
const router = useRouter()
const fileList = ref()
const uploadImage = ref()
const previewImageDialog = ref(false)
const dialogImageUrl = ref('')

// const newsTypeList = [
//   {
//     label: '版画界文章',
//     value: 48
//   },
//   {
//     label: '艺术界文章',
//     value: 49
//   },
//   {
//     label: '站内文章',
//     value: 50
//   },
//   {
//     label: '文章资讯',
//     value: 52
//   }
// ]
const formData = reactive({
  ArticleTitle: '', //   string    `"文章标题"`
  ArticleContent: '', // string    `"文章内容"`
  IsTop: '0', //     string    `置顶标志(暂时不用)`
  ArticleSort: 55, //   int       `"分类ID，传整数ID：48、49、50、52，分类详情如下
  // 48：版画界文章
  // 49：艺术界文章
  // 50：站内文章
  // 52：文章资讯
  Honor: '',

  PubFrom: '', // 来源
  BigPic: '', //       string    `文章图片(大图小图都行，你挑选一个用)`
  SmallPic: '', //      string    `文章图片`
  ModuleID: 35, //     int       `文章分类，固定值23`
  AddUser: '管理员' //       string    `"操作用户"`
  // IsTop: '', //      string    `置顶标志(暂时不用)`
})

const rules = reactive({
  ArticleTitle: [
    {
      required: true,
      message: '请输入文章标题',
      trigger: 'blur'
    }
  ]
})
const reprint = ref(false)
const pubFromChange = (v) => {
  console.log(v)
  if (v.includes('http://') || v.includes('https://')) {
    reprint.value = true
  } else {
    reprint.value = false
    // formData.ArticleContent = ''
  }
}

const id = route.query.id
const getData = () => {
  getArticlesDetail(id).then((res) => {
    console.log(123, res)

    if (
      res.data.data.PubFrom.includes('http://') ||
      res.data.data.PubFrom.includes('https://')
    ) {
      console.log('转载')

      reprint.value = true
    } else {
      reprint.value = false
      formData.ArticleContent = ''
    }

    for (const key in res.data.data) {
      if (Object.hasOwnProperty.call(formData, key)) {
        const element = res.data.data[key]
        formData[key] = element
      }
    }
    if (res.data.data.BigPic) {
      fileList.value = [
        {
          name: 'news-image',
          url: BASE_HOST + 'v1/images/' + res.data.data.BigPic
        }
      ]
    }

    // formData
  })
}
if (id) {
  // 如果有 id 就是编辑模式
  isEdit.value = true
  getData()
}
const handleRemove = (file) => {
  console.log(file)
  uploadImage.value.clearFiles()
  // formData.ProductSpic = ''
  formData.BigPic = ''
}

const handlePictureCardPreview = (file) => {
  dialogImageUrl.value = file.url
  previewImageDialog.value = true
}
const fileUploadSuccess = (returnData) => {
  // 文件上传成功
  console.log(returnData.data.ImgPath, '文件上传成功')

  // setWatermark({
  //   filepath: returnData.data.ImgPath,
  //   small: 1
  // }).then((res) => {
  //   console.log(res, 1233)

  // formData.ProductSpic = res.data.data.SmallPath
  formData.BigPic = returnData.data.ImgPath
  // })
}
const handleExceed = (files) => {
  uploadImage.value.clearFiles()
  const file = files[0]
  file.uid = genFileId()
  console.log(file, 1233)

  uploadImage.value.handleStart(file)
  uploadImage.value.submit()
}

// console.log(valueHtml.value, 999)
// const resetForm = (formEl) => {
//   if (!formEl) return
//   formEl.resetFields()
// }
const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!', formData, formEl)
      if (
        formData.PubFrom.includes('http://') ||
        formData.PubFrom.includes('https://')
      ) {
        const newArticleContent = ` <iframe
          src="${BASE_HOST}v1/cms/frame/?target=${formData.PubFrom}"
          style="width: 100%; height: 100vh"
        ></iframe>
        <div style="text-align: center">
          文章内容转载自：<a href="${formData.PubFrom}">${formData.PubFrom}</a>
        </div>`
        formData.ArticleContent = newArticleContent
      }

      if (isEdit.value) {
        // 更新数据
        putReferral(formData, id).then((res) => {
          console.log(res)
          ElMessage({
            message: '更新成功成功！',
            type: 'success'
          })

          router.go(-1)
        })
      } else {
        // 上传数据

        postReferral(formData).then((res) => {
          console.log(res)
          if (res.data.code === 201) {
            ElMessage({
              message: '创建成功！',
              type: 'success'
            })
            router.go(-1)
          } else {
            ElMessage({
              message: '创建失败请检查参数！',
              type: 'error'
            })
          }
        })
      }
    } else {
      console.log('error submit!', fields)
    }
  })
}

const goBack = () => {
  router.push({ name: 'adminArticle' })
}
</script>

<style lang="scss" scoped>
.form-box {
  margin: 20px auto;
}
</style>
