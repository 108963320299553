<template>
  <div class="web-artist-list">
    <page-header title="版画艺术家" :backgroundImage="headerBackgroundImage">
      <!-- <template v-slot:body> 123 </template> -->
      <template #right>
        <el-select
          :suffix-icon="Search"
          class="search-input"
          v-model="searchValue"
          filterable
          reserve-keyword
          placeholder="输入名称进行搜索"
          @change="searchChange"
        >
          <el-option
            v-for="item in artistList"
            :key="item.ArticleTitle"
            :label="item.ArticleTitle"
            :value="item.Id"
          /> </el-select
      ></template>
    </page-header>
    <div class="page-body reveal-top">
      <div class="artist-sort-item" v-for="item in artistSort" :key="item.id">
        <el-divider content-position="right">
          <div class="letter-text">{{ item.key }}</div>
        </el-divider>
        <div class="artist-sort-content">
          <div
            class="name-item"
            v-for="nameItem in item.content"
            @click="() => itemClick(nameItem.Id)"
            :key="nameItem.Id"
          >
            {{ nameItem.ArticleTitle }}
          </div>
        </div>
      </div>
    </div>
    <el-backtop :bottom="100">
      <div
        style="
          height: 100%;
          width: 100%;
          background-color: #030204;
          border-radius: 10%;
          text-align: center;
          line-height: 46px;
          color: #f3d737;
        "
      >
        <el-icon color="#fff" size="40"><arrow-up-bold /></el-icon>
      </div>
    </el-backtop>
  </div>
</template>
<script setup>
import pageHeader from '../../../components/page-header'
import headerBackgroundImage from '../../../assets/title-background-image/khd.jpg'

import { Search, ArrowUpBold } from '@element-plus/icons-vue'
import scrollReveal from '../../../utils/scrollReveal'

import { ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { getArtists } from '../../../apis'
import pinyin from '../../../utils/pinyin'
const artistSort = ref([])
const artistList = ref([])
const searchValue = ref('')
const router = useRouter()
const queryParams = reactive({ search: '' })
console.log(queryParams)

const getData = () => {
  getArtists().then((res) => {
    // console.log(res, 9191)
    artistList.value = res.data.data
    artistSort.value = formatData(res.data.data)
  })
}
getData()

onMounted(() => {
  scrollReveal.init()
})
const formatData = (data) => {
  // 暂存排序
  const letterObj = {}
  // 初始化 排序
  data.map((item) => {
    if (
      Array.isArray(letterObj[pinyin.getFirstCamelChars(item.ArticleTitle)])
    ) {
      letterObj[pinyin.getFirstCamelChars(item.ArticleTitle)].push(item)
    } else {
      letterObj[pinyin.getFirstCamelChars(item.ArticleTitle)] = [item]
    }

    return item
  })
  // 初始化 字母表

  const letterArr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    .split('')
    .map((item) => ({ key: item, content: letterObj[item] || [] }))
  console.log(letterArr)

  return letterArr
}
const itemClick = (id) => {
  console.log(name)
  router.push({
    name: 'artistDetail',
    query: {
      id
    }
  })
}
const searchChange = (Id) => {
  router.push({
    name: 'artistDetail',
    query: {
      id: Id
    }
  })
}
</script>

<style lang="scss">
.web-artist-list {
  .search-box {
    display: flex;

    .search-input {
      width: 300px;

      .el-input__inner {
        border-radius: 50px;
        // border-radius: 10px 0px 0px 10px;
        font-size: 18px;
        height: 50px;
        padding: 0 20px;
      }
      .el-select__icon {
        font-size: 20px;
      }
    }
  }

  .artist-sort-item {
    background: #039dfa1a;
    // padding: 4px;
    .letter-text {
      color: #d64e52;
      font-size: 20px;
    }
    .artist-sort-content {
      padding: 20px;
      display: flex;
      flex-wrap: wrap;
      .active-name-item,
      .name-item {
        font-size: 18px;
        border-radius: 4px;
        transition: 0.3s;
        cursor: pointer;
        width: 5em;
        text-align: center;

        // text-align-last: justify;

        margin: 8px 10px;
        padding: 6px;
        background: rgba($color: #fff, $alpha: 0.4);

        &:hover {
          // font-weight: bold;
          transform: scale(1.2);
          color: #d64e52;
          background: rgba($color: #fff, $alpha: 1);
        }
      }

      .active-name-item {
        transform: scale(1.2);
        color: #d64e52;
        background: rgba($color: #fff, $alpha: 1);
      }
    }
  }
}
</style>
