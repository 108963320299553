<template>
  <div class="user-content">
    <div class="page-header">
      <div class="page-title">用户管理</div>
    </div>

    <div>
      <el-descriptions
        v-if="userInfo"
        class="margin-top"
        title=""
        :column="1"
        size="large"
        direction="vertical"
        label-align="right"
        border
      >
        <template #extra>
          <el-button type="primary" @click="editPassword">修改密码</el-button>
        </template>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">用户名</div>
          </template>
          {{ userInfo.UserName }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template #label>
            <div class="cell-item">加入时间</div>
          </template>
          {{ moment(userInfo.JoinDate).format('YYYY-MM-DD  hh:mm:ss') }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">邮箱</div>
          </template>
          {{ userInfo.Email }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template #label>
            <div class="cell-item">描述</div>
          </template>
          {{ userInfo.Description }}
        </el-descriptions-item>
      </el-descriptions>
    </div>

    <!-- 创建 编辑弹窗 -->
    <el-dialog v-model="editPasswordDialog" title="修改密码" width="30%">
      <div>
        <el-form
          ref="ruleFormRef"
          :model="aboutDetail"
          :rules="aboutDetailRules"
          class="demo-ruleForm"
          label-width="80px"
          status-icon
        >
          <el-form-item label="旧密码" prop="oldPassword">
            <el-input v-model="aboutDetail.oldPassword" />
          </el-form-item>
          <el-form-item label="新密码" prop="NewPassword">
            <el-input v-model="aboutDetail.NewPassword" />
          </el-form-item>
          <el-form-item label="再次输入" prop="repeatPassword">
            <el-input v-model="aboutDetail.repeatPassword" />
          </el-form-item>

          <!-- <el-form-item>
      <el-button type="primary" @click="submitForm(ruleFormRef)"
        >Create</el-button
      >
      <el-button @click="resetForm(ruleFormRef)">Reset</el-button>
    </el-form-item> -->
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm(ruleFormRef)">取消</el-button>
          <el-button @click="submitForm(ruleFormRef)" type="primary">{{
            '修改'
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import moment from '../../../utils/moment'
import { ElMessage } from 'element-plus'

import { getUsers, postUserChange } from '../../../apis'
import cookie from 'cookiejs'
const editPasswordDialog = ref(false)
const ruleFormRef = ref()
const aboutDetailRules = reactive({
  oldPassword: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
  NewPassword: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
  repeatPassword: [
    { required: true, message: '请输入重复新密码', trigger: 'blur' }
  ]
})
const aboutDetail = ref({
  oldPassword: '',
  NewPassword: '',
  repeatPassword: ''
})
const userInfo = ref({})
const UserId = cookie.get('UserId')
const userName = cookie.get('UserName')
const editPassword = () => {
  editPasswordDialog.value = true
}

const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!')
      const data = {
        PassWord: aboutDetail.value.oldPassword,
        UserName: userName,
        NewPasswd: aboutDetail.value.repeatPassword
      }
      postUserChange(data)
        .then((res) => {
          getData()
          // console.log(res, '删除数据')
          if (res.data.code === 201) {
            editPasswordDialog.value = false
            ElMessage({
              message: '修改成功',
              type: 'success'
            })
          } else {
            ElMessage({
              message: res.data.data,
              type: 'error'
            })
          }
        })
        .catch(() => {
          ElMessage({
            message: '失败，请稍后再试！',
            type: 'success'
          })
        })
    } else {
      console.log('error submit!', fields)
    }
  })
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
  editPasswordDialog.value = false
}

const getData = () => {
  userInfo.value = { a: '1' }

  getUsers(UserId).then((res) => {
    console.log(res.data.data, 'user')
    // if (res.data.code === 200) {
    userInfo.value = res.data.data
    // }
  })
}

getData()
</script>
<style lang="scss">
.user-content {
  .page-header {
    font-size: 24px;
    margin: 20px 10px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
