<template>
  <CommonLsitPage
    dataUrl="/artists/page"
    :params="pageParams"
    ref="tableRef"
  ></CommonLsitPage>
  <el-dialog v-model="dialogVisible" title="确认操作" width="30%">
    <span
      >此操作将删除版画艺术家
      <span style="color: red">{{ artistsDetail.ArticleTitle }}</span
      >，请谨慎操作。</span
    >
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="danger" @click="deleteArtistsFunction">删除</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import CommonLsitPage from '../../../components/common-list-page.vue'
import moment from '../../../utils/moment'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
// import { BASE_HOST } from '../../../utils/axios'
import { ElMessage } from 'element-plus'
import { deleteArtists } from '../../../apis'

import { Delete, Edit, Tickets } from '@element-plus/icons-vue'

const tableRef = ref(null)
const dialogVisible = ref(false)

const router = useRouter()
// const route = useRoute()
const artistsDetail = ref({})
const pageParams = {
  title: '版画艺术家管理',
  btns: [
    {
      type: 'primary',
      name: '新增艺术家',
      onClick: () => {
        console.log(111)
        router.push({
          name: 'adminArtistOperation'
        })
      }
    }
  ],
  // <el-table-column prop="date" label="Date" width="180" />
  tableColumn: [
    {
      label: '发布时间',
      prop: 'Pubtime',
      sortable: 'custom',
      width: 200,
      formatter: (row, column, cellValue, index) => {
        const str = moment(cellValue).format('YYYY-MM-DD  hh:mm:ss')
        // console.log(str)
        return str
      }
    },

    {
      prop: 'ArticleTitle',
      label: '艺术家名称'
    },
    {
      label: '荣誉称号',
      prop: 'Honor'
      // width: 150
    },
    // {
    //   prop: 'AddUser',
    //   label: '发布者'
    // },

    {
      label: '操作',
      width: 240,
      operations: [
        {
          name: '查看',
          // type: 'plain',
          icon: Tickets,
          onClick: (scope) => {
            const artistsDetailPage = router.resolve({
              name: 'artistDetail',
              query: { id: scope.row.Id }
            })

            window.open(artistsDetailPage.href, '_blank')

            // artistsDetail.value = scope.row
            // showDrawer.value = true
          }
        },
        {
          name: '编辑',
          type: 'success',
          icon: Edit,
          onClick: (scope) => {
            // console.log(tableRef.value.refresh())
            console.log(scope.row.Id, 1111)

            router.push({
              name: 'adminArtistOperation',
              query: {
                id: scope.row.Id
              }
            })
          }
        },
        {
          name: '删除',
          type: 'danger',
          icon: Delete,

          onClick: (scope) => {
            artistsDetail.value = scope.row
            dialogVisible.value = true
            console.log(scope, 1111)
          }
        }
      ]
    }
  ]
}

const deleteArtistsFunction = () => {
  deleteArtists(artistsDetail.value.Id).then((res) => {
    tableRef.value.refresh()
    // console.log(res, '删除数据')
    dialogVisible.value = false
    ElMessage({
      message: '删除成功',
      type: 'success'
    })
  })
}
</script>

<style scoped lang="scss">
.cell-item {
  width: 6em;
}
.dec-box {
  // background: red;
}
</style>
