<template>
  <div class="navigation-bar-content">
    <!-- {{ props.defaultActive }} -->
    <div
      :class="active === item.value ? 'active item' : 'item'"
      v-for="(item, index) in props.data"
      :key="index"
      @click="itemClick(item)"
    >
      <div>{{ item.label }}</div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue'
const props = defineProps({
  data: Array,
  defaultActive: String
})
const active = ref(props.defaultActive || '')
const emits = defineEmits(['itemClick'])

const itemClick = (item) => {
  emits('itemClick', item.value)
  // console.log(item)
  active.value = item.value
}
</script>

<style lang="scss">
.navigation-bar-content {
  display: flex;
  align-items: center;
  .item {
    position: relative;
    transition: 0.3s;
    margin: 0 4px;
    // width: 20%;
    text-align: center;

    flex: 1;
    font-size: 16px;
    padding: 14px 4px;

    cursor: pointer;

    &:after,
    &::before {
      transition: 0.3s;
      content: '';
      background: #fd7623;
      position: absolute;
      width: 0%;

      height: 2px;
    }
    &:after {
      top: 0;
      left: 5%;
    }
    &::before {
      bottom: 0;
      right: 5%;
    }

    &:hover {
      background-color: rgba($color: #fff, $alpha: 0.2);
      color: #fd7623;
      border-bottom: 3px solid transparent;
      // padding-bottom: 17px;
      font-size: 18px;

      // border-bottom: 4px solid #fd7623;
      // padding-bottom: 10px;

      &:after,
      &::before {
        width: 90%;
      }
      // &:after {
      //   top: 0;
      //   left: 0%;
      // }
      // &::before {
      //   bottom: 0;
      //   right: 0;
      // }
    }
  }
  .active {
    // background-image: url(../assets/img/navbar-background.png);
    // background-repeat: no-repeat;
    // background-size: auto 100%;
    border-radius: 4px;
    border-bottom: 3px solid #fd7623;
    background: #fff;
    color: #fd7623;
    font-weight: bold;
    text-shadow: 2px 2px 2px #fff;
    font-size: 18px;

    background-position: center bottom;
  }
}
</style>
