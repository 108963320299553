<template>
  <div class="overview-content">
    <div class="overview-title">藏品数量统计</div>
    <div class="chart-dom" ref="WchartDom"></div>
    <div class="chart-dom" ref="PchartDom"></div>
    <div class="overview-title">文章数量统计</div>
    <div class="article-item" v-for="item in articleNumList" :key="item.Id">
      <div class="count">
        {{ item.Count }}
      </div>
      <div class="name">
        {{ item.Name }}
      </div>
    </div>
  </div>
</template>
<script setup>
import * as echarts from 'echarts'
import { onMounted, ref } from 'vue'
// import { useRouter } from 'vue-router'
import { getProductCategory, getArticleDivisionsCount } from '../../../apis'

import scrollReveal from '../../../utils/scrollReveal'
// const router = useRouter()
const WchartDom = ref()
const PchartDom = ref()
const WorkmanshipCategory = ref([])
let WchartCom = null
let PchartCom = null
onMounted(() => {
  scrollReveal.init()

  // 基于准备好的dom，初始化echarts实例
  WchartCom = echarts.init(WchartDom.value)
  PchartCom = echarts.init(PchartDom.value)
  // console.log(echarts, chartCom, 111)
})

const PeriodCategory = ref([])

// 获取藏品数据
getProductCategory().then((res) => {
  res.data.data.map((item) => {
    if (item.CatName === '形式') {
      const arr = item.Subclass
      WorkmanshipCategory.value = arr
    }
    if (item.CatName === '时期') {
      const arr = item.Subclass
      PeriodCategory.value = arr
    }
    return item
  })

  const xDataW = []
  const yDataW = []

  // 版种分类图
  WorkmanshipCategory.value.map((item) => {
    xDataW.push(item.CatName)
    yDataW.push(item.Count)
    return item
  })
  // 绘制图表
  WchartCom.setOption({
    title: {
      text: '版画种类数量分布图'
    },

    tooltip: {},
    xAxis: {
      data: xDataW,
      axisLabel: { interval: 0, rotate: 30 }
    },
    yAxis: {},

    series: [
      {
        name: '版画种类',
        type: 'bar',
        data: yDataW
      }
    ]
  })

  // 年代分类图
  const DataP = []

  PeriodCategory.value.map((item) => {
    DataP.push({
      value: item.Count,
      name: item.CatName
    })
    return item
  })
  PchartCom.setOption({
    title: {
      text: '版画时期数量分布图'

      // left: 'center'
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      top: 'bottom',
      left: 'center'
    },
    series: [
      {
        name: '时期',
        type: 'pie',
        radius: '50%',
        data: DataP,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  })
})
const articleNumList = ref([])
getArticleDivisionsCount().then((res) => {
  articleNumList.value = res.data.data.map((item) => {
    if (item.Count <= 0) {
      item.Count = '暂无数据'
    } else if (item.Count <= 9) {
      item.Count = '000' + item.Count
    } else if (item.Count <= 99) {
      item.Count = '00' + item.Count
    } else if (item.Count <= 999) {
      item.Count = '0' + item.Count
    }
    return item
  })
  console.log(res.data, 'getArticleDivisionsCount')
})

// const toPrintList = (params) => {
//   // type 1形式 2时期

//   router.push({
//     name: 'collectionList',
//     query: { type: params.type, category: params.item.Id }
//   })
// }
</script>
<style lang="scss">
.overview-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .chart-dom {
    border-radius: 10px;

    background: rgba($color: #1997fa, $alpha: 0.1);
    padding: 20px;
    margin: 10px;

    width: 100%;
    // flex: 1;
    height: 30vh;
  }
  .article-item {
    border-radius: 10px;
    position: relative;
    // width: 25%;
    height: 15vh;
    min-height: 180px;
    flex: 1;
    background: rgba($color: #858585, $alpha: 0.1);
    // padding: 20px;
    margin: 10px;
    .count {
      text-align: center;
      margin: 50px auto;
      font-size: 40px;
      color: #1997fa;
    }
    .name {
      font-weight: bold;
      // color: #1997fa;
      position: absolute;
      bottom: 20px;
      text-align: center;
      font-size: 20px;
      width: 100%;
    }
  }
  .overview-title {
    border-bottom: 2px #1997fa solid;
    width: 100%;
    font-size: 24px;
    // line-height: 60px;
    padding: 10px;
    margin: 10px 10px 0;
  }
}
</style>
