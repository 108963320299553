<template>
  <CommonLsitPage
    dataUrl="/watermark/"
    :params="pageParams"
    ref="tableRef"
  ></CommonLsitPage>
  <el-dialog v-model="dialogVisible" title="确认操作" width="30%">
    <span
      >此操作将删除数据
      <span style="color: red">{{ collectionDetail.ProductName }}</span
      >，请谨慎操作。</span
    >
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="danger" @click="deleteCollection">删除</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 展示应该是 在 透明背景 红绿蓝黑白 背景都展示一个 -->
  <el-drawer size="40%" v-model="showDrawer" :Direction="direction">
    <template #title>
      <h2>藏品详情</h2>
    </template>
    <template #default>
      <el-descriptions class="margin-top" title="藏品参数" :column="1" border>
        <template #extra>
          <!-- <el-button type="success">编辑</el-button> -->
        </template>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">缩略图</div>
          </template>
          <el-image
            style="width: 300px; height: 300px"
            :src="BASE_HOST + 'v1/images/' + collectionDetail.ProductSpic"
            :preview-src-list="[
              BASE_HOST + 'v1/images/' + collectionDetail.ProductBpic,
            ]"
            :initial-index="1"
            fit="cover"
          />
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">藏品类型</div>
          </template>

          <el-tag
            type="success"
            effect="dark"
            v-if="collectionDetail.ProductVip"
            >馆藏精品</el-tag
          >
          <el-tag type="info" effect="dark" v-else>一般藏品</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">藏品编号</div>
          </template>
          {{ collectionDetail.ProductNumber }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">藏品名称</div>
          </template>
          {{ collectionDetail.ProductName }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">藏品尺寸</div>
          </template>
          {{ collectionDetail.ProAuthor }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template #label>
            <div class="cell-item">藏品时期</div>
          </template>
          {{ getClassification(collectionDetail.CatId2) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">藏品年代</div>
          </template>
          {{ collectionDetail.ProductYears }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">藏品工艺</div>
          </template>
          {{ collectionDetail.ProductMold }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">作者</div>
          </template>
          {{ collectionDetail.ProductOwner }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">发布时间</div>
          </template>
          {{
            moment(collectionDetail.ProductTime * 1000).format(
              "YYYY-MM-DD  hh:mm:ss"
            )
          }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">描述</div>
          </template>
          <div class="dec-box">
            <div v-html="collectionDetail.ProductContent"></div>
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </template>
    <!-- <template #footer>
      <div style="flex: auto">
        <el-button @click="cancelClick">cancel</el-button>
        <el-button type="primary" @click="confirmClick">confirm</el-button>
      </div>
    </template> -->
  </el-drawer>
</template>

<script setup>
import CommonLsitPage from '../../../components/common-list-page.vue'
import moment from '../../../utils/moment'
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { BASE_HOST } from '../../../utils/axios'
import { ElMessage } from 'element-plus'
import { deleteProduct } from '../../../apis'
import {
  // 获取全部种类的方法
  classification
} from '../../../utils/get-classification'
import { Delete, Edit, Tickets } from '@element-plus/icons-vue'
const classificationData = computed({
  get: () => {
    return {
      periodList: [
        // { label: '全部时期', value: 0 },
        ...classification.periodList
      ],
      // formList: [{ label: '全部形式', value: 0 },
      ...classification.formList
    }
  }
})
const tableRef = ref(null)
const dialogVisible = ref(false)
const getClassification = (id) => {
  const findObj =
    classificationData.value.periodList.find((v) => v.value === id) || {}
  return findObj.label
}

const direction = ref('rtl')
const showDrawer = ref(false)
const router = useRouter()
const collectionDetail = ref({})
const pageParams = {
  title: '水印管理',
  btns: [
    {
      type: 'primary',
      name: '新增',
      onClick: () => {
        console.log(111)
        router.push({
          path: '/admin/collection/operation'
        })
      }
    }
  ],
  // <el-table-column prop="date" label="Date" width="180" />
  tableColumn: [
    {
      label: '发布时间',
      prop: 'JoinDate',
      sortable: 'custom',
      width: 200,
      formatter: (row, column, cellValue, index) => {
        const str = moment(cellValue).format('YYYY-MM-DD  hh:mm:ss')
        // console.log(str)
        return str
      }
    },

    {
      prop: 'Name',
      label: '水印名称'

      // width: 180,
      // sortable: 'custom',
      // filters: [
      //   { text: '2016-05-01', value: '2016-05-01' },
      //   { text: '2016-05-02', value: '2016-05-02' },
      //   { text: '2016-05-03', value: '2016-05-03' },
      //   { text: '2016-05-04', value: '2016-05-04' }
      // ]
    },
    {
      prop: 'Description',
      label: '描述'
    },
    {
      prop: 'State',
      label: '使用状态',
      formatter: (row, column, cellValue, index) => {
        const str = cellValue ? '使用中' : '未使用'
        // console.log(cellValue)
        return str
      }
    },
    {
      label: '操作',
      width: 240,
      operations: [
        {
          name: '查看',
          // type: 'plain',
          icon: Tickets,
          onClick: (scope) => {
            console.log(scope.row.ProductSpic, 1111)
            collectionDetail.value = scope.row
            showDrawer.value = true
          }
        },
        {
          name: '编辑',
          type: 'success',
          icon: Edit,
          onClick: (scope) => {
            // console.log(tableRef.value.refresh())
            console.log(scope.row.Id, 1111)

            router.push({
              path: '/admin/collection/operation',
              query: {
                id: scope.row.Id
              }
            })
          }
        },
        {
          name: '删除',
          type: 'danger',
          icon: Delete,

          onClick: (scope) => {
            collectionDetail.value = scope.row
            dialogVisible.value = true
            console.log(scope, 1111)
          }
        }
      ]
    }
  ]
}

const deleteCollection = () => {
  deleteProduct(collectionDetail.value.Id).then((res) => {
    tableRef.value.refresh()
    // console.log(res, '删除数据')
    dialogVisible.value = false
    ElMessage({
      message: '删除成功',
      type: 'success'
    })
  })
}
</script>

<style scoped lang="scss">
.cell-item {
  width: 6em;
}
.dec-box {
  // background: red;
}
</style>
