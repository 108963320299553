import Axios from 'axios'
import Cookie from 'cookiejs'
import { ElMessage } from 'element-plus'
let BASE_HOST = ''
BASE_HOST = '/'
BASE_HOST = 'http://www.3zmuseum.com/'
// export const BASE_HOST = 'http://101.34.84.72/'
// BASE_HOST = 'http://123.57.42.75:11080/'
// BASE_HOST = 'http://101.34.84.72/'
export { BASE_HOST }

const getToken = () => {
  const token = Cookie.get('Token')

  return token
}

const newAxios = Axios.create({
  // baseURL: '/apis/',
  baseURL: BASE_HOST + 'v1/',
  timeout: 200000

  // headers: { Authorization: getToken() }
})

// 添加请求拦截器
newAxios.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    // console.log(config, 123)

    return {
      ...config,
      headers: { Authorization: getToken(), ...config.headers }
    }
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
newAxios.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return response
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    console.log('请求失败：', error)
    ElMessage({
      message: '请求失败，请刷新后重试。',
      type: 'error'
    })
    return Promise.reject(error)
  }
)

export default newAxios
// (url, otherParams = {}) => {
//   // otherParams.headers = {
//   //   Authorization: getToken(),
//   //   ...otherParams?.headers
//   // }

//   return newAxios
// }
