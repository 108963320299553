<!-- 自定义列表 管理平台用 -->
<template>
  <div class="page-header">
    <div class="page-title">
      {{ props.params.title }}
    </div>
  </div>

  <el-space class="header-box">
    <div class="search-box">
      <el-input
        class="search-input"
        v-model="requestParams.keys"
        clearable
        placeholder="请输入搜索内容"
      >
        <!-- <template #append>

      </template> -->
      </el-input>
      <el-button type="" :icon="Search" @click="onSearch"> 搜索</el-button>
    </div>

    <el-button
      @click="item.onClick(item)"
      :type="item.type"
      v-for="(item, index) in props.params.btns"
      v-show="item.onShow ? item.onShow(item) : true"
      v-bind:key="index"
    >
      {{ item.name }}
    </el-button>
  </el-space>

  <div class="table-box">
    <el-table
      :data="tableData.value"
      stripe
      style="width: 100%"
      @sort-change="sortChange"
      :filter-multiple="false"
      @filter-change="filterFun"
      v-loading="loading"
      element-loading-text="数据加载中..."
    >
      <el-table-column
        v-for="item in props.params.tableColumn"
        v-bind:key="item.prop"
        :prop="item.prop"
        :label="item.label"
        :column-key="item.columnKey || item.prop"
        :width="item.width"
        :sortable="item.sortable"
        :filters="item.filters"
        :filter-multiple="item.filterMultiple || false"
        :formatter="item.formatter"
      >
        <template
          #default="scope"
          v-if="item.operations && item.operations.length"
        >
          <el-space :size="10">
            <el-button
              v-for="(operationItem, operationInex) in getShowButtn(
                item.operations,
                scope
              )"
              v-bind:key="operationInex"
              :type="operationItem.type || ''"
              :icon="operationItem.icon"
              :size="operationItem.size || 'small'"
              @click.prevent="operationItem.onClick(scope)"
            >
              {{ operationItem.name }}
            </el-button>
          </el-space>
        </template>
      </el-table-column>
    </el-table>
  </div>

  <div class="pagination-box">
    <el-pagination
      background
      layout="sizes,total,prev, pager, next,jumper"
      :total="pagination.total"
      :page-sizes="[10, 20, 40, 60, 80, 100]"
      v-model:currentPage="pagination.page"
      v-model:page-size="pagination.pageSize"
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :disabled="loading"
    />
  </div>
</template>

<script setup>
import { Search } from '@element-plus/icons-vue'
import { ref, reactive, defineProps, watchEffect, defineExpose } from 'vue'
import http from '../utils/axios'
import { ElMessage } from 'element-plus'

const props = defineProps({
  params: Object,
  dataUrl: String,
  requestParams: Object
})
const tableData = reactive({ value: [] })
const loading = ref(true)

const pagination = reactive({
  page: 2,
  total: 0,
  pageSize: 0
})

const requestParams = reactive({
  page: 1,
  size: 20,
  query: '',
  keys: '',
  sortby: '',
  order: '',
  ...props.requestParams
})
// {prop: 'date', order: 'descending'}

const getShowButtn = (buttons, scope) => {
  // 过滤是否展示
  buttons = buttons.filter((btn) => {
    const isShow = btn.onShow ? btn.onShow(scope) : true
    return isShow
  })
  return buttons
}
const sortChange = (v) => {
  // sortby=ProductTime&order=asc
  console.log(444, v)
  //   order: "descending"
  // prop: "ProductTime"
  const key = {
    descending: 'desc',
    ascending: 'asc'
  }
  requestParams.sortby = v.prop
  requestParams.order = key[v.order]
  getData()
  // return true
}

const filterFun = (filter) => {
  let query = ''
  for (const key in filter) {
    if (Object.hasOwnProperty.call(filter, key)) {
      const element = filter[key]
      element.map((item) => {
        query += key + ':' + item + ';'
        return item
      })
    }
  }
  // filter.name.map((k) => console.log(k))

  getData({
    query
  })

  // const property = column['property']
  // return row[property] === value
}

const pageSizeChange = (val) => {
  // console.log(val)
  getData({
    size: val
  })
}

const pageCurrentChange = (val) => {
  // console.log(val)
  getData({
    page: val
  })
}

const getData = (params) => {
  loading.value = true

  http
    .get(props.dataUrl, {
      params: {
        ...requestParams,
        ...params
      }
    })
    .then((res) => {
      tableData.value = res.data.data
      pagination.total = res.data.total
      pagination.page = res.data.page
      pagination.pageSize = res.data.pagesize

      loading.value = false
      console.log('数据api', res)
    })
    .catch(() => {
      ElMessage.error('请求失败，请重试！')
    })
}
// 初始化
getData()

const refresh = () => {
  getData({
    page: 1,
    query: '',
    keys: '',
    ...props.requestParams

    // sortby: 'ProductTime',
    // order: 'desc'
  })
}
defineExpose({
  refresh
})

const onSearch = () => {
  getData({
    keys: requestParams.keys
  })
}

// let timer = null
watchEffect((onInvalidate) => {
  // timer = setTimeout(() => {
  // }, 1000)
  // onInvalidate(() => {
  //   clearTimeout(timer)
  // })
})
</script>

<style scoped lang="scss">
.page-header {
  font-size: 24px;
  margin: 20px 10px;
  display: flex;
  justify-content: space-between;
}

.pagination-box,
.header-box,
.table-box {
  margin: 10px;
}
.header-box {
  display: flex;
  justify-content: end;
}
.pagination-box {
  // width: 100%;
  // background: rebeccapurple;
  display: flex;
  justify-content: end;
}
.search-box {
  display: flex;
  min-width: 360px;
  max-width: 500px;
}
.search-input {
}
</style>
