<template>
  <div class="web-collection-list">
    <page-header
      title="馆藏精品"
      :backgroundImage="headerBackgroundImage"
      backgroundImagePosition="center center"
    >
      <template v-slot:body> </template>
      <template #right>
        <!-- <el-select
          class="search-input"
          v-model="queryParams.CatId3"
          placeholder="名家"
          size="large"
        >
          <el-option
            v-for="item in authorType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-select
          class="search-input"
          v-model="queryParams.CatId2"
          placeholder="时期"
          size="large"
        >
          <el-option
            v-for="item in classificationData.periodList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-select
          class="search-input"
          v-model="queryParams.CatId1"
          placeholder="形式"
          size="large"
        >
          <el-option
            v-for="item in classificationData.formList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select> -->
        <el-input
          size="large"
          v-model="queryParams.search"
          class="search-input"
          clearable
          placeholder="请输入搜索内容"
        />
        <el-button type="primary" class="search-button" @click="searchFun"
          >搜索</el-button
        ></template
      >
    </page-header>
    <div
      class="list-content"
      :infinite-scroll-distance="60"
      v-infinite-scroll="getData"
      :infinite-scroll-disabled="disLoad"
      :style="listData.length === 0 ? 'height:auto;' : ''"
    >
      <div
        class="list-item"
        v-for="item in listData"
        :key="item.Id"
        @click="itemClick(item)"
      >
        <el-image
          lazy
          fit="contain"
          class="item-image"
          :src="BASE_HOST + 'v1/images/' + item.ProductSpic"
        >
          <template #placeholder>
            <el-image class="img-status" :src="loadingImage"></el-image>
          </template>
          <template #error>
            <el-image class="img-status" :src="noImage"></el-image>
          </template>
        </el-image>

        <div class="product-name">
          {{ item.ProductName }}
        </div>

        <div class="product-dec">
          <div class="product-dec-name">{{ item.ProductName }}</div>
          <div>编号： {{ item.ProductNumber }}</div>
          <div>形式： {{ item.ProductMold }}</div>
          <div>时期： {{ getClassification(item.CatId2, "form") }}</div>

          <div>作者： {{ item.ProductOwner }}</div>
          <div>尺寸： {{ item.ProAuthor }}</div>

          <div>
            发布日期：
            {{ moment(item.ProductTime * 1000).format("YYYY-MM-DD") }}
          </div>
        </div>
      </div>

      <div
        v-if="loading"
        :style="{
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%',
          height: '80%',
          overflow: 'hidden',
        }"
      ></div>
      <div v-if="noNewData" class="noNewData">
        数据全部加载完毕，暂无新数据。
      </div>
      <div class="no-data" v-if="noData">
        <el-empty description="暂无内容！" />
      </div>
      <div class="load-data" v-if="loadData">
        <LoadingData></LoadingData>
      </div>
    </div>
  </div>
</template>

<script setup>
import pageHeader from '../../../components/page-header'
import headerBackgroundImage from '../../../assets/title-background-image/hzy.jpg'
import {
  // ,
  // 获取全部种类的方法
  classification
} from '../../../utils/get-classification'

import { BASE_HOST } from '../../../utils/axios'
import LoadingData from '../../../components/loading-data.vue'
// import SilkRibbon from '../../../components/silk-ribbon.vue'
import loadingImage from '../../../assets/img/loading-image.png'
import noImage from '../../../assets/img/no-image.png'
import { getProductGallery } from '../../../apis'

import { ref, onMounted, reactive, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
// import { Search } from '@element-plus/icons-vue'
import moment from 'moment'

const route = useRoute()
const router = useRouter()
const listData = ref([])
// 加载页面
const loading = ref(true)
// 加载数据
const loadData = ref(false)
// 无数据
const noData = ref(false)
// 禁用加载
const disLoad = ref(false)

// 最后一页
const noNewData = ref(false)

// const SearchValue = ref('')
// const authorType = [
//   { label: '全部作家', value: 0 },
//   { label: '名家作品', value: 23 },
//   { label: '非名家作品', value: 11 }
// ]
const queryParams = reactive({
  // 形式形式--->> 4 木刻 5 套色木刻 6 水印木刻 7 铜版画 8 石版画 12 丝网版画 13 其它版画 14 插图 22 藏书票 24 年画 25 儿童版画 26 外国版画 27 印版 28 珍贵画册印品 29 版画书籍 30 宣传画 31 学生习作 33 红色收藏
  CatId1: '',
  // 时期
  CatId2: '',
  // 名家
  CatId3: '',
  search: ''
})
// console.log(classification, 1235)

// const classificationData = reactive({
//   ...classification
// })

const classificationData = computed({
  get: () => {
    return {
      periodList: [
        { label: '全部时期', value: 0 },
        ...classification.periodList,
        { label: '其他时期', value: 15 }
      ],
      formList: [{ label: '全部形式', value: 0 }, ...classification.formList]
    }
  }
})

const getClassification = (id) => {
  const findObj =
    classificationData.value.periodList.find((v) => v.value === id) || {}
  return findObj.label
}

// console.log(classificationData, 123123)
let page = 0

// 初始化数据
if (route.query.type || route.query.category) {
  if (route.query.type === '1') {
    queryParams.CatId1 = Number(route.query.category)
  } else {
    queryParams.CatId2 = Number(route.query.category)
  }
}

// .map((item) => {
//   if (item.CatName === '形式') {
//     const arr = item.Subclass

//     arr.map((v) => {
//       v.label = v.CatName
//       v.value = v.Id
//       return v
//     })
//     arr.unshift({ label: '全部形式', value: 0 })
//     // console.log(123, arr)
//     form.value = arr
//   }
//   if (item.CatName === '时期') {
//     const arr = item.Subclass

//     arr.map((v) => {
//       v.label = v.CatName
//       v.value = v.Id
//       return v
//     })
//     arr.unshift({ label: '全部时期', value: 0 })

//     period.value = arr
//   }

//   return item
// })

// const loadDataFun = () => {
//   console.log(12333, queryParams)
// }
const getData = () => {
  loadData.value = true
  page = page + 1

  console.log('加载', page)
  const queryArr = []

  if (queryParams.CatId1) {
    queryArr.push(`CatId1:${queryParams.CatId1}`)
  }
  if (queryParams.CatId2) {
    queryArr.push(`CatId2:${queryParams.CatId2}`)
  }

  if (queryParams.CatId3) {
    queryArr.push(`CatId3:${queryParams.CatId3}`)
  }
  // alert(123, listData.value.length)
  const params = {
    size: 20,
    page,
    query: queryArr.join(','),
    keys: ''
  }
  if (queryParams && queryParams.search && queryParams.search.replace) {
    params.keys = queryParams.search.replace(/\s+/g, ',')
  }
  getProductGallery(params).then((res) => {
    // 页面初始化
    loadData.value = false
    loading.value = false
    disLoad.value = false
    noData.value = false
    noNewData.value = false
    // console.log(res.data, 1)
    // code: 200
    // data: (20)
    // page: 1
    // pagesize: 20
    // state: "success"
    // total: 1141
    if (res.data.data === null || res.data.data.length === 0) {
      // 检测是否无数据
      noData.value = true
      disLoad.value = true
      console.log('无数据')
    } else if (res.data.page * res.data.pagesize >= res.data.total) {
      // 检测是否 最后一页
      noNewData.value = true
      disLoad.value = true

      const arr = [...res.data.data]

      const startIndex = (res.data.page - 1) * res.data.pagesize
      arr.map((item, index) => {
        listData.value[startIndex + index] = item
        return item
      })
      console.log('最后一页')
    } else {
      const arr = [...res.data.data]

      const startIndex = (res.data.page - 1) * res.data.pagesize
      arr.map((item, index) => {
        listData.value[startIndex + index] = item
        return item
      })
    }
  })
  // .catch((err) => {
  //   console.log(err)
  // })
}

const searchFun = () => {
  loading.value = true
  listData.value = []
  page = 0

  queryParams.CatId1 = ''
  queryParams.CatId2 = ''
  queryParams.CatId3 = ''
  getData()
}
onMounted(() => {
  setTimeout(() => {
    if (listData.value === 0) {
      getData()
      console.log('主动加载')
    }
  }, 5000)
})

const itemClick = (item) => {
  // router.push({
  //   name: 'collectionDetail',
  //   query: { id: item.Id }
  // })

  const collectionDetail = router.resolve({
    name: 'collectionDetail',
    query: { id: item.Id }
  })

  window.open(collectionDetail.href, '_blank')
}
</script>
<style lang="scss">
.web-collection-list {
  height: 100%;
  // overflow: hidden;
  margin-bottom: 20px;
  // overflow: auto;
  .search-box {
    display: flex;
    .search-button {
      width: 120px;
      border-radius: 0 10px 10px 0;
      font-size: 18px;
      height: 50px;
      padding: 0 20px;
    }
    .search-input {
      width: 180px;
      /* background: #039dfa1a; */

      /* &:nth-child(1) {
        width: 140px;

        .el-input__inner {
          border-radius: 10px 0px 0px 10px;
        }
      }
      &:nth-child(2) {
        width: 200px;

        .el-input__inner {
          border-radius: 0px;
        }
      }
      &:nth-child(3) {
        width: 180px;

        .el-input__inner {
          border-radius: 0px;
        }
      } */
      /* &:nth-child(4) { */
      width: 200px;

      .el-input__inner {
        border-radius: 0px;
      }
      /* } */

      .el-input__inner {
        border-radius: 50px;

        height: 50px;
        padding: 0 20px;
      }
      .el-input__icon {
        font-size: 20px;
      }
    }
  }

  .collection-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    height: 25vh;
    background-position: center center;
    background-image: url(../../../assets/title-background-image/hzy.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 4vh 10vw;
    line-height: 60px;
    .title {
      // position: absolute;

      font-size: 60px;
      color: white;
      // font-weight: bold;
    }
  }

  .list-content {
    padding: 20px;
    height: calc(100vh - 100px);
    overflow: auto;
    margin: 20px auto;
    width: 96%;
    background: rgba($color: #000, $alpha: 0.1);
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
    // height: 1000px;

    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      background-color: #f2c600;
      // background-image: -webkit-linear-gradient(
      //   30deg,
      //   rgba(1, 105, 240, 0.801) 25%,
      //   transparent 25%,
      //   transparent 50%,
      //   rgba(1, 105, 240, 0.801) 50%,
      //   rgba(1, 105, 240, 0.801) 75%,
      //   transparent 75%,
      //   transparent
      // );
    }

    .list-item {
      color: #fff;
      cursor: pointer;
      flex: auto;
      min-width: 15em;
      min-height: 260px;

      max-height: 340px;
      max-width: 20em;
      margin: 10px auto;
      padding: 10px;
      border-radius: 4px;
      // width: 300px;
      // height: 200px;
      position: relative;
      background-color: rgba($color: #fff, $alpha: 0.5);
      overflow: hidden;
      .item-image {
        transition: 0.3s;
        text-align: center;
        width: 100%;
        height: calc(100% - 40px);
      }
      .img-status {
        // width: 200px;
        height: 200px;
        margin: 0 auto;
      }

      .product-name {
        text-align: center;
        line-height: 40px;
        font-size: 18px;
        color: #000;
      }
      .product-dec {
        transition: 0.3s;
        height: 0;
        overflow: hidden;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;

        font-size: 14px;
        line-height: 30px;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        align-content: center;
        padding: 20px;

        div {
          width: 100%;
        }
        .product-dec-name {
          color: #f2c600;

          // line-height: 50px;
          //    margin-top: 60px;
          font-size: 16px;
        }
      }

      &:hover {
        .item-image {
          height: calc(100%);
        }
        .product-dec {
          // position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          height: 100%;
          width: 100%;
          background: rgba($color: #000, $alpha: 0.5);
        }
      }

      .preview-image {
        transition: 0.2s;
        width: 100%;
        // background: red;
        position: absolute;
        top: 0%;
        // margin-top: -50%;
        z-index: 1;
        // display: none;
        transform: translateY(-25%) scale(0);
      }
      &:hover {
        .preview-image {
          // display: block;
          transform: translateY(-25%) scale(1.2);
        }
      }
    }
    .noNewData {
      text-align: center;
      width: 100%;
      padding: 10px 0;
      color: #fff;
    }
    .load-data {
      // background: red;
      // line-height: 20px;

      margin: 20px auto;
      width: 100%;
    }
    .no-data {
      margin: 20px auto;
      width: 100%;
    }
  }
}
</style>
