import { BASE_HOST } from './axios'

export default (richtext) => {
  richtext = richtext.replace(
    /<[img|IMG].*?src=['|"](.*?(?:[.jpg|.jpeg|.png|.gif|.bmp|.JPG|.JPEG|.PNG|.GIF|.BMP]))['|"].*?[/]?>/g,

    function (match, capture) {
      if (!capture.includes('http://') && !capture.includes('https://')) {
        // match = match.replace(capture, BASE_HOST + '' + capture)
        if (match.indexOf('/v1/images/') === -1) {
          match = match.replace(capture, BASE_HOST + 'v1/images/' + capture)
        } else {
          match = match.replace(capture, BASE_HOST + capture)

          match = match.replace('/v1/images/', 'v1/images/')
        }
      }
      return match
    }
  )

  return richtext
}

export const replaceCopyImgPath = (richtext) => {
  // richtext = richtext.replace(
  //   /<[img|IMG] .*src=['|"]([^'|"]+)[>]*>/g,
  //   // /<[img|IMG].*?src=['|"](.*?(?:[.jpg|.jpeg|.png|.gif|.bmp|.JPG|.JPEG|.PNG|.GIF|.BMP]))['|"].*?[/]?>/g,
  //   function (match, capture) {
  //     // match = '<p>' + capture + '</p>'
  //     // match = match.replace(capture, BASE_HOST + 'v1/cms/frame?target=' + capture)

  //     return match
  //   }
  // )
  richtext = richtext.replace(/<div/gi, '<p')
  richtext = richtext.replace(/<\/div>/gi, '</p>')

  richtext = richtext.replace(/<pre/gi, '<p')
  richtext = richtext.replace(/<\/pre>/gi, '</p>')

  richtext = richtext.replace(/<section/gi, '<p')
  richtext = richtext.replace(/<\/section>/gi, '</p>')

  richtext = richtext.replace(/<blockquote/gi, '<p')
  richtext = richtext.replace(/<\/blockquote>/gi, '</p>')

  richtext = richtext.replace(
    /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
    function (match, capture) {
      return `<img src="${BASE_HOST + 'v1/cms/frame?target=' + capture}"></img>`
      // return `<img src="${BASE_HOST + 'v1/images/museum/collection/thumb/1657089919010952146.jpg'}"  data-type="jpeg" ></img>`
    }
  )

  // console.log(richtext, 871)

  return richtext

  // const pDom = document.createElement('p')
  // pDom.innerHTML = richtext
  // const allImg = pDom.querySelectorAll('img')
  // // console.log(, 9871)
  // for (let index = 0; index < allImg.length; index++) {
  //   const element = allImg[index]
  //   console.log(element, 91)
  //   const imgDom = document.createElement('img')
  //   imgDom.src = BASE_HOST + 'v1/cms/frame?target=' + element.src
  //   // element.setAttribute('src', BASE_HOST + 'v1/cms/frame?target=' + element.src)
  //   element.innerHTML = imgDom
  // }

  // //  = `<img  src="${BASE_HOST + 'v1/cms/frame?target=' + capture}" />`
  // return pDom
}
