<template>
  <div class="admin-layout">
    <el-container class="admin-layout-container">
      <el-header class="admin-layout-header" style="">
        <el-space
          style="width: 100%; height: 100%; justify-content: space-between"
        >
          <el-space class="login">
            <el-image style="height: 50px; padding: 5px" :src="logo" />
            <!-- <img src="../../assets/logo.png" /> -->
            <div class="title">春秋版画博物馆-后台管理系统</div>
          </el-space>
          <el-space class="user-info">
            <el-link type="primary"
              >尊敬的，{{ userInfo || '用户' }}您好！</el-link
            >
            <el-dropdown @command="command">
              <el-avatar
                src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
              />

              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="logout">登出</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </el-space>
        </el-space>
      </el-header>
      <el-container>
        <el-aside>
          <el-menu
            style="height: 100%"
            active-text-color="#ffd04b"
            background-color="#545c64"
            class="el-menu-vertical-demo"
            :default-active="activeMenu"
            text-color="#fff"
            @open="handleOpen"
            @close="handleClose"
          >
            <el-menu-item
              v-for="item in menuList"
              v-bind:key="item.path"
              :index="item.path"
              @click="clickMenu(item)"
            >
              <el-icon>
                <component :is="item.icon" />
              </el-icon>
              <span>{{ item.label }}</span>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-main>
          <router-view />
          <el-backtop :right="100" :bottom="100">
            <div
              style="
                border-radius: 4px;
                /* font-size: 14px; */
                height: 100%;
                width: 100%;
                background-color: #1997fa;
                box-shadow: var(--el-box-shadow-lighter);
                text-align: center;
                line-height: 40px;
                color: #fff;
              "
            >
              UP
            </div>
          </el-backtop>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script setup>
import {
  Menu as IconMenu,
  Odometer,
  User,
  // Stamp,
  PictureRounded,
  ChatDotSquare,
  MessageBox,
  Service,
  Share,
  Reading,
  Star,
  DataLine
} from '@element-plus/icons-vue'

import logo from '../../assets/img/logo.png'
import { getUsers } from '../../apis'
import { ElMessage } from 'element-plus'
import { useRouter, useRoute } from 'vue-router'
import cookie from 'cookiejs'
import { ref } from 'vue'
const handleOpen = () => {}
const handleClose = () => {}
// import { ref } from 'vue'

const router = useRouter()
const route = useRoute()
const userInfo = ref()

const menuList = [
  { label: '总览', path: 'overview', icon: Odometer },
  { label: '藏品管理', path: 'collection', icon: PictureRounded },

  { label: '推荐管理', path: 'recommend', icon: Star },

  { label: '轮播管理', path: 'banner', icon: Star },
  { label: '新闻管理', path: 'news', icon: ChatDotSquare },
  { label: '文章推荐管理', path: 'article', icon: MessageBox },
  { label: '版画知识管理', path: 'knowledge', icon: Reading },

  { label: '展览推荐管理', path: 'exhibition', icon: DataLine },
  { label: '专家顾问管理', path: 'expertConsultant', icon: DataLine },

  { label: '版画艺术家管理', path: 'artist', icon: IconMenu },
  { label: '联系我们', path: 'contactUs', icon: Service },
  { label: '关于我们管理', path: 'about', icon: Share },
  { label: '用户管理', path: 'user', icon: User }

  // { label: '防伪信息管理', path: 'antiCounterfeiting', icon: Stamp }
]

//  router.options.routes.find(
//   (item) => item.name === 'admin'
// ).children
const activeMenu = ref('')

// 选中侧边
menuList.filter((item) => {
  if (route.path.indexOf(item.path) !== -1) {
    activeMenu.value = item.path

    return true
  }
  return false
})

const UserId = cookie.get('UserId')
if (UserId) {
  getUsers(UserId).then((res) => {
    console.log(res, 1234)
    if (res.data.code === 200) {
      userInfo.value = res.data.data.UserName
    } else {
      router.push({ path: '/admin/login' })
      ElMessage({
        message: '登录超时请重新登录！',
        type: 'warning'
      })
    }
  })
} else {
  router.push({ path: '/admin/login' })

  ElMessage({
    message: '未登录请登录！',
    type: 'warning'
  })
}

const clickMenu = (item) => {
  console.log('route', activeMenu)
  router.push({ path: '/admin/' + item.path })
}
const command = (index) => {
  if (index === 'logout') {
    ElMessage({
      message: '登出成功！',
      type: 'success'
    })
    router.push({
      path: '/admin/login'
    })
  }
}
</script>

<style lang="scss">
.admin-layout {
  width: 100vw;
  height: 100vh;
  // background: pink;
  .admin-layout-container {
    height: 100%;
    width: 100%;
  }
  .admin-layout-header {
    background: #3d444b;
    .user-info {
    }
  }
  .login {
    .title {
      color: white;
      font-size: 20px;
    }
  }

  .el-input__wrapper,
  .el-textarea__inner {
    font-size: 16px;
    background: #fff;
  }

  .el-select-dropdown__wrap {
    background: #fff;

    font-size: 16px;
  }

  .input-w500 {
    width: 500px;
  }
}
</style>
