<template>
  <div class="web-index-content">
    <el-container class="web-container">
      <el-header class="header-content">
        <!-- <el-affix :offset="0"></el-affix> -->
        <div class="header-box">
          <div class="logo-box" @click="toHome">
            <el-image :src="Logo" class="logo" />
            <!-- <el-image :src="textChun" class="logo-text" />
              <el-image :src="textQiu" class="logo-text" />
              <el-image :src="textBan" class="logo-text" />
              <el-image :src="textHua" class="logo-text" /> -->

            <!-- <div class="text-bwg">博物馆</div> -->
          </div>
          <div class="right-box">
            <navigation-bar
              class="navigation-menu"
              :data="navigationData"
              @itemClick="menuSelect"
              :default-active="activeMenu"
            ></navigation-bar>

            <div class="serch-btn-box">
              <el-button
                color="#25567A"
                plain
                :icon="Search"
                round
                :size="'large'"
                @click="toSearch"
                >搜索</el-button
              >
            </div>
          </div>
        </div>

        <!-- <div
          class="sub-header-box"
          :style="{ height: submenu[activeMenu] ? '60px' : 0 }"
        >
          <el-space :spacer="spacer">
            <div v-for="(item, index) in submenu[activeMenu]" :key="index">
              <el-button size="large" color="#FD7623" round plain>{{
                item.label
              }}</el-button>
            </div>
          </el-space>
        </div> -->
      </el-header>
      <el-main class="main-body reveal-top">
        <!-- <router-view v-slot="{ Component }">
          <transition name="fade">
            <component :is="Component" />
          </transition>
        </router-view> -->
        <div class="router-content">
          <router-view> </router-view>
          <!-- <router-view v-slot="{ Component }">
            <keep-alive>
              <component :is="Component" />
            </keep-alive>
          </router-view> -->
        </div>
        <div class="footer-content">
          <footer-page></footer-page>
        </div>
      </el-main>
      <!-- <el-footer class="footer-box"> </el-footer> -->
    </el-container>
  </div>
</template>

<script setup>
import navigationBar from '../../components/navigation-bar'
import footerPage from './common/footer-component'
import { watchEffect, onMounted, ref } from 'vue'
// import { ElDivider } from 'element-plus'
import { Search } from '@element-plus/icons-vue'
import Logo from '../../assets/img/logo-text.png'
// import textChun from '../../assets/img/text-chun.png'
// import textQiu from '../../assets/img/text-qiu.png'
// import textBan from '../../assets/img/text-ban.png'
// import textHua from '../../assets/img/text-hua.png'
import scrollReveal from '../../utils/scrollReveal'

import { useRouter, useRoute } from 'vue-router'
// const spacer = h(ElDivider, { direction: 'vertical' })
const router = useRouter()
const route = useRoute()

const navigationData = [
  { label: '首页', value: 'home' },
  { label: '新闻公告', value: 'news' },
  { label: '馆藏精品', value: 'collection' },
  { label: '展览推荐', value: 'exhibition' },
  { label: '版画艺术家', value: 'artist' },
  { label: '版画知识', value: 'knowledge' },

  // { label: '专家顾问', value: 'expertConsultant' },
  { label: '本馆概况', value: 'about' },
  { label: '联系我们', value: 'contactUs' }
]
const activeMenu = ref('home')

navigationData.map((item) => {
  // console.log(1, route.path, item.value)

  if (route.path.includes(item.value)) {
    activeMenu.value = item.value
  }
  return item
})

onMounted(() => {
  // 初始化 加载动画
  scrollReveal.init()
})

// const submenu = {
//   knowledge: [{ label: '版种介绍' }, { label: '版画知识' }]
// }
const toHome = () => {
  router.push({
    name: 'home'
  })
}
const toSearch = () => {
  activeMenu.value = ''
  router.push({
    name: 'search'
  })
}
const menuSelect = (item) => {
  activeMenu.value = item
  // console.log(item, 1)

  // if (!submenu[item]) {
  // 没有子菜单
  router.push({
    name: item
  })
  // }
}
watchEffect(() => {})
</script>

<style  lang="scss">
.web-index-content {
  width: 100%;
  // background: rgba($color: #000000, $alpha: 1.0);
  // background-size: 100% 100%;
  // .web-container {
  //   display: flex;
  //   flex-wrap: wrap;
  // }
  // height: 100vh;
  .header-content {
    min-height: 100px;
    height: auto;
    padding: 0;

    // background: rgba($color: #fff, $alpha: 1);
    background-image: url(../../assets/img/header-bj.png);
    // background-repeat: no-repeat;
    background-size: auto 200%;
    // background-position: 0px 0px;

    .header-box {
      justify-content: space-around;

      width: 100%;
      overflow: hidden;
      background: rgba($color: #fff, $alpha: 0.7);
      box-shadow: 2px 2px 20px #0000003f;
      z-index: 1;
      /* height: auto; */
      display: flex;
      flex-wrap: wrap;

      .logo-box {
        // line-height: 60px;
        display: flex;
        align-items: center;

        cursor: pointer;
        margin: 10px;
        .logo {
          // min-width: 300px;
          // height: 100px;
          height: 80px;
          width: 360px;
        }
        .logo-text {
          margin: 0 -5px;
          width: 60px;

          height: 60px;
        }
        .text-bwg {
          width: 4em;
          // display: inline-block;
          font-family: "楷体";
          font-size: 38px;
          // font-weight: bold;
        }
      }

      .right-box {
        display: flex;
        justify-content: space-between;

        .navigation-menu {
          margin: 0 auto;
          overflow: auto;
          flex-wrap: wrap;
          /* min-width: 900px; */

          //   background-color: rgba($color: #000000, $alpha: 0);
          //   .is-active {
          //     // color: orange;
          //     // border-bottom: 2px solid orange;
          //   }
          .item {
            min-width: 6em;
            //     font-size: 20px;
            //     // color: white;
            //     font-weight: bold;
            //     font-family: 'Courier New', Courier, monospace;
            //     width: 120px;
            //     // &:hover {
            //     //   background: white;
            //     // }
          }
        }
      }
    }

    .sub-header-box {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 0px;
      overflow: hidden;
      // background-color: pink;
      transition: height 0.5s;
      background: rgba($color: #030204, $alpha: 0.3);
    }
  }

  .main-body {
    background: url(../../assets/img/body-bj.png) no-repeat;
    background-size: 100%;
    background-position: center bottom;
    background-color: #f4f1ed;
    padding: 0;
    // flex: 1;
    min-height: calc(100vh - 100px);
    // background-color: #000000;
    display: flex;
    flex-direction: column;
    .router-content {
      flex: 1;
    }
    .footer-content {
    }
  }
  .serch-btn-box {
    display: flex;
    align-items: center;
    margin: 0 20px;
  }
  .footer-box {
    padding: 0;
  }
}
</style>
