<template>
  <div class="expert-consultant-content">
    <!-- <page-header title="专家顾问" :backgroundImage="headerBackgroundImage">

    </page-header> -->
    <div class="page-body-">
      <div class="card-box">
        <div
          class="consultant-item"
          v-for="(item, index) in dataList"
          :key="index"
          @click="itemClick(item)"
        >
          <el-card :body-style="{ padding: '0px' }">
            <el-image
              lazy
              fit="cover"
              class="consultant-item-image"
              :src="BASE_HOST + 'v1/images/' + item.SmallPic"
            ></el-image>
            <!--
          <img
            src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png"
            class="image"
          /> -->
            <div style="padding: 14px">
              <div class="zj-name">{{ item.ArticleTitle }}</div>
              <div class="zj-ry">{{ item.Honor }}</div>
              <div class="zj-jj">{{ item.ArticleContent }}</div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { BASE_HOST } from '../../../utils/axios'

// import pageHeader from '../../../components/page-header'
// import headerBackgroundImage from '../../../assets/title-background-image/yxyz.png'
import { getExpert } from '../../../apis'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
const dataList = ref([])
const router = useRouter()
const itemClick = (item) => {
  router.push({
    name: 'expertConsultantDetail',
    query: {
      id: item.Id
    }
  })
}
const getData = () => {
  getExpert({ size: 10 }).then((res) => {
    dataList.value = res.data.data.filter((item) => item.ArtOrder)
    console.log(res, 133)
  })
}
getData()
</script>

<style lang="scss">
.expert-consultant-content {
  .card-box {
    // flex-wrap: wrap;
    // justify-content: space-evenly;
  }
  .consultant-item {
    max-width: 360px;
    flex: 1;
    display: inline-block;
    cursor: pointer;
    padding: 15px;
    .zj-name {
      font-size: 24px;
      line-height: 32px;
      color: #fd7623;
    }
    .zj-ry {
      color: #25567a;
      font-size: 16px;
      margin: 10px auto;
    }
    .zj-jj {
      // margin: 8px auto;
      font-size: 14px;
      // line-height: 18px;
      // height: 100px;

      text-indent: 2em;
      // overflow: auto;

      overflow: hidden; //超出的文本隐藏

      text-overflow: ellipsis; //溢出用省略号显示

      display: -webkit-box;

      -webkit-line-clamp: 6; // 超出多少行

      -webkit-box-orient: vertical;
    }
    .consultant-item-image {
      width: 100%;
      height: 250px;
    }
  }
}
</style>
