<template>
  <div class="news-content">
    <div class="page-body">
      <div class="news-box">
        <div class="title">
          {{ pageData.ArticleTitle }}
        </div>
        <div class="subtitle">
          <div>
            <span> 发布时间： </span>
            <span>
              {{
                moment(pageData.UpdateTime).format('YYYY年MM月DD日  hh:mm:ss')
              }}
            </span>
          </div>

          <div>
            <span>发布者：</span>
            <span>
              {{ pageData.AddUser }}
            </span>
          </div>
          <div>
            <span>文章来源：</span>
            <span>
              {{ pageData.PubFrom }}
            </span>
          </div>
          <div>
            <span>作者：</span>
            <span>{{ pageData.Honor }}</span>
          </div>
        </div>
        <div class="news-text-body" v-html="pageData.ArticleContent"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import moment from 'moment'
import replaceImgPath from '../../../utils/replace-img-path'
import { getNewsDetail } from '../../../apis'

import {
  useRoute
  // useRouter
} from 'vue-router'
import { ref } from 'vue'
// const newsType = {
//   48: '版画界新闻',
//   49: '艺术界新闻',
//   50: '站内新闻',
//   52: '新闻资讯'
// }
const route = useRoute()
const pageData = ref({})

const getData = () => {
  getNewsDetail(route.query.id).then((res) => {
    pageData.value = res.data.data
    // console.log(pageData.value.ArticleContent)

    pageData.value.ArticleContent = replaceImgPath(
      pageData.value.ArticleContent
    )
  })
}
getData()
</script>
<style lang="scss">
.news-content {
  .title {
    font-size: 26px;
    text-align: center;
    margin: 30px auto 10px;
  }
  .subtitle {
    display: flex;
    justify-content: center;
    margin: 20px auto;
    color: rgb(102, 102, 102);
    div {
      margin: 0 20px;
      // background: #fff;
      // padding: 4px;
      font-size: 14px;
    }
  }
  .news-box {
    padding: 20px 5%;
    background: rgba($color: #fff, $alpha: 0.4);
  }
  .news-text-body {
    // word-break: break-all;
    // word-wrap: break-word;

    word-wrap: break-word;
    word-break: break-all;
  }
  .page-body {
  }
}
</style>
