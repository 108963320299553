<template>
  <div class="home-content">
    <el-carousel class="reveal-top" height="460px" v-if="bannerList.length">
      <el-carousel-item
        class="banner-item"
        @click="bannerClick(item)"
        v-for="(item, index) in bannerList"
        :key="index"
      >
        <el-image
          style="width: 100%"
          fit="contine"
          :src="BASE_HOST + 'v1/images/' + item.FPic"
        ></el-image>
        <h3 class="banner-label">{{ item.FTitle }}</h3>
      </el-carousel-item>
    </el-carousel>

    <div class="text-box reveal-top">
      <div class="news-box">
        <div class="header">
          <div class="title">新闻公告</div>
          <div class="more-button" @click="moreNews">更多</div>
        </div>
        <div
          class="text-item"
          v-for="item in newsList"
          :key="item.id"
          @click="newsItemClick(item)"
        >
          <div class="text-title">{{ item.ArticleTitle }}</div>
          <div class="text-time">
            发布时间
            {{ moment(item.UpdateTime).format('YYYY-MM-DD  hh:mm:ss') }}
          </div>
        </div>
      </div>
      <div class="article-box">
        <div class="header">
          <div class="title">文章推荐</div>
          <div class="more-button" @click="moreArticle">更多</div>
        </div>
        <div
          class="text-item"
          v-for="item in articleList"
          :key="item.id"
          @click="articleItemClick(item)"
        >
          <div class="text-title">{{ item.ArticleTitle }}</div>
          <div class="text-time">
            发布时间
            {{ moment(item.UpdateTime).format('YYYY-MM-DD  hh:mm:ss') }}
          </div>
        </div>
      </div>
    </div>

    <div class="page-body">
      <div class="recommend-box reveal-top">
        <div class="title">藏品推荐·名家推荐</div>

        <el-carousel
          :interval="2000"
          type="card"
          height="460px"
          v-if="recommendList.length"
        >
          <el-carousel-item
            v-for="item in recommendList"
            :key="item"
            @click="recommendClick(item)"
          >
            <el-image
              style="width: 100%; height: 100%"
              fit=""
              :src="BASE_HOST + 'v1/images/' + item.BigPic"
            ></el-image>

            <div class="recommend-dec">
              {{ item.Honor + '-' + item.ArticleTitle }}
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="type-box">
        <div class="title reveal-top">馆藏精品</div>
        <div class="type-item-box reveal-top">
          <div
            @click="toPrintList({ item, type: 1 })"
            class="type-item"
            v-for="item in typeList"
            :key="item.Id"
          >
            <div class="type-img">
              <el-image :src="getTypeThumbnail(item.Id)" fit="cover"></el-image>

              <div class="thumbnail-name">
                {{ item.thumbnailName }}
              </div>
            </div>
            <div class="type-name">
              {{ item.CatName }}
              <!-- {{ item }} -->
            </div>
            <div class="type-num">
              <span>
                ({{ item.Count }})
                <!-- {{ item }} -->
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <el-backtop :bottom="100">
    <div
      style="
        height: 100%;
        width: 100%;
        background-color: #030204;
        border-radius: 10%;
        text-align: center;
        line-height: 46px;
        color: #f3d737;
      "
    >
      <el-icon color="#fff" size="40"><arrow-up-bold /></el-icon>
    </div>
  </el-backtop>
</template>

<script setup>
import moment from 'moment'
import { BASE_HOST } from '../../../utils/axios'
import { useRouter } from 'vue-router'

import { watchEffect, onMounted, ref } from 'vue'
import {
  getPlayer,
  getProductCategory,
  getNewsPage,
  getArticleList,
  getRecommendList
} from '../../../apis'
// import hbmk from '../../../assets/category-thumbnail/lyg.jpg'
// import { ElNotification } from 'element-plus'
import { ArrowUpBold } from '@element-plus/icons-vue'

import scrollReveal from '../../../utils/scrollReveal'
import {
  // WorkmanshipCategory,
  getTypeThumbnail
} from '../../../data/category-type'
const router = useRouter()

const bannerList = ref([])
const recommendClick = (item) => {
  const { ArticleSort } = item
  console.log(ArticleSort)
  // 【ArticleSort:55 文章推荐】, 【ArticleSort:56 藏品推荐，名家推荐】
  if (ArticleSort === 55) {
    router.push({
      name: 'newsDetail',
      query: {
        id: item.Id
      }
    })
  }
  if (ArticleSort === 56) {
    const collectionDetail = router.resolve({
      name: 'collectionDetail',
      query: { id: item.ArticleContent }
    })

    window.open(collectionDetail.href, '_blank')
  }
}
const bannerClick = (item) => {
  router.push({
    name: 'newsDetail',
    query: {
      id: item.FUrl
    }
  })
  console.log(item)
}
const newsList = ref([])

const articleList = ref([])

const moreArticle = () => {
  router.push({
    name: 'article'
  })
}

const moreNews = () => {
  router.push({
    name: 'news'
  })
}

const articleItemClick = (item) => {
  router.push({
    name: 'articleDetail',
    query: {
      id: item.Id
    }
  })
}
const newsItemClick = (item) => {
  router.push({
    name: 'newsDetail',
    query: {
      id: item.Id
    }
  })
}
const toPrintList = (params) => {
  // type 1形式 2时期

  router.push({
    name: 'collectionList',
    query: { type: params.type, category: params.item.Id }
  })
}

const recommendList = ref([])
const typeList = ref([])

const getData = () => {
  // banner
  getPlayer({ query: 'Fstate:1' }).then((res) => {
    bannerList.value = res.data.data.filter((v) => v.FState)
    // console.log(res, 123123)
  })
  // 获取新闻
  getNewsPage({ size: 5 }).then((res) => {
    // console.log(res, '新闻')
    newsList.value = res.data.data
  })
  // 获取文章

  getArticleList({ size: 5 }).then((res) => {
    console.log(res, '文章')
    articleList.value = res.data.data
  })
  // 获取推荐
  getRecommendList().then((res) => {
    console.log(res, '新闻')
    recommendList.value = res.data.data.filter((item) => item.HomepageShow)
  })

  // 获取种类
  getProductCategory().then((res) => {
    res.data.data.map((item) => {
      if (item.CatName === '形式') {
        const arr = item.Subclass
        typeList.value = arr
      }
      // if (item.CatName === '时期') {
      //   const arr = item.Subclass
      //   typeList.value = arr
      // }
      return item
    })
  })
}
getData()

onMounted(() => {
  // ElNotification({
  //   title: '重要通知',
  //   message: '当前网站为测试版本，如发现任何异问题请见谅。',
  //   type: 'warning',
  //   duration: 20000,
  //   offset: 120
  //   // position: 'bottom'
  // })

  scrollReveal.init()
})

watchEffect(() => {})
</script>

<style lang="scss">
.home-content {
  // background: rebeccapurple;
  // height: 100vh;
  // el-carousel__arrow--left
  .el-carousel__arrow {
    width: 50px;
    height: 50px;
    // background: red !important;
    .el-icon {
      width: 2em;
      height: 2em;
      line-height: 2em;
      svg {
        width: 2em;
        height: 2em;
      }
    }
  }

  .banner-item {
    cursor: pointer;
    .el-image {
      height: 100%;
    }
    // position: relative;
    .banner-label {
      text-align: center;
      color: #f4f1ed;
      position: absolute;
      bottom: 0;
      background: rgba($color: #000000, $alpha: 0.5);
      width: 100%;
      height: 70px;
      margin: 0;
      padding: 0 20px;
      line-height: 60px;
    }
  }

  .title {
    text-align: center;

    // padding: 6px 40px;
    margin: 10px;
    font-size: 32px;

    position: relative;
    // &:after {
    //   width: 40px;
    //   height: 80%;

    //   content: '';
    //   transform: rotate(180deg);
    //   position: absolute;
    //   right: 0;
    //   // background: #3388b1;
    //   background-image: url(../../../assets/img/title-zsw.png);
    //   background-repeat: no-repeat;
    //   background-size: 100% 100%;
    // }
    // &:before {
    //   width: 40px;
    //   height: 80%;

    //   content: '';
    //   position: absolute;
    //   left: 0;
    //   // background: #3388b1;
    //   background-image: url(../../../assets/img/title-zsw.png);
    //   background-repeat: no-repeat;
    //   background-size: 100% 100%;
    // }
  }
  .text-box {
    display: flex;
    justify-content: space-between;
    background: rgba($color: #f7c703, $alpha: 0.1);
    padding: 20px 10%;
    > div {
      flex: 1;
      width: 50%;
      padding: 10px;
      margin: 6px;
      // color: white;
      transition: 0.5s;

      border-radius: 4px;
      min-height: 100px;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #25567a;
        // font-size: 28px;
      }

      .text-item {
        // display: flex;
        // justify-content: space-between;
        align-items: center;
        // line-height: 48px;
        // background: rebeccapurple;
        padding: 8px 12px;
        cursor: pointer;

        .text-time {
          // width: 12em;
          // text-align: center;
          font-size: 14px;
          // color: rgb(70, 70, 70);
        }
        .text-title {
          flex: 1;
          font-size: 22px;
          margin: 6px auto;
          overflow: hidden;

          white-space: nowrap;

          text-overflow: ellipsis;
        }

        &:hover {
          color: #fff;
          background: rgba($color: #000, $alpha: 0.6);
        }
      }
    }

    .more-button {
      border-radius: 4px;
      cursor: pointer;
      // height: 10px;
      // line-height: 20px;
      padding: 8px 16px;
      text-align: center;
      border: 2px solid #1997fa;
      transition: 0.3s;

      font-size: 16px;
      color: #1997fa;
      &:hover {
        color: white;
        background: #1997fa;
      }
    }
    .news-box {
      // color: white;
      .header {
        padding: 10px 0;
        border-bottom: 2px solid #25567a;
      }
      .title {
      }
      .more-button {
      }
      background: rgba($color: #fff, $alpha: 1);
      // background-image: url(../../../assets/img/bjdw.png);
      // background-repeat: no-repeat;
      // background-size: 120%;
      // background-position: top right;
    }
    .article-box {
      // color: white;
      .header {
        padding: 10px 0;
        border-bottom: 2px solid #25567a;
      }
      .title {
      }
      .more-button {
      }
      // color: #409071;

      background: rgba($color: #fff, $alpha: 1);
      // background-image: url(../../../assets/img/bjdw.png);
      // background-repeat: no-repeat;
      // background-size: 120%;
      // background-position: top right;
    }
  }

  .recommend-box {
    margin: 20px auto;
    padding: 10px;
    border-radius: 4px;
    position: relative;
    // background: rgba($color: #25567a, $alpha: 0.2);
    .title {
      margin: 10px auto;
      width: 12em;
      // display: inline-block;
    }
    .recommend-dec {
      position: absolute;
      bottom: 0;
      line-height: 20px;
      text-align: center;
      width: 100%;
      padding: 10px;
      color: #f2c600;
      background: rgba($color: #000, $alpha: 0.5);
    }
  }
  .type-box {
    .title {
      margin: 10px auto;
      width: 12em;

      // display: inline-block;
    }
    .type-item-box {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      .type-item {
        position: relative;
        transition: 0.5s;
        cursor: pointer;
        color: #fff;
        // font-weight: 500;
        border-radius: 4px;
        background: rgba($color: #2f475f, $alpha: 0.8);
        background-image: url(../../../assets/img/type-bj.png);
        background-repeat: no-repeat;
        background-position: right center;
        background-size: auto 180%;
        height: 200px;
        width: 32%;
        margin: 16px 0;
        text-shadow: 2px 2px 1px #000;

        // display: flex;
        // justify-content: flex-end;
        // flex-wrap: wrap;
        overflow: hidden;

        &:hover {
          background-color: rgba($color: #2f475f, $alpha: 1);
          background-size: auto 200%;

          .type-name {
            font-size: 38px;
            color: #1997fa;
          }
        }
      }
      .type-name {
        position: absolute;
        right: 20px;
        bottom: 50px;
        transition: 0.5s;
        font-size: 26px;

        // line-height: 200px;
        // margin: 20px;
        // border-bottom: 2px solid white;
        z-index: 1;
      }
      .type-num {
        transition: 0.5s;

        position: absolute;
        right: 20px;
        bottom: 20px;
        color: #f2c600;
        font-size: 24px;
      }
      .type-img {
        // position: relative;
        position: absolute;
        left: 0;
        .el-image {
          height: 100%;
          width: 100%;
        }
        opacity: 1;
        width: 100%;
        height: 100%;
        .thumbnail-name {
          position: absolute;
          font-size: 16px;
          bottom: 0px;
          line-height: 40px;
          padding: 0 10px;
          color: #526577;
          // text-shadow: 2px 2px 0px #fff;
          text-shadow: -1px -1px 0 #ffffff, 1px -1px 0 #ffffff,
            -1px 1px 0 #ffffff, 1px 1px 0 #ffffff;

          // width: 60%;
          text-align: center;
        }
      }
    }
  }
}
</style>
