<!-- 自定义 动画导航 -->
<template>
  <ul class="custom-navbar">
    <li
      :class="activeItem === item.value ? 'active' : ''"
      v-for="item in props.navData"
      :key="item.value"
      @click="itemClick(item)"
    >
      <a href="#">{{ item.label }}</a>
    </li>
  </ul>
</template>
<script setup>
import { ref, defineProps, defineEmits } from 'vue'
// const activeItem = ref('')

const props = defineProps({
  active: String,
  navData: Object
})
const activeItem = ref(props.active)

const emits = defineEmits(['itemClick'])

const itemClick = (item) => {
  emits('itemClick', item)
  activeItem.value = item.value
}
</script>
<style lang="scss" scoped>
.custom-navbar {
  position: relative;
  // top: 50%;
  // left: 50%;
  transform: translate(0%, 0%);
  margin: 0px;
  padding: 0;
  display: flex;
  li {
    margin: 0 4px;

    list-style: none;
  }
  li a {
    // border-bottom: 1px solid white;
    font-size: 20px;
    position: relative;
    display: block;
    padding: 0px 20px;

    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    // font-weight: bold;
    transition: 0.5s;
  }
  li a:hover {
    color: #fd7623;
  }
  li a:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    transform: scaleY(2);
    opacity: 0;
    transition: 0.5s;
    z-index: -1;
  }
  li a:hover:before {
    transform: scaleY(1.2);
    opacity: 1;
  }
  li a:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    transform: scale(0);
    transition: 0.5s;
    z-index: -1;
  }
  li a:hover:after {
    transform: scale(1);
  }

  .active {
    a {
      color: #fd7623;
    }
    a:before {
      transform: scaleY(1.2);
      opacity: 1;
    }
    a:after {
      transform: scale(1);
    }
  }
}
</style>
