<template>
  <div class="knowledge-content">
    <page-header
      class="knowledge-header"
      title="版画知识"
      :backgroundImage="headerBackgroundImage"
      backgroundImagePosition="center 0%"
    >
      <template v-slot:body>
        <!-- <custom-navbar
          :active="queryData.ArticleSort"
          :navData="navbarList"
          @itemClick="itemClick"
        ></custom-navbar> -->
      </template>
      <template #right>
        <el-input
          size="large"
          v-model="queryData.search"
          class="search-input"
          clearable
          placeholder="请输入搜索内容"
          :suffix-icon="Search"
        />
        <el-button type="primary" class="search-button" @click="searchFun"
          >搜索</el-button
        ></template
      >
    </page-header>

    <div class="page-body knowledge-body">
      <el-tabs
        v-model="queryData.ArticleSort"
        class="tabs-knowledge"
        @tab-click="itemClick"
      >
        <el-tab-pane
          v-for="item in navbarList"
          :key="item.value"
          :label="item.label"
          :name="item.value"
        >
        </el-tab-pane>
      </el-tabs>

      <div
        class="knowledge-item"
        v-for="item in knowledgeData.data"
        :key="item.Id"
        @click="knowledgeItemClick(item)"
      >
        <div class="knowledge-img">
          <el-image
            fit="contain"
            :src="BASE_HOST + 'v1/images/' + item.BigPic"
            class="image"
          >
            <template #error>
              <el-image
                class="no-knowledge-image"
                fit="scale-down"
                :src="noNews"
              />
            </template>
          </el-image>
        </div>

        <div class="knowledge-text">
          <div class="knowledge-title">
            <span class="tags" style="color: #0096e2">{{
              // knowledgeType[item.ArticleSort]

              type[item.ArticleSort]
            }}</span>
            {{ item.ArticleTitle }}
          </div>
          <div class="knowledge-dec">
            {{ item.Honor }}
          </div>
          <div class="knowledge-time">
            发布时间：
            {{ moment(item.Pubtime).format('YYYY年MM月DD日  hh:mm:ss') }}
          </div>
        </div>
      </div>
      <!-- {{ knowledgeData.total }} -->
      <CustomPagination
        background
        :total="knowledgeData.total"
        :current-page="queryData.page"
        @current-change="currentChange"
      />
    </div>
  </div>
</template>

<script setup>
import pageHeader from '../../../components/page-header'
import headerBackgroundImage from '../../../assets/title-background-image/jr.jpg'
import { getKnowledge } from '../../../apis'
// import CustomNavbar from '../../../components/custom-navbar.vue'
import CustomPagination from '../../../components/custom-pagination.vue'
import { reactive, ref } from 'vue'
import moment from 'moment'

import { Search } from '@element-plus/icons-vue'
import { BASE_HOST } from '../../../utils/axios'
import noNews from '../../../assets/img/no-img.png'
import { useRouter } from 'vue-router'
const router = useRouter()
const type = {
  102: '版种介绍',
  103: '版画知识'
}
const queryData = reactive({
  page: 1,
  ArticleSort: 103,
  keys: ''
})

const navbarList = [
  // { label: '全部信息', value: 'all' },
  { label: '版画知识', value: 103 },
  { label: '版种介绍', value: 102 }
]
const searchFun = () => {
  console.log(queryData, 77)
  queryData.keys = queryData.search
  getData()
}
const knowledgeData = ref({})
const currentChange = (e) => {
  queryData.page = e
  getData()
}
const getData = () => {
  let query = ''

  if (queryData.ArticleSort !== 'all') {
    query = 'ArticleSort:' + queryData.ArticleSort
  }
  getKnowledge({
    ...queryData,
    query
  }).then((res) => {
    knowledgeData.value = res.data
    // console.log(knowledgeData, 1233)
  })
}
getData()
const itemClick = (val) => {
  // console.log(123, val.props.name)
  queryData.page = 1

  queryData.ArticleSort = val.props.name
  getData()
}
const knowledgeItemClick = (item) => {
  router.push({
    name: 'knowledgeDetail',
    query: {
      id: item.Id
    }
  })
}
</script>
<style lang="scss">
.knowledge-content {
  .tabs-knowledge {
    background: rgba($color: #000000, $alpha: 0.1);
    padding: 4px 10px;
    border-radius: 8px;
    .el-tabs__item {
      color: white;
      font-size: 18px;
    }
    .is-active {
      color: #1997fa;
      // font-size: 20px;
    }
    // background: red;
  }
  .search-box {
    display: flex;
    .search-button {
      width: 120px;
      border-radius: 0 10px 10px 0;
      font-size: 18px;
      height: 50px;
      padding: 0 20px;
    }
    .search-input {
      width: 200px;

      &:nth-child(2) {
        width: 300px;
        .el-input__inner {
          border-radius: 0px;
        }
      }

      .el-input__inner {
        border-radius: 10px 0px 0px 10px;
        font-size: 18px;
        height: 50px;
        padding: 0 20px;
      }
      .el-input__icon {
        font-size: 20px;
      }
    }
  }

  .knowledge-item {
    cursor: pointer;
    display: flex;
    height: 200px;
    width: 100%;
    margin: 20px;
    // background: pink;

    .knowledge-img {
      width: 30%;
      height: 100%;
      margin-right: 20px;

      // background: #ccc;
      .image {
        height: 100%;
        width: 100%;
      }
      .no-knowledge-image {
        height: 100%;
        width: 100%;
        // padding: 20px;
        background: #f7f7f7;
      }
    }
    .knowledge-text {
      flex: 1;

      .knowledge-title {
        padding: 10px;
        /* line-height: 60px; */
        font-size: 28px;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        .tags {
          padding-right: 10px;
          position: relative;

          &::after {
            position: absolute;
            right: 0;
            top: 20%;

            width: 3px;
            height: 70%;
            background-color: #000000;
            content: '';
          }
        }
      }
      .knowledge-dec {
        color: rgb(80, 80, 80);
        font-size: 16px;
        padding: 4px;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .knowledge-time {
        padding: 10px;
      }
    }

    &:hover {
      background: rgba($color: #000000, $alpha: 0.1);
      .knowledge-title {
        color: #fe9900;
      }
    }
  }
}
</style>
