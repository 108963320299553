<template>
  <el-empty class="page-404-box" :image-size="300">
    <template #image>
      <el-image :src="Icon404"></el-image>
    </template>

    <template #description> 暂未找到页面，请联系管理员或返回首页。 </template>
    <el-button type="primary" @click="toHome">返回首页</el-button>
  </el-empty>
</template>

<script setup>
import Icon404 from '../../assets/img/404.png'
import { useRouter } from 'vue-router'
const router = useRouter()
const toHome = () => {
  router.push('/')
}
</script>
<style lang="scss">
.page-404-box {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
</style>
