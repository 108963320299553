<!-- web 页面分页 -->
<template>
  <div class="pagination-box">
    <el-pagination
      :page-size="props.pageSize"
      :background="props.background"
      :disabled="props.disabled"
      :layout="props.layout || 'prev, pager, next'"
      :total="props.total || 1"
      v-model:currentPage="currentPage"
      @size-change="sizeShange"
      @current-change="currentChange"
    />
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits, watchEffect } from 'vue'
const props = defineProps({
  disabled: Boolean,
  background: Boolean,
  layout: String,
  pageSize: Number,
  total: Number,
  currentPage: Number
})
const emit = defineEmits(['sizeShange', 'currentChange'])
const currentPage = ref(0)
watchEffect(() => {
  currentPage.value = props.currentPage
})

const sizeShange = (e) => {
  emit('sizeShange', e)
}

const currentChange = (e) => {
  emit('currentChange', e)
}
watchEffect(() => {})
</script>

<style scoped lang="scss">
.pagination-box {
  display: flex;
  justify-content: center;
  margin: 20px auto;
}
</style>
