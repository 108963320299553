<template>
  <div class="admin-login-box">
    <h1 class="title">春秋版画博物馆后台管理系统</h1>
    <div class="admin-login-content">
      <h2 class="subtitle">登录</h2>

      <div class="admin-login-form">
        <el-space
          direction="vertical"
          :fill="true"
          :size="20"
          style="width: 100%"
        >
          <el-input
            size="large"
            v-model="account"
            class="w-50 m-2"
            placeholder="请输入账号"
            :suffix-icon="User"
          />
          <el-input
            size="large"
            v-model="password"
            type="password"
            placeholder="请输入密码"
            show-password
          />
          <el-button type="primary" size="large" @click="toLogin"
            >登录</el-button
          >
        </el-space>
      </div>
      <div class="admin-login-copyright">Copyright ® 2009 春秋版画博物馆</div>
    </div>
  </div>
</template>

<script setup>
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { User } from '@element-plus/icons-vue'
import { ref } from 'vue'
import { Login } from '../../../apis'
import Cookie from 'cookiejs'
const account = ref('')
const password = ref('')
const router = useRouter()
const toLogin = () => {
  // 登录方法
  if (!account.value) {
    ElMessage({
      message: '请检查用户名是否输入',
      type: 'error'
    })
  } else if (!password.value) {
    ElMessage({
      message: '请检查密码是否输入',
      type: 'error'
    })
  } else {
    Login({
      username: account.value,
      password: password.value
    }).then((res) => {
      console.log(res, 123)

      if (res.data.code === 201) {
        ElMessage({
          message: '登录成功！',
          type: 'success'
        })

        Cookie.set('UserName', res.data.data.UserName, { expires: 1, path: '' })
        Cookie.set('UserId', res.data.data.Id, { expires: 1, path: '' })
        Cookie.set('Token', res.data.data.Token, { expires: 1, path: '' })

        router.push({
          path: '/admin'
        })
      } else {
        ElMessage({
          message: res.data.data,
          type: 'error'
        })
      }
    })
  }
}
</script>
<style scoped lang="scss">
.admin-login-box {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  // align-items: center;
  background: rgba(61, 63, 70, 1);
  background-image: url(/src/assets/img/login-bj.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .title {
    color: white;
    text-shadow: 10px 6px 4px #e6a23c;
    padding: 20px 50px;
    font-size: 40px;

    // text-align: center;
  }
  .admin-login-content {
    display: flex;
    align-items: center;
    align-content: space-between;
    // align-content: center;

    flex-wrap: wrap;
    margin: 4vh 6vw;

    background: rgba($color: #fff, $alpha: 0.8);
    width: 460px;
    padding: 20px;
    height: calc(100% - 8vh);
    border-radius: 10px;

    .subtitle {
      color: #409eff;

      // text-align: center;
    }
    .admin-login-form {
      width: 100%;
      // background: palegreen;
      min-height: 500px;
    }
    .admin-login-copyright {
      width: 100%;
      text-align: center;
      font-size: 14px;
    }
  }
}
</style>
