// web 页面请求

import http from '../utils/axios'
// 获取版画种类
export const getPlayer = (query) => {
  return http('/player/page', {
    params: {
      size: 20,
      ...query
    }
  })
}
// 获取新闻
export const getNews = () => {
  return http('/news/')
}
// 新闻详情

export const getNewsDetail = (id) => {
  return http('/news/' + id)
}

// 文章详情

export const getArticlesDetail = (id) => {
  return http('/articles/' + id)
}

// 获取新闻列表
export const getNewsPage = (query) => {
  return http('/news/page', {
    params: {
      size: 10,
      ...query
    }
  })
}

// 获取文章推荐
export const getArticleList = (query) => {
  return http('/referral/page', {
    params: {
      size: 10,
      query: 'ArticleSort:55',
      ...query
    }
  })
}
// 获取藏品 名家推荐
export const getRecommendList = () => {
  return http('/referral/page', {
    params: {
      size: 10,
      query: 'ArticleSort:56'
    }
  })
}

// 获取版画种类
export const getProductCategory = () => {
  return http('/product_category/tree')
}

export const getProduct = (query) => {
  return http('/product', {
    params: {
      size: 20,
      ...query
    }
  })
}
// 藏品列表
export const getProductGallery = (query) => {
  return http('/product/page', {
    params: {
      size: 20,
      ...query
    }
  })
}
// 藏品详情
export const getProductDetail = (id) => {
  return http('/product/' + id, {
    params: {}
  })
}

// 获取艺术家列表
export const getArtists = (query) => {
  return http('/artists/', {
    params: {
      // size: 20,
      ...query
    }
  })
}
export const getArtistsPage = (query) => {
  return http('/artists/page', {
    params: {
      size: 20,
      ...query
    }
  })
}

// 获取专家顾问列表
export const getExpert = (query) => {
  return http('/expert/page', {
    params: {
      size: 999,
      ...query
    }
  })
}
export const getExpertDetail = (id) => {
  return http('/expert/' + id, {
    params: {
      // size: 20,
      // ...query
    }
  })
}

// 获取艺术家详情
export const getArtistsDetail = (id) => {
  return http('/artists/' + id, {
    params: {}
  })
}
// 通过 名称获取艺术家详情
export const getArtistsName = (name) => {
  return http('/artists/name/' + name, {
    params: {}
  })
}

// 版画知识
export const getKnowledge = (query) => {
  return http('/knowledge/page', {
    params: {
      // size: 20,
      ...query
    }
  })
}

// 版画知识
export const getKnowledgeDetail = (id) => {
  return http('/knowledge/' + id, {
    params: {
      // size: 20,
      // ...query
    }
  })
}

// 搜索
export const getSearch = (query) => {
  return http('/articles/page', {
    params: {
      // size: 20,
      ...query
    }
  })
}

// 展览推荐
export const getExhibitionPage = (query) => {
  return http('/exhibition/page', {
    params: {
      size: 20,
      ...query
    }
  })
}

// 友情链接
export const getBlogroll = (query) => {
  return http('/blogroll/', {
    method: 'get',
    query: {
      // size: 20,
      ...query
    }
  })
}
