import http from '../utils/axios'
// import qs from 'qs'

export const Login = (data) => {
  return http('cms/users/login', {
    method: 'post',
    data: {
      ...data
    }
  })
}

export const getUsers = (id) => {
  return http('cms/users/' + id, {})
}
export const setWatermark = (data) => {
  const formdata = new FormData()
  // console.log(formdata, 11)
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const element = data[key]
      formdata.append(key, element)
    }
  }
  return http('cms/upload', {
    method: 'PUT',
    data: formdata,
    // data,
    headers: {
      // ...data
      // 'Content-Type': 'multipart/form-data'
    }
  })
}

export const uploadImg = (data) => {
  const formdata = new FormData()
  // console.log(formdata, 11)
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const element = data[key]
      formdata.append(key, element)
    }
  }
  return http('cms/upload', {
    method: 'post',
    data: formdata,
    // data,
    headers: {
      // ...data
      // 'Content-Type': 'multipart/form-data'
    }
  })
}
//  轮播操作
export const postPlayer = (data) => {
  return http('player', {
    method: 'post',
    data
  })
}
export const putPlayer = (data, id) => {
  return http('player/' + id, {
    method: 'put',
    data
  })
}
export const deletePlayer = (id) => {
  return http('player/' + id, {
    method: 'delete'
  })
}

// 新闻操作
export const postNews = (data) => {
  return http('news', {
    method: 'post',
    data
  })
}
export const putNews = (data, id) => {
  return http('news/' + id, {
    method: 'put',
    data
  })
}
export const deleteNews = (id) => {
  return http('news/' + id, {
    method: 'delete'
  })
}

export const postProduct = (data) => {
  return http('product', {
    method: 'post',
    data
  })
}
export const putProduct = (data, id) => {
  return http('product/' + id, {
    method: 'put',
    data
  })
}
export const deleteProduct = (id) => {
  return http('product/' + id, {
    method: 'delete'
  })
}
// 全部文章管理操作
export const postArticles = (data) => {
  return http('articles', {
    method: 'post',
    data
  })
}
export const putArticles = (data, id) => {
  return http('articles/' + id, {
    method: 'put',
    data
  })
}
export const deleteArticles = (id) => {
  return http('articles/' + id, {
    method: 'delete'
  })
}

// 推荐 文章 藏品推荐
export const postReferral = (data) => {
  return http('referral', {
    method: 'post',
    data
  })
}
export const putReferral = (data, id) => {
  return http('referral/' + id, {
    method: 'put',
    data
  })
}
export const deleteReferral = (id) => {
  return http('referral/' + id, {
    method: 'delete'
  })
}

// 版画艺术家
export const postArtists = (data) => {
  return http('artists', {
    method: 'post',
    data
  })
}
export const putArtists = (data, id) => {
  return http('artists/' + id, {
    method: 'put',
    data
  })
}
export const deleteArtists = (id) => {
  return http('artists/' + id, {
    method: 'delete'
  })
}

// 版画知识
export const postKnowledge = (data) => {
  return http('knowledge', {
    method: 'post',
    data
  })
}
export const putKnowledge = (data, id) => {
  return http('knowledge/' + id, {
    method: 'put',
    data
  })
}
export const deleteKnowledge = (id) => {
  return http('knowledge/' + id, {
    method: 'delete'
  })
}

// 专家顾问
export const postExpert = (data) => {
  return http('expert/', {
    method: 'post',
    data
  })
}
export const putExpert = (data, id) => {
  return http('expert/' + id, {
    method: 'put',
    data
  })
}
export const deleteExpert = (id) => {
  return http('expert/' + id, {
    method: 'delete'
  })
}

// 反馈
export const postMessages = (data) => {
  return http('/messages/', {
    method: 'POST',
    data: {
      // size: 20,
      ...data
    }
  })
}

export const putMessages = (data, id) => {
  return http('messages/' + id, {
    method: 'put',
    data
  })
}
export const deleteMessages = (id) => {
  return http('messages/' + id, {
    method: 'delete'
  })
}

// 友情链接
export const postBlogroll = (data) => {
  return http('/blogroll/', {
    method: 'POST',
    data: {
      // size: 20,
      ...data
    }
  })
}

export const putBlogroll = (data, id) => {
  return http('blogroll/' + id, {
    method: 'put',
    data
  })
}
export const deleteBlogroll = (id) => {
  return http('blogroll/' + id, {
    method: 'delete'
  })
}
// 修改密码

//
export const postUserChange = (data) => {
  return http('cms/users/change', {
    method: 'POST',
    data: {
      // size: 20,
      ...data
    }
  })
}

// 水印
export const getWatermark = (data) => {
  return http('/watermark/', {
    method: 'get',
    query: {
      // size: 20,
      ...data
    }
  })
}
export const postWatermark = (data) => {
  return http('/watermark/', {
    method: 'POST',
    data: {
      // size: 20,
      ...data
    }
  })
}

export const putWatermark = (data, id) => {
  return http('watermark/' + id, {
    method: 'put',
    data
  })
}
export const deleteWatermark = (id) => {
  return http('watermark/' + id, {
    method: 'delete'
  })
}

// 模块信息Modules
export const getModules = (data) => {
  return http('/modules/', {
    method: 'get',
    query: {
      // size: 20,
      ...data
    }
  })
}
export const postModules = (data) => {
  return http('/modules/', {
    method: 'POST',
    data: {
      // size: 20,
      ...data
    }
  })
}

export const putModules = (data, id) => {
  return http('modules/' + id, {
    method: 'put',
    data
  })
}
export const deleteModules = (id) => {
  return http('modules/' + id, {
    method: 'delete'
  })
}

// 统计数据接口

export const getArticleDivisionsCount = (data) => {
  return http('/article_divisions/count', {
    method: 'get',
    query: {
      // size: 20,
      ...data
    }
  })
}

// 展览推荐

export const getExhibition = (query) => {
  return http('/exhibition/', {
    params: {
      // size: 20,
      ...query
    }
  })
}
export const getExhibitionDetail = (id) => {
  return http('/exhibition/' + id, {})
}

export const postExhibition = (data) => {
  return http('/exhibition/', {
    method: 'POST',
    data: {
      // size: 20,
      ...data
    }
  })
}

export const putExhibition = (data, id) => {
  return http('/exhibition/' + id, {
    method: 'put',
    data
  })
}
export const deleteExhibition = (id) => {
  return http('/exhibition/' + id, {
    method: 'delete'
  })
}
