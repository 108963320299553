<template>
  <div class="contact-us-content">
    <page-header title="联系我们" :backgroundImage="headerBackgroundImage">
      <!-- <template v-slot:body> 123 </template>
      <template #right>44456</template> -->
    </page-header>

    <div class="page-body">
      <div class="background-box">
        <div class="left-box">
          <div class="subtitle">联系方式</div>
          <div>电话：010-88580136 88895208</div>
          <div>传真：010-88895208-108</div>
          <div>邮箱：3zmuseum@sina.cn</div>
          <div>邮编：100195</div>
          <div>地址：北京海淀区西四环北路15号依斯特大厦716号</div>

          <el-space class="ewm">
            <div class="ewm-box">
              <el-image :src="zhaotangweiAEWM"></el-image>
              <div>微信联系 赵馆长</div>
            </div>
            <div class="ewm-box">
              <el-image :src="qunliaoEWM"></el-image>
              <div>加入QQ交流群</div>
            </div>
          </el-space>
        </div>

        <div>
          <div class="subtitle">问题反馈</div>
          <el-form
            ref="ruleFormRef"
            :rules="rules"
            :model="formData"
            label-width="auto"
            label-position="top"
            size="large"
          >
            <el-form-item prop="Title" label="问题反馈">
              <el-input v-model="formData.Title" />
            </el-form-item>
            <el-form-item prop="Call" label="称呼方式">
              <el-input v-model="formData.Call" />
            </el-form-item>
            <el-form-item prop="Tel" label="联系方式">
              <el-input v-model.number="formData.Tel" />
            </el-form-item>
            <el-form-item prop="Content" label="问题描述">
              <el-input
                v-model="formData.Content"
                :autosize="{ minRows: 4, maxRows: 8 }"
                type="textarea"
                placeholder=""
              />
            </el-form-item>

            <el-form-item>
              <div class="btn-group">
                <el-button
                  class="feedback-btn"
                  type="primary"
                  @click="feedback(ruleFormRef)"
                >
                  反馈
                </el-button>

                <el-button class="clear-btn" @click="clearForm">清空</el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ElMessage } from 'element-plus'

import { postMessages } from '../../../apis'
import pageHeader from '../../../components/page-header'
import zhaotangweiAEWM from '../../../assets/img/zhaotangwei.jpg'
import qunliaoEWM from '../../../assets/img/qunliao.jpg'

import headerBackgroundImage from '../../../assets/title-background-image/xyjn.jpg'
import { reactive, ref } from 'vue'
const ruleFormRef = ref()
const formData = reactive({
  Call: '',
  Content: '',
  Tel: '',
  Title: ''
})

const rules = reactive({
  Title: [
    { required: true, message: '请输入问题', trigger: 'blur' }
    // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
  ],
  Call: [
    { required: true, message: '请输入称呼方式', trigger: 'blur' }
    // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
  ],
  Tel: [
    { required: true, message: '请输入联系方式', trigger: 'blur' },
    { type: 'number', message: '请输入正确的联系方式', trigger: 'blur' }

    // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
  ],
  Content: [
    { required: true, message: '请输入问题描述', trigger: 'blur' }
    // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
  ]
})
const clearForm = () => {
  formData.Content = ''
  formData.Call = ''
  formData.Tel = ''
  formData.Title = ''
}

const feedback = async (formEl) => {
  console.log(formEl, 123123)
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!')
      formData.Tel = formData.Tel + ''
      postMessages(formData).then((res) => {
        console.log(res, 123)
        clearForm()
        ElMessage({
          offset: 200,
          message: '反馈成功，我们收到消息后会及时与您联系。',
          type: 'success'
        })
      })
    } else {
      console.log('error submit!', fields)
    }
  })
}
</script>
<style lang="scss">
.contact-us-content {
  // width: 100%;
  // height: 80vh;
  .background-box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    .left-box {
    }
    .ewm {
      flex-wrap: wrap;
    }
    .ewm-box {
      margin: 20px;
      text-align: center;
      font-size: 14px;
      background: white;
      width: 200px;
      height: 200px;
    }
    > div {
      line-height: 40px;
      margin: 10px;
      padding: 20px;

      background: rgba($color: #fff, $alpha: 0.5);

      font-size: 20px;
      flex: 1;
    }
    .subtitle {
      color: #3388b1;
      margin-bottom: 20px;
      font-size: 24px;
      text-shadow: 1px 1px 0px #fff;
    }

    .btn-group {
      width: 100%;
      display: flex;
      justify-content: space-around;
    }
    .feedback-btn,
    .clear-btn {
      width: 45%;
      margin: 6px 0;
    }
  }
}
</style>
