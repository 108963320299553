<template>
  <div class="web-collection-detail">
    <div class="page-body">
      <el-page-header content="藏品详情" @back="goBackClose" />
      <h2 class="name">{{ pageData.ProductName }}</h2>

      <el-row class="collection-detail-box">
        <el-col :span="10">
          <el-image
            teleported
            hide-on-click-modal
            fit="contain"
            class="collection-image"
            preview-teleported
            :preview-src-list="[
              BASE_HOST + 'v1/images/' + pageData.ProductBpic,
            ]"
            :src="BASE_HOST + 'v1/images/' + pageData.ProductSpic"
          ></el-image>
        </el-col>
        <el-col :span="11" :offset="1">
          <div class="item">
            <div class="label">作品名称</div>
            <div class="value">{{ pageData.ProductName }}</div>
          </div>

          <div class="item">
            <div class="label">作者</div>
            <div class="value">
              <el-link
                style="font-size: 16px"
                v-if="productOwnerData.ArticleTitle"
                type="primary"
                @click="toProductOwner(productOwnerData.Id)"
              >
                {{ productOwnerData.ArticleTitle }}
                <!-- productOwnerData.ArticleTitle -->
              </el-link>
              <div v-else>
                {{ pageData.ProductOwner }}
              </div>
            </div>
          </div>
          <div class="item">
            <div class="label">年代</div>
            <div class="value">{{ pageData.ProductYears }}</div>
          </div>
          <div class="item">
            <div class="label">尺寸</div>
            <div class="value">{{ pageData.ProAuthor }}</div>
          </div>

          <div class="item">
            <div class="label">类型</div>
            <div class="value">{{ pageData.ProductMold }}</div>
          </div>

          <div class="item">
            <div class="label">时期</div>
            <div class="value">{{ getClassification(pageData.CatId2) }}</div>
          </div>

          <div class="item">
            <div class="label">作品分类</div>

            <div class="value">
              <el-tag
                v-if="pageData.ProductVip"
                effect="dark"
                class="ml-2"
                type="warning"
              >
                馆藏精品
              </el-tag>
            </div>
            <div class="value">
              <el-tag
                v-if="pageData.CatId3 === 23"
                effect="dark"
                class="ml-2"
                type="success"
              >
                名家作品
              </el-tag>
            </div>
          </div>
          <div class="item">
            <div class="label">馆藏编号</div>
            <div class="value">{{ pageData.ProductNumber }}</div>
          </div>
          <div class="item">
            <div class="label">发布时间</div>
            <div class="value">
              {{
                moment(pageData.ProductTime * 1000).format(
                  "YYYY-MM-DD  hh:mm:ss"
                )
              }}
            </div>
          </div>
        </el-col>
        <el-col :span="2" class="back-btn-box">
          <el-button :icon="ArrowLeft" @click="goBackClose">返回</el-button>
        </el-col>
      </el-row>

      <div class="dev-box">
        简介：
        <div v-html="pageData.ProductContent"></div>
      </div>

      <div class="product-box">
        <div class="product-title">推荐作品</div>

        <div class="product-warp">
          <div class="product-content">
            <div
              @click="productClick(item)"
              class="product-item"
              v-for="(item, index) in productList"
              :key="index"
            >
              <el-image
                fit="contain"
                :src="BASE_HOST + 'v1/images/' + item.ProductSpic"
              ></el-image>

              <div class="product-item-name">
                {{ item.ProductName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  getProductDetail,
  getProductGallery,
  getArtistsName
} from '../../../apis'
import { ArrowLeft } from '@element-plus/icons-vue'
import { ref, onMounted, computed } from 'vue'
import moment from 'moment'
import { BASE_HOST } from '../../../utils/axios'
import { useRoute, useRouter } from 'vue-router'
import {
  // ,
  // 获取全部种类的方法
  classification
} from '../../../utils/get-classification'
const pageData = ref({})
const productList = ref([])
const productOwnerData = ref({})
const route = useRoute()
const router = useRouter()
// const goBack = () => {
//   router.go(-1)
// }
const goBackClose = () => {
  window.close()
}
const classificationData = computed({
  get: () => {
    return {
      periodList: [
        // { label: '全部时期', value: 0 },
        ...classification.periodList,
        { label: '其他时期', value: 15 }
      ],
      // formList: [{ label: '全部形式', value: 0 },
      ...classification.formList
    }
  }
})
const getClassification = (id) => {
  const findObj =
    classificationData.value.periodList.find((v) => v.value === id) || {}
  return findObj.label
}

const getData = (id = route.query.id) => {
  id = Number(id)
  // 获取数据
  getProductDetail(id).then((res) => {
    pageData.value = { ...res.data.data }

    // 获取作者信息
    if (res.data.data.ProductOwner) {
      getArtistsName(res.data.data.ProductOwner).then((productOwner) => {
        productOwnerData.value = productOwner.data.data
      })
    }

    // 获取相关作品
    getProductGallery({
      size: 20,
      keys: pageData.value.ProductOwner
    }).then((resData) => {
      const arr = resData.data.data.filter((item) => {
        if (item.Id !== id) {
          return true
        } else {
          return false
        }
      })

      productList.value = arr
    })
  })
}
const toProductOwner = (id) => {
  const url = router.resolve({
    name: 'artistDetail',
    query: {
      id: id
    }
  })

  window.open(url.href, '_blank')
}
const productClick = (item) => {
  const url = router.resolve({
    name: 'collectionDetail',
    query: {
      id: item.Id
    }
  })

  window.open(url.href, '_blank')

  // getData(item.Id)
}
onMounted(() => {
  getData()
})
</script>

<style lang="scss">
.web-collection-detail {
  min-height: 70vh;
  .back-btn-box {
    display: flex;
    align-items: flex-end;
  }
  .name {
    font-size: 36px;
  }
  .collection-detail-box {
    background: rgba($color: #000, $alpha: 0.1);
    margin: 20px auto;
    padding: 20px;
  }
  .collection-image {
    background: rgba($color: #fff, $alpha: 0.6);
    padding: 10px 20px;
    width: 100%;
    height: 400px;
  }
  .item {
    margin: 8px;

    display: flex;
    // justify-content: space-around;
    line-height: 32px;

    .label {
      color: #030204;
      margin-right: 1em;
      width: 6em;
      text-align: justify;
      text-align-last: justify;
      padding: 2px 16px;
      background: rgba($color: #fff, $alpha: 0.6);
    }
    .value {
      color: #030204;
      padding: 2px;
      font-size: 16px;
    }
  }

  .dev-box {
    padding: 20px;

    background: rgba($color: #000, $alpha: 0.1);
  }
  .product-box {
    margin: 20px auto;

    padding: 20px;

    background: rgba($color: #000, $alpha: 0.1);
    .product-title {
      font-size: 24px;
    }
    .product-warp {
      // height: 300px;
      width: 100%;
      overflow: auto;
    }
    .product-content {
      min-height: 300px;
      display: flex;
      flex-wrap: wrap;
      // background: #000;
      .product-item {
        cursor: pointer;
        max-width: 300px;
        margin: 10px auto;
        // height: 200px;
        // width: 25%;
        flex: auto;
        // height: 100%;
        margin: 10px;
        background: rgba($color: #000000, $alpha: 0.5);
        .product-item-name {
          color: #fff;
          text-align: center;
          // line-height: 30px;
          // background: pink;
        }
        .el-image {
          width: 100%;
          height: calc(100% - 30px);
        }
      }
    }
  }
}
</style>
