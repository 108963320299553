import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Router from './router'
import App from './App.vue'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
// import V3waterfall from 'v3-waterfall'
import { init as initClassification } from './utils/get-classification'
// 获取种类
initClassification()

// 兼容 域名前缀变化
if (
  !window.location.href.includes('www.3zmuseum.com') &&
  !window.location.href.includes('localhost')
) {
  window.location.href = 'http://www.3zmuseum.com/'
}
const app = createApp(App)
app.use(ElementPlus, { locale: zhCn })
app.use(Router)
// app.use(initClassification)
// app.use(V3waterfall)

app.mount('#app')
