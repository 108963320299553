<template>
  <CommonLsitPage
    dataUrl="/knowledge/page"
    :params="pageParams"
    :requestParams="{}"
    ref="tableRef"
  ></CommonLsitPage>
  <el-dialog v-model="dialogVisible" title="确认操作" width="30%">
    <span
      >此操作将删除数据
      <span style="color: red">{{ knowledgeDetail.ArticleTitle }}</span
      >，请谨慎操作。</span
    >
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="danger" @click="deleteArticlesFunction"
          >删除</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import CommonLsitPage from '../../../components/common-list-page.vue'
import moment from '../../../utils/moment'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
// import { BASE_HOST } from '../../../utils/axios'
import { ElMessage } from 'element-plus'
import { deleteKnowledge } from '../../../apis'

import { Delete, Edit, Tickets } from '@element-plus/icons-vue'

const tableRef = ref(null)
const dialogVisible = ref(false)

const router = useRouter()
// const route = useRoute()
const knowledgeDetail = ref({})
const pageParams = {
  title: '版画知识管理',
  btns: [
    {
      type: 'primary',
      name: '发布版画知识',
      onClick: () => {
        console.log(111)
        router.push({
          name: 'adminKnowledgeOperation'
        })
      }
    }
  ],
  // <el-table-column prop="date" label="Date" width="180" />
  tableColumn: [
    {
      label: '发布时间',
      prop: 'Pubtime',
      sortable: 'custom',
      width: 200,
      formatter: (row, column, cellValue, index) => {
        const str = moment(cellValue).format('YYYY-MM-DD  hh:mm:ss')
        // console.log(str)
        return str
      }
    },

    {
      prop: 'ArticleTitle',
      label: '标题'
    },
    {
      label: '类型',
      prop: 'ArticleSort',
      filters: [
        { text: '版种介绍', value: 102 },
        { text: '版画知识', value: 103 }
      ],
      formatter: (row, column, cellValue, index) => {
        const str = cellValue === 102 ? '版种介绍' : '版画知识'
        // console.log(str)
        return str
      }
    },
    {
      prop: 'Honor',
      label: '简介'
    },

    {
      label: '操作',
      width: 240,
      operations: [
        {
          name: '查看',
          // type: 'plain',
          icon: Tickets,
          onClick: (scope) => {
            const knowledgeDetailPage = router.resolve({
              name: 'knowledgeDetail',
              query: { id: scope.row.Id }
            })

            window.open(knowledgeDetailPage.href, '_blank')

            // knowledgeDetail.value = scope.row
            // showDrawer.value = true
          }
        },
        {
          name: '编辑',
          type: 'success',
          icon: Edit,
          onClick: (scope) => {
            // console.log(tableRef.value.refresh())
            console.log(scope.row.Id, 1111)

            router.push({
              name: 'adminKnowledgeOperation',
              query: {
                id: scope.row.Id
              }
            })
          }
        },
        {
          name: '删除',
          type: 'danger',
          icon: Delete,

          onClick: (scope) => {
            knowledgeDetail.value = scope.row
            dialogVisible.value = true
            console.log(scope, 1111)
          }
        }
      ]
    }
  ]
}

const deleteArticlesFunction = () => {
  deleteKnowledge(knowledgeDetail.value.Id).then((res) => {
    tableRef.value.refresh()
    // console.log(res, '删除数据')
    dialogVisible.value = false
    ElMessage({
      message: '删除成功',
      type: 'success'
    })
  })
}
</script>

<style scoped lang="scss">
.cell-item {
  width: 6em;
}
.dec-box {
  // background: red;
}
</style>
