<template>
  <div class="web-index-content">
    <el-carousel height="100vh" direction="vertical">
      <el-carousel-item v-for="item in backgroundImages" :key="item">
        <el-image class="background-img" fit="cover" :src="item.img"></el-image>
      </el-carousel-item>
    </el-carousel>
    <div class="background-box">
      <el-image class="logo-img" fit="cover" :src="Logo"></el-image>

      <div class="title-box">
        <el-image class="chun" fit="cover" :src="textChun"></el-image>
        <el-image class="qiu" fit="cover" :src="textQiu"></el-image>
        <el-image class="ban" fit="cover" :src="textBan"></el-image>
        <el-image class="hua" fit="cover" :src="textHua"></el-image>
        <div class="bwg">博物馆</div>
      </div>

      <div class="btn-box">
        <div class="btn" @click="toHome">点击进入</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Logo from '../../../assets/img/logo.png'
import img1 from '../../../assets/title-background-image/lie.jpg'
import img2 from '../../../assets/title-background-image/hsys.jpg'
import img3 from '../../../assets/title-background-image/yxyz.jpg'
import img4 from '../../../assets/title-background-image/hscx.jpg'
import img5 from '../../../assets/title-background-image/wg.jpg'
import { useRouter } from 'vue-router'
import textChun from '../../../assets/img/text-chun.png'
import textQiu from '../../../assets/img/text-qiu.png'
import textBan from '../../../assets/img/text-ban.png'
import textHua from '../../../assets/img/text-hua.png'
const router = useRouter()
const backgroundImages = [
  {
    img: img1
  },
  {
    img: img2
  },
  {
    img: img3
  },
  {
    img: img4
  },
  {
    img: img5
  }
]
const toHome = () => {
  router.push({ path: '/web/home' })
}
</script>
<style lang="scss" scoped>
.web-index-content {
  width: 100%;
  position: relative;
  .logo-img {
    width: 80px;
    margin: 30px;
  }
  .bwg {
    background: linear-gradient(90deg, #1997fa1c, #1997fa);

    color: #fff;
    width: 80%;
    font-size: 80px;
    font-family: '楷体';
    letter-spacing: 30px;
    text-align: right;
    padding: 20px 10%;
    margin-left: 20%;
  }
  .title-box {
    position: absolute;
    top: 90px;
    height: 50vh;
    width: 100%;

    .el-image {
      // flex: auto;
      margin: 10px auto;
      width: 25%;
    }
  }
  .btn-box {
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    .btn {
      transition: 0.5s;
      cursor: pointer;
      // margin: 60px auto;
      width: 7em;
      text-align: center;
      line-height: 2.5em;
      border-radius: 10px;
      border: 2px solid #fff;
      background: rgba($color: #000000, $alpha: 0.5);
      font-size: 34px;

      color: #fff;
      &:hover {
        transform: scale(1.2);
        background: rgba($color: #1997fa, $alpha: 0.5);
      }
    }
  }
  .background-box {
    position: absolute;
    left: 5%;
    top: 5%;
    width: 90%;
    height: 90%;
    // background:
    border-radius: 10px;

    // background-color: rgba($color: #0093e9, $alpha: 0.6);
    background-image: linear-gradient(
      -45deg,
      rgba($color: #fff, $alpha: 0.2) 0%,
      rgba($color: #fff, $alpha: 0.7) 100%
    );
  }

  .background-img {
    width: 100%;
    height: 100%;
  }
}
</style>
