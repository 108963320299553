<template>
  <div>
    <div class="cropper-content">
      <!-- 剪裁框 -->
      <!-- props.imgFile -->
      <div class="cropper">
        <img ref="imageDom" :src="props.imgFile" alt="" />
      </div>
      <!-- 预览框 -->
      <div
        class="show-preview"
        :style="{
          overflow: 'hidden',
          margin: '0 25px',
          display: 'flex',
          'align-items': 'center'
        }"
      >
        <div class="preview before" />
      </div>
    </div>
    <div class="footer-btn">
      <!-- 缩放旋转按钮 -->
      <div class="scope-btn">
        <el-tooltip class="item" effect="dark" content="放大" placement="top">
          <el-button type="" @click="cropperzoom(0.05)">
            <el-icon><ZoomIn /></el-icon>
          </el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="缩小" placement="top">
          <el-button @click="cropperzoom(-0.05)">
            <el-icon><ZoomOut /></el-icon>
          </el-button>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="逆时针旋转"
          placement="top"
        >
          <el-button @click="cropperRotate(-90)">
            <el-icon><RefreshLeft /></el-icon>
          </el-button>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="顺时针旋转"
          placement="top"
        >
          <el-button @click="cropperRotate(90)">
            <el-icon><RefreshRight /></el-icon>
          </el-button>
        </el-tooltip>
      </div>

      <!-- 确认上传按钮 -->
      <div class="upload-btn">
        <!-- <el-button type="primary" @click="uploadImg('blob')">上传</el-button> -->
        <el-button @click="closeCropper">取消</el-button>
        <el-button type="primary" :disabled="isDisabled" @click="sureSava()"
          >确定</el-button
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'

import {
  RefreshLeft,
  RefreshRight,
  ZoomIn,
  ZoomOut
} from '@element-plus/icons-vue'

import { ref, defineProps, defineEmits, onMounted } from 'vue'

const props = defineProps({
  imgFile: String,
  autoCropWidth: Number,
  autoCropHeight: Number
})

const emits = defineEmits(['closeCropper'])
const imageDom = ref()
const myCropper = ref(null)
const afterImg = ref('')
// const ScaleX = ref(1)
// const ScaleY = ref(1)
// const fixed = false
// const fixedBox = false
// const inputRotate = 0
const isDisabled = false

const closeCropper = () => {
  emits('closeCropper')
}
// 绝对角度旋转
// const cropperrotateTo = (val) => {
//   myCropper.rotateTo(val)
// }

// const handleChangeRotate = (value) => {
//   cropperrotateTo(value)
// }

const init = () => {
  myCropper.value = new Cropper(imageDom.value, {
    viewMode: 1,
    dragMode: 'move',
    // initialAspectRatio: 1,
    preview: '.before',
    background: false,
    autoCropArea: 1,
    zoomOnWheel: true,
    aspectRatio: 3.6
    // movable :true,
    // rotatable :true
  })
}
// 裁剪
// const uploadImgs = () => {
//   this.afterImg = myCropper.value
//     .getCroppedCanvas({
//       imageSmoothingQuality: 'high'
//     })
//     .toDataURL('image/jpeg')
//   this.$emit('getCrop', this.afterImg)
// }

const base64ToBlob = (code) => {
  const parts = code.split(';base64,')
  const contentType = parts[0].split(':')[1]
  const raw = window.atob(parts[1])
  const rawLength = raw.length

  const uInt8Array = new Uint8Array(rawLength)

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i)
  }
  return new Blob([uInt8Array], {
    type: contentType
  })
}
const sureSava = () => {
  afterImg.value = myCropper.value
    .getCroppedCanvas({
      imageSmoothingQuality: 'high'
    })
    .toDataURL('image/png')
  emits('upload', base64ToBlob(afterImg.value))
}
// 缩放
const cropperzoom = (val) => {
  myCropper.value.zoom(val)
}
// 重置
// const cropperReset = () => {
//   myCropper.value.reset()
//   this.ScaleX = 1
//   this.ScaleY = 1
// }
// 移动
// const croppermove = (val1, val2) => {
//   myCropper.value.move(val1, val2)
// }
// 旋转
const cropperRotate = (val) => {
  myCropper.value.rotate(val)
}

// X轴翻转
// const cropperScaleX = () => {
//   ScaleX.value = -ScaleX.value
//   if (
//     myCropper.value.getImageData().rotate === -90 ||
//     myCropper.value.getImageData().rotate === 90
//   ) {
//     myCropper.value.scaleY(ScaleX.value)
//   } else {
//     myCropper.value.scaleX(ScaleX.value)
//   }
// }
// y轴翻转
// const cropperScaleY = () => {
//   // console.log(myCropper.value.getImageData().rotate)
//   ScaleY.value = -ScaleY.value
//   if (
//     myCropper.value.getImageData().rotate === -90 ||
//     myCropper.value.getImageData().rotate === 90
//   ) {
//     myCropper.value.scaleX(ScaleY.value)
//   } else {
//     myCropper.value.scaleY(ScaleY.value)
//   }
// }

onMounted(() => {
  init()
})
// export default {
//   name: 'VueCropper',

//   watch: {
//     // imgFile(file) {
//     //   this.imgFile = file
//     // }
//   },

//   methods: {
//   }
// }
</script>
<style lang="scss" scoped>
.cropper {
  border: 1px solid #fff;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC);
  img {
    width: 100%;
    opacity: 0;
  }
}
.cropper-content {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
}
.cropper-content .cropper {
  width: 550px;
  height: 400px;
  overflow: hidden;
}
.cropper-content .show-preview {
  flex: 1;
  -webkit-flex: 1;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  overflow: hidden;
  /* border: 1px solid #cccccc; */
  background: #cccccc;
  margin-left: 40px;
}
.preview {
  overflow: hidden;
  border: 1px solid #468ac8;
  background: #cccccc;
}
.footer-btn {
  margin-top: 30px;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
}
.footer-btn .scope-btn {
  width: 260px;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
}
.footer-btn .scope-btn span {
  display: inline-block;
  padding: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background: #fff;
  cursor: pointer;
}
.footer-btn .scope-btn span i {
  font-size: 32px;
  color: #333333;
}
.footer-btn .upload-btn {
  flex: 1;
  -webkit-flex: 1;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  margin-right: 25px;
}
.footer-btn .btn {
  outline: none;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  font-weight: 500;
  padding: 8px 15px;
  font-size: 12px;
  border-radius: 3px;
  color: #fff;
  background-color: #67c23a;
  border-color: #67c23a;
}
.solide {
  margin-left: 25px;
  width: 200px;
}
.before {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* 这个属性可以得到想要的效果 */
}
</style>
