<template>
  <div class="web-search-content">
    <el-container>
      <!-- <el-header class="header-content">
        <el-affix :offset="0">
          <div class="header-box">
            <div class="logo-box" @click="toHome">
              <el-image :src="Logo" class="logo" />

            </div>
            <div class="right-box">
              <navigation-bar
                class="navigation-menu"
                :data="navigationData"
                @itemClick="menuSelect"
                :default-active="queryData.activeMenu"
              ></navigation-bar>
            </div>
          </div>
        </el-affix>
      </el-header> -->
      <el-main class="main-body reveal-top">
        <div class="page-body">
          <div class="search-box">
            <el-page-header content="站内搜索" @back="toHome" />
            <div class="title">
              在
              <span class="search-key">{{
                getModel(queryData.activeMenu)
              }}</span>
              中搜索:
            </div>

            <div class="search">
              <el-input
                clear
                size="large"
                v-model="queryData.keys"
                class="search-input"
                placeholder="请输入搜索内容"
                :suffix-icon="Search"
              />
              <el-button
                :disabled="!queryData.keys"
                type="primary"
                size="large"
                class="search-button"
                @click="searchFun"
                >搜索</el-button
              >
              <el-button size="large" class="search-button" @click="clearFun"
                >清除</el-button
              >
            </div>
            <el-space wrap class="search-history">
              <el-tag
                @click="historyClick(tag)"
                effect="plain"
                type=""
                size="large"
                v-for="(tag, tagIndex) in historyList"
                :key="tag.name"
                class="history-item"
                closable
                @close="tagClose(tagIndex)"
              >
                {{ tag.name }}
              </el-tag>
            </el-space>
            <div class="data-total" v-if="searchData.code">
              搜索到
              <span class="data-number">{{ searchData.total || 0 }}</span>
              条符合条件的数据
            </div>
          </div>

          <div class="search-list">
            <div
              @click="searchItemClick(item)"
              class="search-item"
              :key="index"
              v-for="(item, index) in searchData.data"
            >
              <!-- <div class="tag">
                {{ getType(item) }}

              </div> -->
              <el-image
                lazy
                fit="contain"
                class="item-image"
                :src="BASE_HOST + 'v1/images/' + item.ProductSpic"
              >
              </el-image>
              <div class="image-name">
                {{ item.ProductName }}
              </div>
            </div>
          </div>

          <CustomPagination
            v-if="searchData.data"
            background
            :page-size="20"
            :total="searchData.total"
            :current-page="queryData.page"
            @current-change="currentChange"
          />
        </div>
      </el-main>
      <!-- <el-footer class="footer-box">
        <footer-page></footer-page>
      </el-footer> -->
    </el-container>
  </div>
</template>

<script setup>
// import navigationBar from '../../../components/navigation-bar'
// import footerPage from '../common/footer-component'
import { getProductGallery } from '../../../apis'
import { reactive, watchEffect, onMounted, ref } from 'vue'
// import { ElDivider } from 'element-plus'
import { BASE_HOST } from '../../../utils/axios'

import { Search } from '@element-plus/icons-vue'
// import Logo from '../../../assets/img/logo-text.png'

// import textChun from '../../../assets/img/text-chun.png'
// import textQiu from '../../../assets/img/text-qiu.png'
// import textBan from '../../../assets/img/text-ban.png'
// import textHua from '../../../assets/img/text-hua.png'

import scrollReveal from '../../../utils/scrollReveal'
import CustomPagination from '../../../components/custom-pagination.vue'
// import typeData from '../../../data/type'

import { useRouter } from 'vue-router'
// const spacer = h(ElDivider, { direction: 'vertical' })
const router = useRouter()
const historyList = ref([])
// const getType = (item) => {
//   if (!item) return
//   // const { ModuleID, ArticleSort } = item
//   console.log(item, 1111)
//   // if (typeData[ModuleID][ArticleSort]) {
//   //   return typeData[ModuleID][ArticleSort].name
//   // } else if (typeData[ModuleID]) {
//   //   return typeData[ModuleID].name
//   // } else {
//   //   return '其他'
//   // }
// }
const tagClose = (index) => {
  const arr = [...historyList.value]
  arr.splice(index, 1)
  console.log(arr, index)

  historyList.value = arr
}
const queryData = reactive({
  keys: '',
  page: 1,
  activeMenu: 'all'
})

const navigationData = [
  { label: '全部藏品', value: 'all' },
  // { label: '全部模块', value: 'all' },
  { label: '新闻公告', value: 47 },
  { label: '文章推荐', value: 55 },
  { label: '版画艺术家', value: 20 },
  { label: '版画知识', value: 61 },
  { label: '专家顾问', value: 70 }
]
const getModel = (val) => {
  const model = navigationData.find((item) => item.value === val)
  return model ? model.label : ''
}
onMounted(() => {
  // 初始化 加载动画
  scrollReveal.init()
})

const searchData = ref({})
const getData = () => {
  getProductGallery({
    size: 20,
    ...queryData,
    keys: queryData.keys.replace(/\s/g, ',')
  }).then((res) => {
    console.log(res)
    searchData.value = res.data
  })
}

const historyClick = ({ name }) => {
  queryData.page = 1
  queryData.keys = name

  getData()
}
const currentChange = (e) => {
  queryData.page = e
  getData()
}
const searchFun = () => {
  queryData.page = 1
  console.log(historyList.value.includes(queryData.keys), historyList.value)
  const cz = historyList.value.find((item) => item.name === queryData.keys)
  if (!cz) {
    historyList.value.push({ name: queryData.keys })
  }
  getData()
}
const clearFun = () => {
  queryData.keys = ''
}

const searchItemClick = ({ Id, ModuleID }) => {
  const url = router.resolve({
    name: 'collectionDetail',
    query: {
      id: Id
    }
  })

  window.open(url.href, '_blank')
}
const toHome = () => {
  router.push({
    name: 'home'
  })
}
// const menuSelect = (item) => {
//   queryData.activeMenu = item
//   console.log(item, 1)
// }
watchEffect(() => {})
</script>

<style lang="scss">
.web-search-content {
  // background: rgba($color: #000000, $alpha: 1.0);
  // background-size: 100% 100%;

  // height: 100vh;
  .header-content {
    min-height: 100px;
    height: auto;
    padding: 0;

    // background: rgba($color: #fff, $alpha: 1);
    background-image: url(../../../assets/img/header-bj.png);
    // background-repeat: no-repeat;
    background-size: auto 200%;
    // background-position: 0px 0px;

    .header-box {
      background: rgba($color: #fff, $alpha: 0.8);
      box-shadow: 2px 2px 20px #0000003f;
      z-index: 1;
      height: 100px;
      display: flex;
      justify-content: space-between;

      .logo-box {
        // line-height: 60px;
        display: flex;
        align-items: center;

        cursor: pointer;
        margin: 10px;
        .logo {
          // min-width: 300px;
          // height: 100px;
          height: 80px;
          // width: 60px;
        }
        .logo-text {
          margin: 0 -5px;
          width: 60px;

          height: 60px;
        }
        .text-bwg {
          width: 4em;
          // display: inline-block;
          font-family: "楷体";
          font-size: 38px;
          // font-weight: bold;
        }
      }

      .right-box {
        display: flex;
        justify-content: space-between;

        .navigation-menu {
          min-width: 900px;
        }
      }
    }

    .sub-header-box {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 0px;
      overflow: hidden;
      // background-color: pink;
      transition: height 0.5s;
      background: rgba($color: #030204, $alpha: 0.3);
    }
  }

  .main-body {
    background: url(../../../assets/img/body-bj.png) no-repeat;
    background-size: 100%;
    background-position: center bottom;
    background-color: #f4f1ed;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 160px);
    // background-color: #000000;
  }
  .history-item {
    cursor: pointer;
  }
  .search-list {
    background: rgba($color: #fff, $alpha: 0.5);
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;

    .search-item {
      background: rgba($color: #fff, $alpha: 1);
      width: 18%;
      margin: 1% auto;
      cursor: pointer;
      font-size: 20px;
      padding: 10px;
      text-align: center;
      // line-height: 30px;
      .el-image {
        height: 300px;
      }
      .tag {
        border: 1px solid #1997fa;
        font-size: 18px;

        background: rgba($color: #fff, $alpha: 1);
        text-align: center;
        display: inline-block;
        width: 7em;
        padding: 4px;
        color: #1997fa;
      }
      &:hover {
        background: rgba($color: #000000, $alpha: 0.2);
        color: #fd7623;
      }
    }
  }
  .search-box {
    .title {
      border-left: 4px solid #fd7623;
      padding-left: 10px;
      line-height: 30px;
      font-size: 34px;
      margin: 20px 0px;
      .search-key {
        color: #fd7623;
      }
    }
    padding: 20px 40px;
    background: rgba($color: #fff, $alpha: 0.5);
    .search {
      display: flex;
    }
    .search-input {
      .el-input__inner {
        height: 50px;
        font-size: 20px;
      }
    }
    .search-button {
      height: 50px;

      width: 10%;
      font-size: 20px;
    }
    .search-history {
      margin: 10px auto;
    }
  }
  .data-total {
    text-align: center;
    margin: 10px 0;
    color: rgb(53, 53, 53);
    .data-number {
      font-size: 18px;
      color: #fd7623;
    }
  }
  .footer-box {
    padding: 0;
  }
}
</style>
