<template>
  <CommonLsitPage
    dataUrl="/player/page"
    :params="pageParams"
    :requestParams="{}"
    ref="tableRef"
  ></CommonLsitPage>
  <el-dialog v-model="dialogVisible" title="确认操作" width="30%">
    <span
      >此操作将删除轮播 <span style="color: red">{{ newsDetail.FTitle }}</span
      >，请谨慎操作。</span
    >
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="danger" @click="deleteaBannersFunction"
          >删除</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import ModuleType from '../../../data/type'

import CommonLsitPage from '../../../components/common-list-page.vue'
import moment from '../../../utils/moment'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
// import { BASE_HOST } from '../../../utils/axios'
import { ElMessage } from 'element-plus'
import { deletePlayer, putPlayer } from '../../../apis'

import { Delete } from '@element-plus/icons-vue'

const tableRef = ref(null)
const dialogVisible = ref(false)

const router = useRouter()
// const route = useRoute()
const newsDetail = ref({})
const pageParams = {
  title: '轮播管理',
  btns: [
    {
      type: 'primary',
      name: '新增轮播',
      onClick: () => {
        router.push({
          name: 'adminBannerOperation'
        })
      }
    }
  ],
  // <el-table-column prop="date" label="Date" width="180" />
  tableColumn: [
    {
      label: '发布时间',
      prop: 'FUpdate',
      sortable: 'custom',
      width: 200,
      formatter: (row, column, cellValue, index) => {
        const str = moment(cellValue).format('YYYY-MM-DD  hh:mm:ss')
        // console.log(str)
        return str
      }
    },

    {
      prop: 'FTitle',
      label: '标题'
    },
    {
      prop: 'FState',
      label: '是否展示',
      formatter: (row, column, cellValue, index) => {
        const str = cellValue ? '展示' : '不展示'
        // console.log(str)
        return str
      }
    },
    {
      label: '关联类型',
      prop: 'IsProduct',
      width: 150,
      formatter: (row, column, cellValue, index) => {
        return ModuleType[cellValue].name
      }
    },
    {
      prop: 'FAdduser',
      label: '发布者'
    },

    {
      label: '操作',
      width: 240,
      operations: [
        {
          name: '首页展示',
          type: 'success',
          // icon: Edit,
          onShow: ({ row }) => {
            return row.FState === 0
          },
          onClick: (scope) => {
            console.log(scope.row)
            const rowData = scope.row
            rowData.FState = 1
            putPlayer(rowData, rowData.Id).then((res) => {
              console.log(res, 1111)
              if (res.data.code === 200) {
                tableRef.value.refresh()
                ElMessage({
                  message: '操作成功',
                  type: 'success'
                })
              }
            })
          }
        },
        {
          name: '首页隐藏',
          type: 'warning',
          // icon: Edit,
          onShow: ({ row }) => {
            return row.FState === 1
          },
          onClick: (scope) => {
            const rowData = scope.row
            rowData.FState = 0
            putPlayer(rowData, rowData.Id).then((res) => {
              console.log(res, 1111)
              if (res.data.code === 200) {
                tableRef.value.refresh()
                ElMessage({
                  message: '操作成功',
                  type: 'success'
                })
              }
            })
            // console.log(tableRef.value.refresh())
          }
        },
        {
          name: '删除',
          type: 'danger',
          icon: Delete,

          onClick: (scope) => {
            newsDetail.value = scope.row
            dialogVisible.value = true
            console.log(scope, 1111)
          }
        }
      ]
    }
  ]
}

const deleteaBannersFunction = () => {
  deletePlayer(newsDetail.value.Id).then((res) => {
    tableRef.value.refresh()
    // console.log(res, '删除数据')
    dialogVisible.value = false
    ElMessage({
      message: '删除成功',
      type: 'success'
    })
  })
}
</script>

<style scoped lang="scss">
.cell-item {
  width: 6em;
}
.dec-box {
  // background: red;
}
</style>
