<template>
  <div class="news-box">
    <div>
      <div class="title"></div>
      <div class="navbar"></div>

      <div class="search-box"></div>
    </div>

    <page-header
      class="news-header"
      title="新闻公告"
      :backgroundImage="headerBackgroundImage"
    >
      <template v-slot:body>
        <custom-navbar
          :active="queryData.ArticleSort"
          :navData="navbarList"
          @itemClick="itemClick"
        ></custom-navbar>
      </template>
      <template #right>
        <el-input
          size="large"
          v-model="queryData.search"
          class="search-input"
          clearable
          placeholder="请输入搜索内容"
          :suffix-icon="Search"
        />
        <el-button type="primary" class="search-button" @click="searchFun"
          >搜索</el-button
        ></template
      >
    </page-header>
    <div class="page-body news-body">
      <div
        class="news-item"
        v-for="item in newsData.data"
        :key="item.id"
        @click="newsItemClick(item)"
      >
        <div class="news-img">
          <el-image
            fit="contain"
            :src="BASE_HOST + 'v1/images/' + item.BigPic"
            class="image"
          >
            <template #error>
              <el-image class="no-news-image" fit="scale-down" :src="noNews" />
            </template>
          </el-image>
        </div>

        <div class="news-text">
          <div class="news-title">
            <span class="tags" style="color: #0096e2">{{
              newsType[item.ArticleSort]
            }}</span>
            {{ item.ArticleTitle }}
          </div>
          <!-- <div class="news-dec">
           {{item}}</div> -->
          <div class="news-time">
            更新时间：
            {{ moment(item.UpdateTime).format('YYYY年MM月DD日  hh:mm:ss') }}
          </div>
        </div>
      </div>

      <CustomPagination
        background
        :total="newsData.total"
        :current-page="queryData.page"
        @current-change="currentChange"
      />
    </div>
  </div>
</template>

<script setup>
import pageHeader from '../../../components/page-header'
import headerBackgroundImage from '../../../assets/title-background-image/mxlx.jpg'

import CustomNavbar from '../../../components/custom-navbar.vue'
import CustomPagination from '../../../components/custom-pagination.vue'
import noNews from '../../../assets/img/no-img.png'
import { ref, reactive } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { getNewsPage } from '../../../apis'
import moment from 'moment'
import { BASE_HOST } from '../../../utils/axios'
import { useRouter } from 'vue-router'

const router = useRouter()
const newsData = ref([])
const newsType = {
  48: '版画界新闻',
  49: '艺术界新闻',
  50: '站内新闻',
  52: '新闻资讯'
}
const navbarList = [
  { label: '全部信息', value: 'all' },
  // { label: '版画界新闻', value: 48 },
  // { label: '艺术界新闻', value: 49 },
  { label: '新闻资讯', value: 52 },
  { label: '站内新闻', value: 50 }
]
// const SearchValue = ref('')

const queryData = reactive({
  page: 1,
  ArticleSort: 'all',
  keys: ''
})
const itemClick = (val) => {
  queryData.page = 1

  queryData.ArticleSort = val.value
  getData()
}

const currentChange = (e) => {
  queryData.page = e
  getData()
}
const newsItemClick = (item) => {
  router.push({
    name: 'newsDetail',
    query: {
      id: item.Id
    }
  })
}
const searchFun = () => {
  console.log(queryData, 77)
  queryData.keys = queryData.search
  getData()
}
const getData = () => {
  let query = ''

  if (queryData.ArticleSort !== 'all') {
    query = 'ArticleSort:' + queryData.ArticleSort
  }
  getNewsPage({
    ...queryData,
    query
  }).then((res) => {
    newsData.value = res.data
    console.log(res, 1233)
  })
}
getData()
</script>
<style lang="scss">
.news-box {
  .search-box {
    display: flex;
    .search-button {
      width: 120px;
      border-radius: 0 10px 10px 0;
      font-size: 18px;
      height: 50px;
      padding: 0 20px;
    }
    .search-input {
      width: 200px;

      &:nth-child(2) {
        width: 300px;
        .el-input__inner {
          border-radius: 0px;
        }
      }

      .el-input__inner {
        border-radius: 10px 0px 0px 10px;
        font-size: 18px;
        height: 50px;
        padding: 0 20px;
      }
      .el-input__icon {
        font-size: 20px;
      }
    }
  }
  .news-header {
  }
  .news-body {
    // background-color: #fe9900;
  }
  .news-item {
    cursor: pointer;
    display: flex;
    height: 200px;
    width: 100%;
    margin: 20px;
    // background: pink;

    .news-img {
      width: 30%;
      height: 100%;
      margin-right: 20px;

      // background: #ccc;
      .image {
        height: 100%;
        width: 100%;
      }
      .no-news-image {
        height: 100%;
        width: 100%;
        // padding: 20px;
        background: #f7f7f7;
      }
    }
    .news-text {
      flex: 1;

      .news-title {
        padding: 10px;
        /* line-height: 60px; */

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        font-size: 28px;
        .tags {
          padding-right: 10px;
          position: relative;

          &::after {
            position: absolute;
            right: 0;
            top: 20%;

            width: 3px;
            height: 70%;
            background-color: #000000;
            content: '';
          }
        }
      }
      .news-dec {
        color: rgb(80, 80, 80);
        font-size: 16px;
        padding: 10px;
      }
      .news-time {
        padding: 10px;
      }
    }

    &:hover {
      background: rgba($color: #000000, $alpha: 0.1);
      .news-title {
        color: #fe9900;
      }
    }
  }
}
</style>
