<template>
  <el-page-header
    :content="isEdit ? '编辑版画知识' : '发布版画知识'"
    @back="goBack"
  />
  <div class="form-box">
    <el-form
      ref="formRef"
      :model="formData"
      :rules="rules"
      label-width="120px"
      class="demo-formData"
      :size="formSize"
    >
      <el-form-item label="版画知识标题" prop="ArticleTitle">
        <el-input class="input-w500" v-model="formData.ArticleTitle" />
      </el-form-item>
      <el-form-item label="类型" prop="ArticleSort">
        <!-- <el-input v-model="formData.ArticleSort" /> -->

        <el-select
          v-model="formData.ArticleSort"
          class="input-w500"
          placeholder="请选择"
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="简介" prop="Honor">
        <el-input
          class="input-w500"
          v-model="formData.Honor"
          :rows="2"
          type="textarea"
        />
      </el-form-item>
      <!-- <el-form-item label="版画知识种类" prop="ArticleSort">

        <el-select v-model="formData.ArticleSort" placeholder="版画知识种类">
          <el-option
            :label="item.label"
            :value="item.value"
            v-for="item in newsTypeList"
            :key="item.value"
          />
        </el-select>
      </el-form-item> -->
      <el-form-item label="版画知识封面图" prop="BigPic">
        <!-- <el-input v-model="formData.BigPic" /> -->

        <el-upload
          ref="uploadImage"
          :limit="1"
          :action="BASE_HOST + 'v1/cms/upload/'"
          :data="{ module: 'news' }"
          :headers="{
            // 'Content-Type': 'multipart/form-data'
          }"
          :file-list="fileList"
          :on-success="fileUploadSuccess"
          list-type="picture-card"
          :auto-upload="true"
          :on-exceed="handleExceed"
        >
          <!-- {{ fileList }} -->
          <el-icon><Plus /></el-icon>

          <template #file="{ file }">
            <div>
              <img
                class="el-upload-list__item-thumbnail"
                :src="file.url"
                alt=""
              />
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <el-icon><zoom-in /></el-icon>
                </span>
                <!-- <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleDownload(file)"
                >
                  <el-icon><Download /></el-icon>
                </span> -->
                <span
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <el-icon><Delete /></el-icon>
                </span>
              </span>
            </div>
          </template>
        </el-upload>

        <el-dialog v-model="previewImageDialog">
          <el-image fit="cover" :src="dialogImageUrl" alt="Preview Image" />
        </el-dialog>
      </el-form-item>
      <el-form-item label="版画知识内容" prop="ArticleContent">
        <wangeditor v-model="formData.ArticleContent"></wangeditor>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" v-if="!isEdit" @click="submitForm(formRef)"
          >保存</el-button
        >
        <el-button type="primary" v-else @click="submitForm(formRef)"
          >更新</el-button
        >
        <el-button @click="goBack">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { BASE_HOST } from '../../../utils/axios'
import { onBeforeUnmount, ref, shallowRef, reactive } from 'vue'
// import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { Delete, Plus, ZoomIn } from '@element-plus/icons-vue'
import wangeditor from '../../../components/wang-editor-component.vue'

import { useRoute, useRouter } from 'vue-router'
import { getKnowledgeDetail, postKnowledge, putKnowledge } from '../../../apis'
import { ElMessage, genFileId } from 'element-plus'
const formRef = ref()
const isEdit = ref(false)
const formSize = ref('default')
const route = useRoute()
const router = useRouter()
const fileList = ref()
const uploadImage = ref()
const previewImageDialog = ref(false)
const dialogImageUrl = ref('')

const typeList = [
  { label: '版种介绍', value: 102 },
  { label: '版画知识', value: 103 }
]
const formData = reactive({
  ArticleTitle: '',

  ArticleContent: '',
  ArticleSort: 103, // 102 103
  Honor: '', // 简介
  IsTop: '1',
  ModuleID: 27,
  BigPic: '',
  ArtOrder: 9999,

  PubFrom: '',
  SmallPic: ''
})

const rules = reactive({
  ArticleTitle: [
    {
      required: true,
      message: '请输入版画知识标题',
      trigger: 'blur'
    }
  ]
})

const id = route.query.id
const getData = () => {
  getKnowledgeDetail(id).then((res) => {
    console.log(123, res)

    for (const key in res.data.data) {
      if (Object.hasOwnProperty.call(formData, key)) {
        const element = res.data.data[key]
        formData[key] = element
      }
    }
    if (res.data.data.BigPic) {
      fileList.value = [
        {
          name: 'news-image',
          url: BASE_HOST + 'v1/images/' + res.data.data.BigPic
        }
      ]
    }

    // formData
  })
}
if (id) {
  // 如果有 id 就是编辑模式
  isEdit.value = true
  getData()
}
const handleRemove = (file) => {
  console.log(file)
  uploadImage.value.clearFiles()
  // formData.ProductSpic = ''
  formData.BigPic = ''
}

const handlePictureCardPreview = (file) => {
  dialogImageUrl.value = file.url
  previewImageDialog.value = true
}
const fileUploadSuccess = (returnData) => {
  // 文件上传成功
  console.log(returnData.data.ImgPath, '文件上传成功')

  // setWatermark({
  //   filepath: returnData.data.ImgPath,
  //   small: 1
  // }).then((res) => {
  //   console.log(res, 1233)

  // formData.ProductSpic = res.data.data.SmallPath
  formData.BigPic = returnData.data.ImgPath
  // })
}
const handleExceed = (files) => {
  uploadImage.value.clearFiles()
  const file = files[0]
  file.uid = genFileId()
  console.log(file, 1233)

  uploadImage.value.handleStart(file)
  uploadImage.value.submit()
}

// 编辑器实例，必须用 shallowRef
const editorRef = shallowRef()

// const toolbarConfig = {}
// const editorConfig = {
//   placeholder: '请输入内容...',
//   MENU_CONF: {
//     uploadVideo: {
//       server: BASE_HOST + 'v1/cms/upload/',
//       fieldName: 'file',
//       meta: {
//         module: 'video'
//       },
//       customInsert: (res, insertFn) => {
//         // res 即服务端的返回结果
//         console.log(res, 123)
//         // 从 res 中找到 url alt href ，然后插图图片

//         const url = BASE_HOST + 'v1/images/' + res.data.ImgPath
//         const alt = '春秋版画博物馆 版画知识'
//         const href = '#'
//         insertFn(url, alt, href)
//       }
//     },
//     uploadImage: {
//       server: BASE_HOST + 'v1/cms/upload/',
//       fieldName: 'file',
//       meta: {
//         module: 'news'
//       },
//       customInsert: (res, insertFn) => {
//         // res 即服务端的返回结果
//         console.log(res, 123)
//         // 从 res 中找到 url alt href ，然后插图图片

//         const url = BASE_HOST + 'v1/images/' + res.data.ImgPath
//         const alt = '春秋版画博物馆 版画知识'
//         const href = '#'
//         insertFn(url, alt, href)
//       }
//     }
//   }
// }

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  const editor = editorRef.value
  if (editor == null) return
  editor.destroy()
})

// const handleCreated = (editor) => {
//   editorRef.value = editor // 记录 editor 实例，重要！
// }

// console.log(valueHtml.value, 999)

const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    console.log(valid, 1234)
    if (valid) {
      console.log('submit!', formData, formEl)

      if (isEdit.value) {
        // 更新数据
        putKnowledge(formData, id).then((res) => {
          console.log(res)
          ElMessage({
            message: '更新成功成功！',
            type: 'success'
          })

          router.go(-1)
        })
      } else {
        // 上传数据
        postKnowledge(formData).then((res) => {
          console.log(res)
          if (res.data.code === 201) {
            ElMessage({
              message: '创建成功！',
              type: 'success'
            })
            router.go(-1)
          } else {
            ElMessage({
              message: '创建失败请检查参数！',
              type: 'error'
            })
          }
        })
      }
    } else {
      console.log('error submit!', fields)
    }
  })
}

const goBack = () => {
  router.push({ name: 'adminKnowledge' })
}
</script>

<style lang="scss" scoped>
.form-box {
  margin: 20px auto;
}
</style>
