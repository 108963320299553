<template>
  <el-page-header :content="isEdit ? '编辑藏品' : '新增藏品'" @back="goBack" />
  <div class="form-box">
    <el-form
      ref="formRef"
      :model="formData"
      :rules="rules"
      label-width="120px"
      class="demo-formData"
      :size="formSize"
    >
      <el-form-item label="作品名称" prop="ProductName">
        <el-input v-model="formData.ProductName" class="input-w500" />
      </el-form-item>
      <el-form-item label="作者" prop="ProductOwner">
        <!-- <el-input v-model="formData.ProductOwner" /> -->
        <el-autocomplete
          class="input-w500"
          v-model="formData.showProductOwner"
          :fetch-suggestions="querySearch"
          clearable
          placeholder="请输入作者"
          @select="ProductOwnerSelect"
        />
        <el-link
          class="add-artist"
          type="primary"
          href="/admin/artist/operation"
          target="_blank"
          >新增作者</el-link
        >
      </el-form-item>
      <el-form-item label="年代" prop="ProductYears">
        <el-input class="input-w500" v-model="formData.ProductYears" />
      </el-form-item>

      <el-form-item label="尺寸" prop="ProAuthor">
        <el-input class="input-w500" v-model="formData.ProAuthor" />
      </el-form-item>

      <el-form-item label="类型" prop="CatId1">
        <!-- <el-input v-model="formData.CatId1" /> -->
        <el-select
          class="input-w500"
          v-model="formData.CatId1"
          placeholder="类型"
          @change="formChange"
        >
          <el-option
            :label="item.label"
            :value="item.value"
            v-for="item in classificationData.formList"
            :key="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="时期" prop="CatId2">
        <!-- <el-input v-model="formData.CatId2" /> -->
        <el-select
          class="input-w500"
          v-model="formData.CatId2"
          placeholder="时期"
        >
          <el-option
            :label="item.label"
            :value="item.value"
            v-for="item in classificationData.periodList"
            :key="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="名家作品" prop="CatId3">
        <!-- <el-input v-model="formData.CatId3" /> -->
        <el-radio-group v-model="formData.CatId3">
          <el-radio :label="23">名家作品</el-radio>
          <el-radio :label="11">非名家作品</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="藏品分类" prop="ProductVip">
        <!-- <el-input v-model="formData.ProductVip" /> -->
        <el-radio-group v-model="formData.ProductVip">
          <el-radio :label="1">馆藏精品</el-radio>
          <el-radio :label="0">一般藏品</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="位置序号" prop="ProductOrder">
        <el-input
          type="number"
          :min="0"
          class="input-w500"
          v-model="formData.ProductOrder"
          placeholder=""
        />
      </el-form-item>
      <el-form-item label="馆藏编号" prop="ProductNumber">
        <el-input
          class="input-w500"
          v-model="formData.ProductNumber"
          placeholder="CQ0XXXXXXXX"
        />
      </el-form-item>
      <el-form-item label="简介" prop="ProductContent">
        <el-input
          class="input-w500"
          v-model="formData.ProductContent"
          :autosize="{ minRows: 2, maxRows: 4 }"
          type="textarea"
          placeholder="请输入简介"
        />
      </el-form-item>
      <el-form-item label="藏品上传" prop="ProductBpic">
        <!-- <el-input v-model="formData.name" /> -->

        <!-- 藏品  collection
          新闻 news
          艺术家 artist -->
        <el-upload
          ref="uploadImage"
          :limit="1"
          :action="BASE_HOST + 'v1/cms/upload/'"
          :data="{ module: 'collection' }"
          :headers="{
            // 'Content-Type': 'multipart/form-data'
          }"
          :file-list="fileList"
          :on-success="fileUploadSuccess"
          list-type="picture-card"
          :auto-upload="true"
          :on-exceed="handleExceed"
        >
          <!-- {{ fileList }} -->
          <el-icon><Plus /></el-icon>

          <template #file="{ file }">
            <div>
              <img
                class="el-upload-list__item-thumbnail"
                :src="file.url"
                alt=""
              />
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <el-icon><zoom-in /></el-icon>
                </span>
                <!-- <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleDownload(file)"
                >
                  <el-icon><Download /></el-icon>
                </span> -->
                <span
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <el-icon><Delete /></el-icon>
                </span>
              </span>
            </div>
          </template>
        </el-upload>

        <el-dialog v-model="previewImageDialog">
          <el-image fit="cover" :src="dialogImageUrl" alt="Preview Image" />
        </el-dialog>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" v-if="!isEdit" @click="submitForm(formRef)"
          >保存</el-button
        >
        <el-button type="primary" v-else @click="submitForm(formRef)"
          >更新</el-button
        >
        <el-button @click="goBack">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script setup>
import { BASE_HOST } from '../../../utils/axios'

import { reactive, ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Delete, Plus, ZoomIn } from '@element-plus/icons-vue'
import { genFileId, ElMessage } from 'element-plus'
import {
  getArtists,
  setWatermark,
  postProduct,
  putProduct,
  getProductDetail
} from '../../../apis'
import {
  // ,
  // 获取全部种类的方法
  classification
} from '../../../utils/get-classification'

const router = useRouter()
const route = useRoute()
const previewImageDialog = ref(false)
const dialogImageUrl = ref('')
const uploadImage = ref()
const fileList = ref([])
// const disabled = ref(false)
// import type { FormInstance, FormRules } from 'element-plus'
const classificationData = computed({
  get: () => {
    return {
      periodList: [
        ...classification.periodList,
        { label: '其他时期', value: 15 }
      ],
      formList: [
        // { label: '全部形式', value: 0 },
        ...classification.formList
      ]
    }
  }
})

const formSize = ref('default')
const formRef = ref()
const formData = reactive({
  CatId1: '', //          int    `形式 id 必填
  CatId2: '', //       int    `时期 id 必填
  CatId3: '', //         int    `名家 id 必填 名家是：23，其他是：11
  ProductOrder: 999999, // 位置序号
  ProAuthor: '', //     string `尺寸
  ProductSpic: '', //    string `小图片路径
  ProductBpic: '', //    string `大图片路径
  ProductContent: '', // string `文字描述信息，介绍等
  ProductName: '', //    string `标题必填
  ProductNumber: '', //  string `馆藏编号 必填
  ProductMold: '', //   string `馆藏种类
  ProductYears: '', //   string `馆藏年代
  showProductOwner: '', // 展示作者
  ProductOwner: '', //   string `馆藏作者

  ProductRank: 0, //    int8   `是否推荐：1推荐 0不推荐"`
  ProductVip: '', //     int8   `是否精品：1精品 0非精品"`

  ProductUser: '管理员' //   string `操作用户
})

const rules = reactive({
  ProductName: [
    {
      required: true,
      message: '请输入藏品名称',
      trigger: 'blur'
    }
  ],
  ProductNumber: [
    {
      required: true,
      message: '请输入馆藏编号',
      trigger: 'blur'
    }
  ],
  CatId1: [
    {
      required: true,
      message: '请选择藏品形式',
      trigger: 'blur'
    }
  ],
  CatId2: [
    {
      required: true,
      message: '请选择藏品时期',
      trigger: 'blur'
    }
  ],
  ProductYears: [
    {
      required: true,
      message: '请输入藏品年代',
      trigger: 'blur'
    }
  ],
  ProAuthor: [
    {
      required: true,
      message: '请输入藏品尺寸',
      trigger: 'blur'
    }
  ],
  ProductOwner: [
    {
      required: true,
      message: '请输入藏品作者',
      trigger: 'blur'
    }
  ],
  CatId3: [
    {
      required: true,
      message: '请选择是否为名家作品',
      trigger: 'blur'
    }
  ],
  ProductVip: [
    {
      required: true,
      message: '请选择藏品类型',
      trigger: 'blur'
    }
  ],
  ProductContent: [
    {
      required: true,
      message: '请输入藏品描述',
      trigger: 'blur'
    }
  ],
  ProductBpic: [
    {
      required: true,
      message: '请上传藏品照片',
      trigger: 'blur'
    }
  ]
})
const isEdit = ref(false)
const id = route.query.id
const getData = () => {
  getProductDetail(id).then((res) => {
    // console.log(123, res)

    for (const key in res.data.data) {
      if (Object.hasOwnProperty.call(formData, key)) {
        const element = res.data.data[key]
        formData[key] = element
      }
    }
    formData.showProductOwner = formData.ProductOwner
    fileList.value = [
      {
        name: 'cp',
        url: BASE_HOST + 'v1/images/' + res.data.data.ProductBpic
      }
    ]
    // formData
  })
}
if (id) {
  // 如果有 id 就是编辑模式
  isEdit.value = true
  getData()
}

// const createFilter = (queryString) => {
//   return (restaurant) => {
//     return (
//       restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
//     )
//   }
// }

// 版画形式改变
const formChange = (v) => {
  const label = classificationData.value.formList.find(
    (item) => item.value === v
  ).label
  console.log(label, 123)
  formData.ProductMold = label
}
const querySearch = (queryString, cb) => {
  getArtists({
    keys: queryString.replace(/\s/g, ','),
    size: 40
  }).then((res) => {
    // 作者列表

    let ProductOwnerList = []

    if (res.data.data) {
      ProductOwnerList = res.data.data.map((item) => {
        item.label = item.ArticleTitle
        item.value = item.ArticleTitle
        return item
      })
      cb(ProductOwnerList)
    } else {
      cb(ProductOwnerList)
    }

    //   const results = queryString
    // ? ProductOwnerSelectList.value.filter(createFilter(queryString))
    // : ProductOwnerSelectList.value
    // call callback function to return suggestions
  })
}

const ProductOwnerSelect = (i) => {
  // 选择作者
  console.log(i.value)
  formData.ProductOwner = i.value
}
const handleRemove = (file) => {
  console.log(file)
  uploadImage.value.clearFiles()
  formData.ProductSpic = ''
  formData.ProductBpic = ''
}

const fileUploadSuccess = (returnData) => {
  // 文件上传成功
  console.log(returnData.data.ImgPath, '文件上传成功')

  setWatermark({
    filepath: returnData.data.ImgPath,
    small: 1
  }).then((res) => {
    console.log(res, 1233)

    formData.ProductSpic = res.data.data.SmallPath
    formData.ProductBpic = res.data.data.WaterPath
  })
}
const handleExceed = (files) => {
  uploadImage.value.clearFiles()
  const file = files[0]
  file.uid = genFileId()
  console.log(file, 1233)

  uploadImage.value.handleStart(file)
  uploadImage.value.submit()
}

const handlePictureCardPreview = (file) => {
  dialogImageUrl.value = file.url
  previewImageDialog.value = true
}

const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!', formData, formEl)
      formData.ProductOrder = Number(formData.ProductOrder)
      if (isEdit.value) {
        // 更新数据
        putProduct(formData, id).then((res) => {
          console.log(res)
          ElMessage({
            message: '更新成功成功！',
            type: 'success'
          })

          router.go(-1)
        })
      } else {
        // 上传数据
        postProduct(formData).then((res) => {
          console.log(res)
          ElMessage({
            message: '创建成功！',
            type: 'success'
          })
          router.go(-1)
        })
      }
    } else {
      console.log('error submit!', fields)
    }
  })
}

// const resetForm = (formEl) => {
//   if (!formEl) return
//   formEl.resetFields()
// }

const goBack = () => {
  router.push({ name: 'adminCollection' })
}
</script>

<style lang="scss" scoped>
.form-box {
  margin: 20px auto;
}
.add-artist {
  margin: 0 10px;
}
</style>
