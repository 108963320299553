<template>
  <el-page-header
    :content="isEdit ? '编辑专家顾问' : '新增专家顾问'"
    @back="goBack"
  />
  <div class="form-box">
    <el-form
      ref="formRef"
      :model="formData"
      :rules="rules"
      label-width="120px"
      class="demo-formData"
      :size="formSize"
    >
      <el-form-item label="专家名称" prop="ArticleTitle">
        <!-- <el-input v-model="formData.ArticleContent" /> -->
        <el-autocomplete
          v-model="formData.ArticleTitle"
          :fetch-suggestions="querySearch"
          clearable
          class="input-w500"
          placeholder=""
          @select="handleSelect"
        />
      </el-form-item>
      <el-form-item label="专家荣誉" prop="Honor">
        <el-input class="input-w500" v-model="formData.Honor" />
      </el-form-item>

      <el-form-item label="简介" prop="ArticleContent">
        <el-input
          class="input-w500"
          type="textarea"
          :row="4"
          v-model="formData.ArticleContent"
        />
      </el-form-item>

      <el-form-item label="专家顾问照片" prop="SmallPic">
        <!-- <el-input v-model="formData.SmallPic" /> -->

        <el-upload
          ref="uploadImage"
          :limit="1"
          :action="BASE_HOST + 'v1/cms/upload/'"
          :data="{ module: 'other' }"
          :headers="{
            // 'Content-Type': 'multipart/form-data'
          }"
          :file-list="fileList"
          :on-success="fileUploadSuccess"
          list-type="picture-card"
          :auto-upload="true"
          :on-exceed="handleExceed"
        >
          <!-- {{ fileList }} -->
          <el-icon><Plus /></el-icon>

          <template #file="{ file }">
            <div>
              <img
                class="el-upload-list__item-thumbnail"
                :src="file.url"
                alt=""
              />
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreview(file)"
                >
                  <el-icon><zoom-in /></el-icon>
                </span>
                <!-- <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleDownload(file)"
                >
                  <el-icon><Download /></el-icon>
                </span> -->
                <span
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file)"
                >
                  <el-icon><Delete /></el-icon>
                </span>
              </span>
            </div>
          </template>
        </el-upload>

        <el-dialog v-model="previewImageDialog">
          <el-image fit="cover" :src="dialogImageUrl" alt="Preview Image" />
        </el-dialog>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" v-if="!isEdit" @click="submitForm(formRef)"
          >保存</el-button
        >
        <el-button type="primary" v-else @click="submitForm(formRef)"
          >更新</el-button
        >
        <el-button @click="goBack">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { BASE_HOST } from '../../../utils/axios'
import { onBeforeUnmount, ref, shallowRef, reactive } from 'vue'

import { Delete, Plus, ZoomIn } from '@element-plus/icons-vue'

import { useRoute, useRouter } from 'vue-router'
import {
  getArtistsPage,
  getArtistsName,
  postExpert,
  putExpert
} from '../../../apis'
import { ElMessage, genFileId } from 'element-plus'
const formRef = ref()
const isEdit = ref(false)
const formSize = ref('default')
const route = useRoute()
const router = useRouter()
const fileList = ref([])
const uploadImage = ref()
const previewImageDialog = ref(false)
const dialogImageUrl = ref('')

const formData = reactive({
  ArticleContent: '',
  ArticleTitle: '',
  Honor: '',
  SmallPic: '',
  ArtOrder: 1,
  isTop: '1',
  AddUser: '管理员',
  ModuleID: 25,
  ArticleSort: 92
})

const rules = reactive({
  ArticleTitle: [
    {
      required: true,
      message: '请输入或者选择专家'
      // trigger: 'blur'
    }
  ]
})

const id = route.query.id

const querySearch = (key, cb) => {
  getArtistsPage({ keys: key.replace(/\s/g, ','), size: 40 }).then((res) => {
    let arr = []
    if (res.data.data) {
      arr = res.data.data.map((item) => {
        // item.label = item.ProductName
        item.value = item.ArticleTitle
        return item
      })
    }

    cb(arr)

    console.log(res.data.data, 123451)
  })
}
const handleSelect = (v) => {
  getArtistsName(v.ArticleTitle).then((res) => {
    console.log(res.data.data)
    const data = res.data.data
    formData.ArticleContent = data.ArticleContent
    // formData.ArticleTitle = v.ProductName
    formData.Honor = data.Honor
    formData.SmallPic = data.SmallPic
    fileList.value = [
      {
        name: 'image',
        url: BASE_HOST + 'v1/images/' + data.SmallPic
      }
    ]
  })

  // uploadImage
}
const getData = () => {
  // getArtistsDetail(id).then((res) => {
  //   console.log(123, res)
  //   for (const key in res.data.data) {
  //     if (Object.hasOwnProperty.call(formData, key)) {
  //       const element = res.data.data[key]
  //       formData[key] = element
  //     }
  //   }
  //   if (res.data.data.SmallPic) {
  //     fileList.value = [
  //       {
  //         name: 'artists-image',
  //         url: BASE_HOST + 'v1/images/' + res.data.data.SmallPic
  //       }
  //     ]
  //   }
  //   // formData
  // })
}
if (id) {
  // 如果有 id 就是编辑模式
  isEdit.value = true
  getData()
}
const handleRemove = (file) => {
  console.log(file)
  uploadImage.value.clearFiles()
  // formData.ProductSpic = ''
  formData.SmallPic = ''
}

const handlePictureCardPreview = (file) => {
  dialogImageUrl.value = file.url
  previewImageDialog.value = true
}
const fileUploadSuccess = (returnData) => {
  // 文件上传成功
  console.log(returnData.data.ImgPath, '文件上传成功')

  // setWatermark({
  //   filepath: returnData.data.ImgPath,
  //   small: 1
  // }).then((res) => {
  //   console.log(res, 1233)

  // formData.ProductSpic = res.data.data.SmallPath
  formData.SmallPic = returnData.data.ImgPath
  // })
}
const handleExceed = (files) => {
  uploadImage.value.clearFiles()
  const file = files[0]
  file.uid = genFileId()
  console.log(file, 1233)

  uploadImage.value.handleStart(file)
  uploadImage.value.submit()
}

// 编辑器实例，必须用 shallowRef
const editorRef = shallowRef()

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  const editor = editorRef.value
  if (editor == null) return
  editor.destroy()
})

// console.log(valueHtml.value, 999)

const submitForm = async (formEl) => {
  console.log(formEl, 123)
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!', formData, formEl)

      if (isEdit.value) {
        // 更新数据
        putExpert(formData, id).then((res) => {
          console.log(res)
          ElMessage({
            message: '更新成功成功！',
            type: 'success'
          })

          router.go(-1)
        })
      } else {
        // 上传数据
        postExpert(formData).then((res) => {
          console.log(res)
          if (res.data.code === 201) {
            ElMessage({
              message: '创建成功！',
              type: 'success'
            })
            router.go(-1)
          } else {
            ElMessage({
              message: '创建失败请检查参数！',
              type: 'error'
            })
          }
        })
      }
    } else {
      console.log('error submit!', fields)
    }
  })
}

const goBack = () => {
  router.push({ name: 'adminRecommend' })
}
</script>

<style lang="scss" scoped>
.form-box {
  margin: 20px auto;
}
</style>
