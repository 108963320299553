export default {
  0: {
    name: '默认'
  },
  23: {
    name: '新闻',
    moduleName: 'newsDetail',
    47: {
      name: '新闻'
    },
    52: {
      name: '新闻资讯'
    },
    50: {
      name: '站内新闻'
    },
    49: {
      name: '艺术界新闻'
    },
    48: {
      name: '版画界新闻'
    }
  },

  34: {
    name: '展览',
    moduleName: 'newsDetail',

    97: {
      name: '拍卖资讯'
    },
    99: {
      name: '展览会'
    },
    98: {
      name: '拍卖会'
    }
  },

  35: {
    name: '文章推荐',
    moduleName: 'newsDetail',

    53: {
      name: '推荐'
    },
    56: {
      name: '藏品推荐'
    },
    55: {
      name: '文章推荐'
    }
  },

  25: {
    name: '收藏家',
    moduleName: 'newsDetail',

    96: {
      name: '天下版藏'
    },
    95: {
      name: '藏品鉴赏'
    },
    94: {
      name: '收藏故事'
    },
    93: {
      name: '收藏名家'
    },
    92: {
      name: '收藏名家'
    }
  },

  27: {
    name: '版画知识',
    moduleName: 'newsDetail',

    61: {
      name: '版画知识'
    },
    102: {
      name: '版种介绍'
    },
    103: {
      name: '版画知识'
    }
  },

  24: {
    name: '版画艺术家',
    moduleName: 'artistDetail',

    20: {
      name: '版画艺术家'
    }
  },

  28: {
    name: '版画制作',
    moduleName: 'newsDetail',

    67: {
      name: '版画制作'
    },
    68: {
      name: '构图'
    },
    69: {
      name: '构思'
    }
  }
}
