import { createRouter, createWebHistory } from 'vue-router'
import Page404 from '../views/page-404/page-404'

// web 页面
import WebIndex from '../views/web/web-index'
import WebHome from '../views/web/web-home/web-home'
import WebIndexPage from '../views/web/web-index/web-index'
import WebNews from '../views/web/web-news/web-news'
import WebNewsDetail from '../views/web/web-news/web-news-detail'
import WebArticle from '../views/web/web-article/web-article'
import WebArticleDetail from '../views/web/web-article/web-article-detail'

import WebCollectionType from '../views/web/web-collection/web-collection-type'
import WebCollectionList from '../views/web/web-collection/web-collection-list'
import WebCollectionDetail from '../views/web/web-collection/web-collection-detail'
import WebArtistList from '../views/web/web-artist/web-artist-list'
import WebArtistDetail from '../views/web/web-artist/web-artist-detail'
import WebContactUs from '../views/web/web-contact-us/web-contact-us'
import WebAbout from '../views/web/web-about/web-about'
import WebExpertConsultant from '../views/web/web-expert-consultant/web-expert-consultant'
import WebExpertConsultantDetail from '../views/web/web-expert-consultant/web-expert-consultant-detail'

import WebExhibition from '../views/web/web-exhibition/web-exhibition'
import WebExhibitionDetail from '../views/web/web-exhibition/web-exhibition-detail'

import WebSearch from '../views/web/web-search-page/web-search-page'
import WebKnowledge from '../views/web/web-knowledge/web-knowledge'
import WebKnowledgeDetail from '../views/web/web-knowledge/web-knowledge-detail'

// admin

// admin 登录
import AdminLogin from '../views/admin/admin-login/admin-login'
import Admin from '../views/admin/admin-index'

import AdminOverview from '../views/admin/admin-overview/admin-overview'

// 藏品管理
import AdminCollection from '../views/admin/admin-collection/collection-list'
import AdminCollectionOperation from '../views/admin/admin-collection/collection-operation'
// 新闻管理
import AdminNews from '../views/admin/admin-news/news-list'
import AdminNewsOperation from '../views/admin/admin-news/new-operation'
// 展览管理

import AdminExhibition from '../views/admin/admin-exhibition/exhibition-list'
import AdminExhibitionOperation from '../views/admin/admin-exhibition/exhibition-operation'
// 文章管理

import AdminArticle from '../views/admin/admin-article/article-list'
import AdminArticleOperation from '../views/admin/admin-article/article-operation'

// 艺术家管理
import AdminArtist from '../views/admin/admin-artist/artist-list'
import AdminArtistOperation from '../views/admin/admin-artist/artist-operation'

// 推荐管理

import AdminRecommend from '../views/admin/admin-recommend/recommend-list'
import AdminRecommendOperation from '../views/admin/admin-recommend/recommend-operation'
// 轮播
import AdminBanner from '../views/admin/admin-banner/banner-list'
import AdminBannerOperation from '../views/admin/admin-banner/banner-operation'

// 版画知识

import AdminKnowledge from '../views/admin/admin-knowledge/knowledge-list'
import AdminKnowledgeOperation from '../views/admin/admin-knowledge/knowledge-operation'

// 联系我们

import AdminContactUs from '../views/admin/admin-contactUs/contactUs-list'
// import AdminContactUsOperation from '../views/admin/admin-contactUs/contactUs-operation'
// 专家顾问

import AdminExpertConsultant from '../views/admin/admin-expertConsultant/expertConsultant-list'
import AdminExpertConsultantOperation from '../views/admin/admin-expertConsultant/expertConsultant-operation'

// 关于我们

import AdminAbout from '../views/admin/admin-about/about-list'
// import AdminAboutOperation from '../views/admin/admin-about/about-operation'

// 用户管理
import AdminUser from '../views/admin/admin-user/admin-user'
// 防伪信息
import AdminAntiCounterfeiting from '../views/admin/admin-antiCounterfeiting/admin-antiCounterfeiting'

const routes = [
  {
    path: '/',
    name: 'index',
    component: WebIndexPage
    // redirect: (to) => {
    //   // 方法接收目标路由作为参数
    //   // return 重定向的字符串路径/路径对象
    //   // return { path: '/' }
    // }
  },
  {
    path: '/web',
    name: 'web',
    component: WebIndex,
    redirect: (to) => {
      // 方法接收目标路由作为参数
      // return 重定向的字符串路径/路径对象
      return { path: '/web/home' }
    },
    children: [
      {
        path: 'home',
        name: 'home',
        component: WebHome
      },
      {
        path: 'news',
        name: 'news',
        component: WebNews
      },
      {
        path: 'news/detail',
        name: 'newsDetail',
        component: WebNewsDetail
      },
      {
        path: 'article',
        name: 'article',
        component: WebArticle
      },
      {
        path: 'article/detail',
        name: 'articleDetail',
        component: WebArticleDetail
      },
      {
        path: 'collection',
        name: 'collection',
        component: WebCollectionType
      },
      {
        path: 'collection/list',
        name: 'collectionList',
        component: WebCollectionList
      },
      {
        path: 'collection/detail',
        name: 'collectionDetail',
        component: WebCollectionDetail
      },
      {
        path: 'artist',
        name: 'artist',
        component: WebArtistList
      },
      {
        path: 'artist/detail',
        name: 'artistDetail',
        component: WebArtistDetail
      },
      {
        path: 'contactUs',
        name: 'contactUs',
        component: WebContactUs
      },
      {
        path: 'about',
        name: 'about',
        component: WebAbout
      },
      {
        path: 'expertConsultant',
        name: 'expertConsultant',
        component: WebExpertConsultant
      },
      {
        path: 'expertConsultant/detail',
        name: 'expertConsultantDetail',
        component: WebExpertConsultantDetail
      },
      {
        path: 'exhibition',
        name: 'exhibition',
        component: WebExhibition
      },
      {
        path: 'exhibition/detail',
        name: 'exhibitionDetail',
        component: WebExhibitionDetail
      },

      {
        path: 'knowledge',
        name: 'knowledge',
        component: WebKnowledge
      },
      {
        path: 'knowledge/detail',
        name: 'knowledgeDetail',
        component: WebKnowledgeDetail
      },
      {
        path: 'search',
        name: 'search',
        component: WebSearch
      }
    ]
  },
  // {
  //   path: '/search',
  //   name: 'search',
  //   component: WebSearch
  // },

  // 管理平台
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    redirect: (to) => {
      // 方法接收目标路由作为参数
      // return 重定向的字符串路径/路径对象
      return { path: '/admin/overview' }
    },
    children: [
      {
        path: 'overview',
        name: 'adminOverview',
        component: AdminOverview
      },
      {
        path: 'collection',
        name: 'adminCollection',
        component: AdminCollection
      },
      {
        path: 'collection/operation',
        name: 'adminCollectionOperation',
        component: AdminCollectionOperation
      },
      {
        path: 'news',
        name: 'adminNews',
        component: AdminNews
      },
      {
        path: 'news/operation',
        name: 'adminNewsOperation',
        component: AdminNewsOperation
      },
      {
        path: 'exhibition',
        name: 'adminExhibition',
        component: AdminExhibition
      },
      {
        path: 'exhibition/operation',
        name: 'adminExhibitionOperation',
        component: AdminExhibitionOperation
      },
      //
      {
        path: 'article',
        name: 'adminArticle',
        component: AdminArticle
      },
      {
        path: 'article/operation',
        name: 'adminArticleOperation',
        component: AdminArticleOperation
      },
      {
        path: 'artist',
        name: 'adminArtist',
        component: AdminArtist
      },
      {
        path: 'artist/operation',
        name: 'adminArtistOperation',
        component: AdminArtistOperation
      },

      // 轮播
      {
        path: 'banner',
        name: 'adminBanner',
        component: AdminBanner
      },
      {
        path: 'banner/operation',
        name: 'adminBannerOperation',
        component: AdminBannerOperation
      },
      // 推荐管理
      {
        path: 'recommend',
        name: 'adminRecommend',
        component: AdminRecommend
      },
      {
        path: 'recommend/operation',
        name: 'adminRecommendOperation',
        component: AdminRecommendOperation
      },

      {
        path: 'knowledge',
        name: 'adminKnowledge',
        component: AdminKnowledge
      },
      {
        path: 'knowledge/operation',
        name: 'adminKnowledgeOperation',
        component: AdminKnowledgeOperation
      },
      // 专家顾问
      {
        path: 'expertConsultant',
        name: 'adminExpertConsultant',
        component: AdminExpertConsultant
      },
      {
        path: 'expertConsultant/operation',
        name: 'adminExpertConsultantOperation',
        component: AdminExpertConsultantOperation
      },
      // 联系我们
      {
        path: 'contactUs',
        name: 'adminContactUs',
        component: AdminContactUs
      },
      // {
      //   path: 'contactUs/operation',
      //   name: 'adminContactUsOperation',
      //   component: AdminContactUsOperation
      // },
      // 用户管理
      {
        path: 'user',
        name: 'adminUser',
        component: AdminUser
      },
      {
        path: 'user/operation',
        name: 'adminUserOperation',
        component: AdminArtistOperation
      },
      // 关于我们
      {
        path: 'about',
        name: 'adminAbout',
        component: AdminAbout
      },
      // {
      //   path: 'about/operation',
      //   name: 'adminAboutOperation',
      //   component: AdminAboutOperation
      // },

      // 防伪信息
      {
        path: 'antiCounterfeiting',
        name: 'adminAntiCounterfeiting',
        component: AdminAntiCounterfeiting
      }
      // {
      //   path: 'antiCounterfeiting/operation',
      //   name: 'adminAntiCounterfeitingOperation',
      //   component: AdminArtistOperation
      // }
    ]
  },
  {
    path: '/admin/login',
    component: AdminLogin
  },
  { path: '/:pathMatch(.*)*', name: 'not-found', component: Page404 }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: (to, from, savedPosition) => {
    // 跳转页面 重置到顶部
    return { top: 0 }
  },
  routes
})

export default router
