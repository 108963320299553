<template>
  <div class="web-artist-detail">
    <div class="page-body">
      <el-page-header content="版画艺术家详情" @back="goBack" />

      <el-row class="artist-detail-box">
        <el-col :span="6">
          <el-image
            teleported
            hide-on-click-modal
            preview-teleported
            fit="contain"
            class="artist-image"
            :preview-src-list="[BASE_HOST + 'v1/images/' + pageData.SmallPic]"
            :src="BASE_HOST + 'v1/images/' + pageData.SmallPic"
          ></el-image>
        </el-col>
        <el-col :span="15" :offset="1">
          <h2 class="name">{{ pageData.ArticleTitle }}</h2>
          <div class="item">
            <div class="label">录入时间</div>
            <div class="value-text">
              {{ moment(pageData.Pubtime).format("YYYY-MM-DD  hh:mm:ss") }}
            </div>

            <div class="label">更新时间</div>
            <div class="value-text">
              {{ moment(pageData.UpdateTime).format("YYYY-MM-DD  hh:mm:ss") }}
            </div>

            <div class="label">发布者</div>
            <div class="value-text">{{ pageData.AddUser }}</div>
          </div>

          <div class="item">
            <div class="label">简介</div>
            <div class="value">
              <div style="width: 100%" v-html="pageData.ArticleContent"></div>
            </div>
          </div>
        </el-col>

        <el-col :span="2" class="back-btn-box">
          <el-button :icon="ArrowLeft" @click="goBack">返回</el-button>
        </el-col>
      </el-row>
      <div class="product-box">
        <div class="product-title">推荐作品</div>

        <div class="product-warp">
          <div class="product-content">
            <div
              @click="productClick(item)"
              class="product-item"
              v-for="(item, index) in productList"
              :key="index"
            >
              <el-image
                fit="contain"
                :src="BASE_HOST + 'v1/images/' + item.ProductSpic"
              ></el-image>

              <div class="product-item-name">
                {{ item.ProductName }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="dev-box">
        简介：
        <div v-html="pageData.ArticleContent"></div>
      </div> -->
    </div>
  </div>
</template>
<script setup>
import { ArrowLeft } from '@element-plus/icons-vue'

import { getArtistsDetail, getProductGallery } from '../../../apis'
import { ref, onMounted } from 'vue'
import moment from 'moment'
import { BASE_HOST } from '../../../utils/axios'

import { useRoute, useRouter } from 'vue-router'
const pageData = ref({})
const productList = ref([])

const route = useRoute()
const router = useRouter()
const goBack = () => {
  router.push({
    name: 'artist'
  })
}
const getData = () => {
  getArtistsDetail(route.query.id).then((res) => {
    pageData.value = { ...res.data.data }

    getProductGallery({
      size: 999,
      keys: pageData.value.ArticleTitle
    }).then((resData) => {
      console.log(resData, 123)
      productList.value = resData.data.data
    })
  })
}
const productClick = (item) => {
  router.push({
    name: 'collectionDetail',
    query: {
      id: item.Id
    }
  })
}

onMounted(() => {
  getData()
})
</script>

<style lang="scss">
.web-artist-detail {
  min-height: 70vh;
  .back-btn-box {
    display: flex;
    align-items: flex-end;
  }
  .name {
    // text-align: center;
    margin: 20px;
    font-size: 36px;
  }
  .artist-detail-box {
    background: rgba($color: #000, $alpha: 0.1);
    margin: 20px auto;
    padding: 20px;
  }
  .artist-image {
    background: rgba($color: #fff, $alpha: 0.6);
    padding: 10px 20px;
    width: 100%;
    height: 400px;
  }
  .item {
    margin: 10px;

    display: flex;
    // justify-content: space-around;
    line-height: 36px;

    .label {
      color: #030204;
      margin-right: 1em;
      width: 6em;
      text-align: justify;
      text-align-last: justify;
      padding: 2px 16px;
      background: rgba($color: #fff, $alpha: 0.6);
    }
    .value {
      width: calc(100% - 8em);
      color: #030204;
      padding: 2px;
    }
    .value-text {
      padding-right: 20px;
    }
  }

  .product-box {
    margin: 20px auto;

    padding: 20px;

    background: rgba($color: #000, $alpha: 0.1);
    .product-title {
      font-size: 24px;
    }
    .product-warp {
      // height: 300px;
      width: 100%;
      overflow: auto;
    }
    .product-content {
      min-height: 300px;
      display: flex;
      flex-wrap: wrap;
      // background: #000;
      .product-item {
        cursor: pointer;
        max-width: 300px;
        margin: 10px auto;
        // height: 200px;
        // width: 25%;
        flex: auto;
        // height: 100%;
        margin: 10px;
        background: rgba($color: #000000, $alpha: 0.5);
        .product-item-name {
          color: #fff;
          text-align: center;
          // line-height: 30px;
          // background: pink;
        }
        .el-image {
          width: 100%;
          height: calc(100% - 30px);
        }
      }
    }
  }
}
</style>
