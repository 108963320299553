<template>
  <div class="type-page-content">
    <page-header
      title="馆藏精品"
      :backgroundImage="headerBackgroundImage"
      backgroundImagePosition="center center"
    >
      <!-- <template v-slot:body> 123 </template>
      <template #right>44456</template> -->
    </page-header>

    <div class="page-body">
      <div class="type-box">
        <div class="type-title reveal-top">版画种类</div>
        <div class="type-item-box reveal-top">
          <div
            @click="toPrintList({ item, type: 1 })"
            class="type-item reveal-top"
            v-for="item in WorkmanshipCategory"
            :key="item.Id"
          >
            <div class="type-img">
              <el-image :src="getTypeThumbnail(item.Id)" fit="cover"></el-image>
              <div class="thumbnail-name">
                {{ item.thumbnailName }}
              </div>
            </div>
            <div class="type-name">{{ item.CatName }}</div>
            <div class="type-num">
              ( {{ item.Count }})

              <!-- {{ item }} -->
            </div>
          </div>
        </div>
      </div>
      <div class="type-box reveal-top">
        <div class="type-title reveal-top">版画时期</div>
        <div class="type-item-box">
          <div
            @click="toPrintList({ item, type: 2 })"
            class="type-item reveal-top"
            v-for="item in PeriodCategory"
            :key="item.Id"
          >
            <div class="type-img">
              <el-image :src="getTypeThumbnail(item.Id)" fit="cover"></el-image>
              <div class="thumbnail-name">
                {{ item.thumbnailName }}
              </div>
            </div>
            <div class="type-name">{{ item.CatName }}</div>
            <div class="type-num">
              ({{ item.Count }})
              <!-- {{ item }} -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import pageHeader from '../../../components/page-header'
import headerBackgroundImage from '../../../assets/title-background-image/hzy.jpg'

import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { getProductCategory } from '../../../apis'
import {
  getTypeThumbnail
  // PeriodCategory
} from '../../../data/category-type'
import scrollReveal from '../../../utils/scrollReveal'
const router = useRouter()
onMounted(() => {
  scrollReveal.init()
})

const WorkmanshipCategory = ref([])
const PeriodCategory = ref([])

getProductCategory().then((res) => {
  res.data.data.map((item) => {
    if (item.CatName === '形式') {
      const arr = item.Subclass
      WorkmanshipCategory.value = arr
    }
    if (item.CatName === '时期') {
      const arr = item.Subclass
      PeriodCategory.value = arr
    }
    return item
  })
})

const toPrintList = (params) => {
  // type 1形式 2时期

  router.push({
    name: 'collectionList',
    query: { type: params.type, category: params.item.Id }
  })
}
</script>
<style lang="scss">
.type-page-content {
  .type-box {
    .type-title {
      text-align: center;
      margin: 10px auto;
      width: 6em;
      padding: 10px 20px;
      font-size: 32px;
      border-bottom: 2px solid #000;
      position: relative;
      // &:after {
      //   width: 40px;
      //   height: 80%;

      //   content: '';
      //   transform: rotate(180deg);
      //   position: absolute;
      //   right: 0;
      //   // background: #3388b1;
      //   background-image: url(../../../assets/img/title-zsw.png);
      //   background-repeat: no-repeat;
      //   background-size: 100% 100%;
      // }
      // &:before {
      //   width: 40px;
      //   height: 80%;

      //   content: '';
      //   position: absolute;
      //   left: 0;
      //   // background: #3388b1;
      //   background-image: url(../../../assets/img/title-zsw.png);
      //   background-repeat: no-repeat;
      //   background-size: 100% 100%;
      // }
    }
    .type-item-box {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      .type-item {
        position: relative;
        transition: 0.5s;
        cursor: pointer;
        color: #fff;
        // font-weight: 500;
        border-radius: 4px;
        background: rgba($color: #2f475f, $alpha: 0.8);
        background-image: url(../../../assets/img/type-bj.png);
        background-repeat: no-repeat;
        background-position: right center;
        background-size: auto 180%;
        height: 200px;
        width: 32%;
        margin: 16px 0;
        text-shadow: 2px 2px 1px #000;

        // display: flex;
        // justify-content: flex-end;
        // flex-wrap: wrap;
        overflow: hidden;

        &:hover {
          background-color: rgba($color: #2f475f, $alpha: 1);
          background-size: auto 200%;

          .type-name {
            font-size: 38px;
            color: #1997fa;
          }
        }
      }
      .type-name {
        position: absolute;
        right: 20px;
        bottom: 50px;
        transition: 0.5s;
        font-size: 26px;

        // line-height: 200px;
        // margin: 20px;
        // border-bottom: 2px solid white;
        z-index: 1;
      }
      .type-num {
        transition: 0.5s;

        position: absolute;
        right: 20px;
        bottom: 20px;
        color: #f2c600;
        font-size: 24px;
      }
      .type-img {
        // position: relative;
        position: absolute;
        left: 0;
        .el-image {
          height: 100%;
          width: 100%;
        }
        opacity: 1;
        width: 100%;
        height: 100%;
        .thumbnail-name {
          position: absolute;
          font-size: 16px;
          bottom: 0px;
          line-height: 40px;
          padding: 0 10px;
          color: #526577;
          // text-shadow: 2px 2px 0px #fff;
          text-shadow: -1px -1px 0 #ffffff, 1px -1px 0 #ffffff,
            -1px 1px 0 #ffffff, 1px 1px 0 #ffffff;

          // width: 60%;
          text-align: center;
        }
      }
    }
  }
}
</style>
