<!-- 网站 友情链接 -->

<template>
  <div class="links">
    <div v-for="item in Links" :key="item.Id">
      <a target="open" :href="item.Href"
        ><span>{{ item.Name }}</span>
      </a>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { getBlogroll } from '../../src/apis'

const Links = ref([])
getBlogroll().then((res) => {
  console.log(res, 1234)
  Links.value = res.data.data
})
</script>
<style lang="scss">
.links {
  display: flex;
  flex-wrap: wrap;
  // background: palegoldenrod;
  > div {
    margin: 0 10px;
    // background: palevioletred;
    // width: 10em;
    line-height: 30px;
    font-size: 14px;
  }
  a[href] {
    color: #ccc;
    text-decoration: dashed;

    &:hover {
      color: white;
    }
  }
}
</style>
