<template>
  <CommonLsitPage
    dataUrl="/referral/page"
    :requestParams="{ query: 'ArticleSort:56' }"
    :params="pageParams"
    ref="tableRef"
  ></CommonLsitPage>
  <el-dialog v-model="dialogVisible" title="确认操作" width="30%">
    <span
      >此操作将删除推荐
      <span style="color: red">{{ RecommendDetail.ArticleTitle }}</span
      >，请谨慎操作。</span
    >
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="danger" @click="deleteRecommendFunction"
          >删除</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import CommonLsitPage from '../../../components/common-list-page.vue'
import moment from '../../../utils/moment'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
// import { BASE_HOST } from '../../../utils/axios'
import { ElMessage } from 'element-plus'
import { deleteArticles, putReferral } from '../../../apis'

import { Delete } from '@element-plus/icons-vue'

const tableRef = ref(null)
const dialogVisible = ref(false)

const router = useRouter()
// const route = useRoute()
const RecommendDetail = ref({})
const pageParams = {
  title: '推荐管理',
  btns: [
    {
      type: 'primary',
      name: '新增推荐',
      onClick: () => {
        router.push({
          name: 'adminRecommendOperation'
        })
      }
    }
  ],

  // <el-table-column prop="date" label="Date" width="180" />
  tableColumn: [
    {
      label: '发布时间',
      prop: 'Pubtime',
      sortable: 'custom',
      width: 200,
      formatter: (row, column, cellValue, index) => {
        const str = moment(cellValue).format('YYYY-MM-DD  hh:mm:ss')
        // console.log(str)
        return str
      }
    },

    {
      prop: 'ArticleTitle',
      label: '推荐标题'
    },
    {
      label: '推荐作者',
      prop: 'Honor'
      // width: 150
    },
    {
      prop: 'HomepageShow',
      label: '是否展示',
      formatter: (row, column, cellValue, index) => {
        const str = cellValue ? '显示' : '隐藏'
        // console.log(row, 11)
        return str
      }
    },

    {
      label: '操作',
      width: 240,
      operations: [
        {
          name: '首页展示',
          type: 'success',
          // icon: Edit,
          onShow: ({ row }) => {
            return row.HomepageShow === 0
          },
          onClick: (scope) => {
            // console.log(tableRef.value.refresh())
            const rowData = scope.row
            rowData.HomepageShow = 1
            putReferral(rowData, rowData.Id).then((res) => {
              console.log(res, 1111)
              if (res.data.code === 200) {
                tableRef.value.refresh()
                ElMessage({
                  message: '操作成功',
                  type: 'success'
                })
              }
            })
          }
        },
        {
          name: '首页隐藏',
          type: 'warning',
          // icon: Edit,
          onShow: ({ row }) => {
            return row.HomepageShow === 1
          },
          onClick: (scope) => {
            const rowData = scope.row
            rowData.HomepageShow = 0
            putReferral(rowData, rowData.Id).then((res) => {
              console.log(res, 1111)
              if (res.data.code === 200) {
                tableRef.value.refresh()
                ElMessage({
                  message: '操作成功',
                  type: 'success'
                })
              }
            })
            // console.log(tableRef.value.refresh())
          }
        },
        {
          name: '删除',
          type: 'danger',
          icon: Delete,

          onClick: (scope) => {
            RecommendDetail.value = scope.row
            dialogVisible.value = true
            console.log(scope, 1111)
          }
        }
      ]
    }
  ]
}

const deleteRecommendFunction = () => {
  deleteArticles(RecommendDetail.value.Id).then((res) => {
    tableRef.value.refresh()
    // console.log(res, '删除数据')
    dialogVisible.value = false
    ElMessage({
      message: '删除成功',
      type: 'success'
    })
  })
}
</script>

<style scoped lang="scss">
.cell-item {
  width: 6em;
}
.dec-box {
  // background: red;
}
</style>
