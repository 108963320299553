import { getProductCategory } from '../apis'
import { reactive } from 'vue'

const data = reactive({
  formList: [],
  periodList: []
})

export const classification = data
// (type) => {
//   // form 形式
//   // period 时期
//   // 获取种类数据
//   if (type === 'form') {
//     return data.formList
//   } else if (type === 'period') {
//     return data.periodList
//   } else {
//     return data
//   }

// }

// const getData = async () => {
//   return await new Promise((resolve, reject) => {
//    .then(resolve).catch(reject)
//   })
// }
export const init = () => {
  console.log('调用初始化种类')
  getProductCategory().then((res) => {
    console.log('初始化种类', res)
    res.data.data.map((item) => {
      if (item.CatName === '形式') {
        const arr = item.Subclass

        data.formList = arr.map((v) => {
          v.label = v.CatName
          v.value = v.Id
          return v
        })
      }
      if (item.CatName === '时期') {
        const arr = item.Subclass

        data.periodList = arr.map((v) => {
          v.label = v.CatName
          v.value = v.Id
          return v
        })
      }

      return item
    })
    // .then((res) => {

    //   typeList.push({})
  })

  return ''
}
// export const getClassification = (key, type) => {
//   //    form 形式
//   //  period 时期
//   // const typeList = []
//   if (type === 'form') {
//     return data.formList.find((item) => item.value === key)
//   }
//   if (type === 'period') {
//     return data.periodList.find((item) => item.value === key)
//   }
//   // data
//   return ''
// }
