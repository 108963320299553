<template>
  <div class="exhibition-content">
    <page-header title="展览推荐" :backgroundImage="headerBackgroundImage">
    </page-header>
    <div class="page-body">
      <el-tabs
        v-model="queryData.ArticleSort"
        class="tabs-knowledge"
        @tab-click="typeClick"
      >
        <el-tab-pane
          v-for="item in navbarList"
          :key="item.value"
          :label="item.label"
          :name="item.value"
        >
        </el-tab-pane>
      </el-tabs>

      <div class="card-box">
        <div
          class="consultant-item"
          v-for="(item, index) in dataList"
          :key="index"
          @click="itemClick(item)"
        >
          <el-card :body-style="{ padding: '0px' }">
            <el-image
              lazy
              fit="cover"
              class="consultant-item-image"
              :src="BASE_HOST + 'v1/images/' + item.BigPic"
            ></el-image>
            <!--
          <img
            src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png"
            class="image"
          /> -->
            <div style="padding: 14px">
              <div class="zj-name">{{ item.ArticleTitle }}</div>
              <div class="zj-time">
                <!-- {{ moment(item.Pubtime).format('YYYY-MM-DD') }} -->

                {{ item.Honor }}
              </div>
              <!-- <div class="zj-ry">{{ item.Honor }}</div> -->
              <!-- <div class="zj-jj">
                {{ item.Honor }}
              </div> -->
            </div>
          </el-card>
        </div>
      </div>
      <CustomPagination
        background
        :total="exhibitionData.total"
        :current-page="queryData.page"
        @current-change="currentChange"
      />
    </div>
  </div>
</template>

<script setup>
import { BASE_HOST } from '../../../utils/axios'
// import moment from 'moment'
import CustomPagination from '../../../components/custom-pagination.vue'

import pageHeader from '../../../components/page-header'
import headerBackgroundImage from '../../../assets/title-background-image/yxyz.jpg'
import { getExhibitionPage } from '../../../apis'
import { ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
const dataList = ref([])
const exhibitionData = ref({})
const router = useRouter()
const typeClick = (val) => {
  // console.log(123, val.props.name)
  queryData.page = 1

  queryData.ArticleSort = val.props.name
  getData()
}
const itemClick = (item) => {
  router.push({
    name: 'exhibitionDetail',
    query: {
      id: item.Id
    }
  })
}

const navbarList = [
  // { label: '全部信息', value: 'all' },
  { label: '其他展览', value: 99 },
  { label: '本馆展览', value: 98 }
]
const currentChange = (e) => {
  queryData.page = e
  getData()
}
const queryData = reactive({
  page: 1,
  ArticleSort: 99,
  keys: ''
})
const getData = () => {
  let query = ''

  if (queryData.ArticleSort !== 'all') {
    query = 'ArticleSort:' + queryData.ArticleSort
  }

  getExhibitionPage({ size: 20, ...queryData, query }).then((res) => {
    dataList.value = res.data.data.filter((item) => item)
    exhibitionData.value = res.data
    console.log(res, 133)
  })
}
getData()
</script>

<style lang="scss">
.exhibition-content {
  .card-box {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    // justify-content: space-evenly;
  }
  .tabs-knowledge {
    background: rgba($color: #000000, $alpha: 0.1);
    padding: 4px 10px;
    border-radius: 8px;
    .el-tabs__item {
      color: white;
      font-size: 18px;
    }
    .is-active {
      color: #1997fa;
      // font-size: 20px;
    }
    // background: red;
  }
  .consultant-item {
    width: 400px;
    margin: 0 auto;

    cursor: pointer;
    padding: 10px;
    .zj-name {
      margin: 6px auto;
      font-size: 18px;
      // line-height: 32px;
      color: #1997fa;
    }
    .zj-time {
      color: rgb(99, 99, 99);
    }
    .zj-ry {
      color: #25567a;
      font-size: 16px;
      margin: 10px auto;
    }
    .zj-jj {
      // margin: 8px auto;
      font-size: 14px;
      // line-height: 18px;
      // height: 100px;

      text-indent: 2em;
      // overflow: auto;
      color: rgb(94, 94, 94);
      overflow: hidden; //超出的文本隐藏

      text-overflow: ellipsis; //溢出用省略号显示

      display: -webkit-box;

      -webkit-line-clamp: 4; // 超出多少行

      -webkit-box-orient: vertical;
    }
    .consultant-item-image {
      width: 100%;
      height: 300px;
    }
  }
}
</style>
