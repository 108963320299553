// 黑白木刻    （老羊倌）
import hbmk from '../assets/category-thumbnail/lyg.jpg'
// 套色木刻    （打过长江去）
import tsmk from '../assets/category-thumbnail/dgcjq.jpg'
// 铜版 （晚窗）
import tb from '../assets/category-thumbnail/wc.jpg'
// 水印木刻   （黄海渔归）
import symk from '../assets/category-thumbnail/hhyg.jpg'
// 石版  （草地驿站）
import sb from '../assets/category-thumbnail/cdyz.jpg'
// 丝网版画  （宫墙）
import swbh from '../assets/category-thumbnail/gq.jpg'
// 综合版画 其他版画   （西递村系列之二十三）
import zhbh from '../assets/category-thumbnail/mzxhs.jpg'
// 浮世绘  （神奈川冲浪里）
import fsh from '../assets/category-thumbnail/snccl.jpg'
// 版画书籍  （抗战八年木刻选集）
import bhsj from '../assets/category-thumbnail/kzbnmkxj.jpg'
// 插画 插图
import ch from '../assets/category-thumbnail/ch.jpg'
// 藏书票
import csp from '../assets/category-thumbnail/csp.jpg'
// 年画
import nh from '../assets/category-thumbnail/nh.jpg'
// 儿童版画
import etbh from '../assets/category-thumbnail/etbh.jpg'
// 外国版画
import wgbh from '../assets/category-thumbnail/wgbh.jpg'
// 印版
import yb from '../assets/category-thumbnail/yb.jpg'
// 珍贵画册印品
import zghcyp from '../assets/category-thumbnail/zghcyp.jpg'
// 宣传画
// import xch from '../assets/category-thumbnail/xch.jpg'
// 学生作品
import xszp from '../assets/category-thumbnail/xszp.jpg'
// 红色收藏
import hssc from '../assets/category-thumbnail/hssc.jpg'
// 年代

import mq from '../assets/category-thumbnail/mq.jpg'
import mgsq from '../assets/category-thumbnail/mgsq.jpg'
import kzsq from '../assets/category-thumbnail/kzsq.jpg'
import jfzzsq from '../assets/category-thumbnail/jfzzsq.jpg'
import shzyjssq from '../assets/category-thumbnail/shzyjssq.jpg'
import wgsq from '../assets/category-thumbnail/wgsq.jpg'
import ggkfjxd from '../assets/category-thumbnail/ggkfjxd.jpg'

// 时期种类
export const PeriodCategory = []
// 工艺种类 代表作
export const getTypeThumbnail = (id) => {
  const imgs = [
    {
      id: 4,
      name: '木刻',
      type: 'block',
      thumbnail: hbmk,
      thumbnailName: '老羊倌'
    },
    {
      id: 5,

      name: '套色木刻',
      type: 'block',
      thumbnail: tsmk,
      thumbnailName: '打过长江去'
    },
    {
      id: 7,

      name: '铜版画',
      type: 'block',
      thumbnail: tb,
      thumbnailName: '晚窗'
    },
    {
      id: 6,

      name: '水印木刻',
      type: 'block',
      thumbnail: symk,
      thumbnailName: '黄海渔归'
    },
    {
      id: 8,

      name: '石版画',
      type: 'block',
      thumbnail: sb,
      thumbnailName: '草地驿站'
    },
    {
      id: 12,

      name: '丝网版画',
      type: 'block',
      thumbnail: swbh,
      thumbnailName: '宫墙'
    },
    {
      id: 13,

      name: '其它版画',
      type: 'block',
      thumbnail: zhbh,
      thumbnailName: '西递村系列之二十三'
    },
    {
      id: 21,

      name: '浮世绘',
      type: 'block',
      thumbnail: fsh,
      thumbnailName: '神奈川冲浪里'
    },
    {
      id: 29,

      name: '版画书籍',
      type: 'block',
      thumbnail: bhsj,
      thumbnailName: '抗战八年木刻选集'
    },
    {
      id: 14,

      name: '插图',
      type: 'block',
      thumbnail: ch,
      thumbnailName: ''
    },
    {
      id: 22,

      name: '藏书票',
      type: 'block',
      thumbnail: csp,
      thumbnailName: ''
    },
    {
      id: 24,

      name: '年画',
      type: 'block',
      thumbnail: nh,
      thumbnailName: ''
    },
    {
      id: 25,

      name: '儿童版画',
      type: 'block',
      thumbnail: etbh,
      thumbnailName: ''
    },
    {
      id: 26,

      name: '外国版画',
      type: 'block',
      thumbnail: wgbh,
      thumbnailName: ''
    },
    {
      id: 27,

      name: '印版',
      type: 'block',
      thumbnail: yb,
      thumbnailName: ''
    },
    {
      id: 28,

      name: '珍贵画册印品',
      type: 'block',
      thumbnail: zghcyp,
      thumbnailName: ''
    },
    {
      id: 0,
      name: '宣传画',
      type: 'block',
      thumbnail: nh,
      thumbnailName: ''
    },
    {
      id: 31,

      name: '学生习作',
      type: 'block',
      thumbnail: xszp,
      thumbnailName: ''
    },
    {
      id: 0,

      name: '红色收藏',
      type: 'block',
      thumbnail: hssc,
      thumbnailName: ''
    },

    // 年代 缩略图
    {
      id: 9,

      name: '明清',
      type: 'block',
      thumbnail: mq,
      thumbnailName: ''
    },
    {
      id: 10,

      name: '民国',
      type: 'block',
      thumbnail: mgsq,
      thumbnailName: ''
    },
    {
      id: 0,
      name: '新文化运动时期',
      type: 'block',
      thumbnail: kzsq,
      thumbnailName: ''
    },
    {
      id: 16,

      name: '抗日战争时期',
      type: 'block',
      thumbnail: kzsq,
      thumbnailName: ''
    },
    {
      id: 17,

      name: '解放战争时期',
      type: 'block',
      thumbnail: jfzzsq,
      thumbnailName: ''
    },
    {
      id: 18,

      name: '社会主义建设时期',
      type: 'block',
      thumbnail: shzyjssq,
      thumbnailName: ''
    },
    {
      id: 19,

      name: '文革时期',
      type: 'block',
      thumbnail: wgsq,
      thumbnailName: ''
    },
    {
      id: 20,

      name: '改革开放及现代',
      type: 'block',
      thumbnail: ggkfjxd,
      thumbnailName: ''
    }
  ]

  const item = imgs.find((item) => item.id === id) || {}
  if (id) {
    return item.thumbnail
  } else {
    return fsh
  }
}

export default {}
