<template>
  <ul>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
  </ul>
</template>

<style lang="scss" scoped>
ul {
  height: 100px;
  width: 100%;
  position: relative;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
ul li {
  list-style: none;
  width: 6px;
  height: 20px;
  background: #262626;
  margin: 0 4px;
  animation: animate 0.7s infinite alternate;
}

@keyframes animate {
  0% {
    transform: scaleY(1);
  }
  25% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(1);
  }
  75% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(3);
  }
}
ul li:nth-child(1) {
  animation-delay: 0.1s;
}
ul li:nth-child(2) {
  animation-delay: 0.2s;
}
ul li:nth-child(3) {
  animation-delay: 0.3s;
}
ul li:nth-child(4) {
  animation-delay: 0.4s;
}
ul li:nth-child(5) {
  animation-delay: 0.5s;
}
ul li:nth-child(6) {
  animation-delay: 0.6s;
}
</style>
